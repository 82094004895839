import { useState, useEffect } from 'react';

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerHeight < 599) {
            setIsMobile(true);
        }
    }, []);

    return isMobile;
}

export default useMobile;