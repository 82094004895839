// /////////////////////////////////////////////////////////////
// Fichier pour la gestion d'un toggle menu
// /////////////////////////////////////////////////////////////
// Creation :    -> CGR
// Update : 21/09/2022  -> CGR : Ajout du comportement responsiv
// /////////////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports 

// Imports system
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAngleDown, 
    faAngleUp, 
} from '@fortawesome/free-solid-svg-icons'

// Import context
import { programmeContext, programmeNameContext, pageContext, languageContext } from './../../../../contexts';


// Imports src
import LinkedButton from '../../../../components/button/linkedButton'
import Button from '../../../../components/button/button';
import { useLocation } from 'react-router-dom';


// /////////////////////////////////////////////////////////////
// Component


const ToggleLevel = ({buttons = [], extraBtn = null, position = 0, maxVisibleButtons = 0}) => {

    // Properties
    //OLD:const maxBtnToActivateSlider = position === 1 ? 4 : 2;
    const lang = useContext(languageContext)
    const [offset, setOffset] = useState(0);
    const [touchPos, setTouchPos] = useState(0);
    const [touchMove, setTouchMove] = useState(0);
    const { search } = useLocation()

    // Effects
    useEffect(() => {

        // On test s'il faut faire les actions suivant la taille d'ecran et le nombre de boutons
        if (Math.abs(touchMove) < 70) {
            setTouchMove(0);
            return;
        }

        if ((buttons.length + (extraBtn ? 1 : 0)) > maxVisibleButtons) {
            const mov = -Math.round(touchMove / 70);
            setOffset(o => {
                if (o + mov < 0) {
                    return 0;
                } else if (Math.abs(o + mov) >= ((buttons.length + (extraBtn ? 1 : 0)) - maxVisibleButtons)) {
                    return -((buttons.length + (extraBtn ? 1 : 0)) - maxVisibleButtons);
                }
                return -(o + mov);
            })
        }
      }, [buttons, maxVisibleButtons, touchMove])

    return (
        <programmeNameContext.Consumer>
            {name => 
            <programmeContext.Consumer>
                {datas => 
                    <pageContext.Consumer>
                        {page => 
                            <div className="toogleSafeZone">
                                {((buttons.length + (extraBtn ? 1 : 0)) > maxVisibleButtons && offset != 0) &&
                                    <div className = "slideArrows" id="upArrow" onClick = {() => {setOffset(o => o + 1)}}>
                                        <FontAwesomeIcon icon={faAngleUp}/>
                                    </div>
                                }
                                <div 
                                    className="toggleScroller" 
                                    style={{
                                        height : `${maxVisibleButtons * 70}px`, 
                                        alignItems : (buttons.length + (extraBtn ? 1 : 0)) < maxVisibleButtons ? "center" : "flex-start"
                                    }}
                                >
                                    <nav 
                                        id={`togglePos${position}`} 
                                        style={{transform:`translateY(${(buttons.length + (extraBtn ? 1 : 0)) < maxVisibleButtons ? 0 : offset * 70}px)`}}
                                        onTouchStart={(e) => setTouchPos(e.changedTouches[0].clientY)}
                                        onTouchEnd={(e) => setTouchMove(touchPos - e.changedTouches[0].clientY)}
                                    >
                                        {buttons.map(button => 
                                            (button.route.includes("http") && button.linkType !== "intégré") ?
                                            <a key = {`link_${button.text}`} href = {`${button.route}`} target={button.linkType === "onglet" ? '_blank' : ""}>
                                                <Button 
                                                    key = {`btn_${button.id}`}
                                                    icon={button.icon} 
                                                    text={position === 0 || position === 2 ? button.text : button.text[lang]}
                                                    state={page.current == button.route.replace("?noheader=true", "")}
                                                    displayText = {datas.displayText}
                                                />
                                            </a>
                                            :
                                            <LinkedButton
                                                key = {`btn_${button.id}`}
                                                route = {button.linkType === "intégré" ? `/${datas.urlName}/externalcontent/${button.id}${search}` : `/${datas.urlName}/${button.route}${search}`}
                                                icon = {button.icon} 
                                                text = {position === 0 || position === 2 ? button.text : button.text[lang]}
                                                state = {button.linkType === "intégré" ? page.current == button.id : page.current == button.route.replace("?noheader=true", "")}
                                                displayText = {datas.displayText}
                                            />
                                        )}
                                        {extraBtn}
                                    </nav>
                                </div>
                                {((buttons.length + (extraBtn ? 1 : 0)) > maxVisibleButtons && Math.abs(offset) < ((buttons.length + (extraBtn ? 1 : 0)) - maxVisibleButtons)) &&
                                    <div className = "slideArrows" id="downArrow" onClick = {() => {setOffset(o => o - 1)}}>
                                        <FontAwesomeIcon icon={faAngleDown}/>
                                    </div>
                                }
                            </div>
                    }</pageContext.Consumer>
            }</programmeContext.Consumer>
        }</programmeNameContext.Consumer>    
    )
}


// /////////////////////////////////////////////////////////////
// Export
export default ToggleLevel;
