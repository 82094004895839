// /////////////////////////////////////////////////////////////
// Sous Fichier pour la gestion des routes de detail-lot
// /////////////////////////////////////////////////////////////
// Creation : 09/09/2022    -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

// Imports src
import DetailLot from '../../../pages/detailLot/detailLot';
import LocaliserLot from '../../../pages/detailLot/localiser/localiser';
import Visites from '../../../pages/visites/visites';
import Vues from '../../../pages/detailLot/vues/vues';




///////////////////////////////////
// Component

const DetailLotRouter = ({loadLot, loadHdForFrame}) => { // Routes pour le moteur de recherche

    // Render
    return (
        <Routes>
            <Route path='plan' element={
                <DetailLot loadLot = {loadLot} loadHdForFrame = {loadHdForFrame}/>}>
            </Route>
            <Route path='situation/*' element={
                <LocaliserLot loadLot = {loadLot} loadHdForFrame = {loadHdForFrame}/>}>
            </Route>
            <Route path='vim' element={
                <Visites loadLot = {loadLot}/>}>
            </Route>
            <Route path='vim/:piece' element={
                <Visites loadLot = {loadLot}/>}>
            </Route>
            <Route path='vue' element={
                <Vues loadLot = {loadLot}/>}>
            </Route>
        </Routes>
    )
}


// ///////////////////////////////////
// Export
export default DetailLotRouter;