// ////////////////////////////////////////////////////////////
// Fichier pour la gestion des pieces d'une VIM
// ////////////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ////////////////////////////////////////////////////////////


// //////////////////////////
// Imports

// Imports system
import React from 'react';
// Imports components
import ButtonLong from '../../components/button/buttonLong';
import { useSearchParams  } from 'react-router-dom'



// //////////////////////////
// Components
const Pieces = ({rooms, current, setCurrent, navigation, setNavigation}) => { // Toggle pour le changement de piece

    const [searchParams, setSearchParams] = useSearchParams ()

    // Fonctions
    const navigate = (where) => {
        searchParams.set(navigation, where.title)
        setSearchParams(searchParams)
        setCurrent(where);
    }

    // Render
    return (
        <div className = {`vim-pieces ${navigation !== "" ? "open" : "close"}`}>
            {
                rooms.map((room) => {
                    return (
                        <ButtonLong 
                            key = {room.name ? room.name : room} 
                            icon= {room.icon ? room.icon : null}
                            text = {room.title ? room.title : room} 
                            state = {current.name === room.name ? room.title : ""} 
                            action = {() => {
                                navigate(room);
                                setNavigation("");
                            }} 
                        />
                    )
                })
            }
        </div>
    )
}

// //////////////////////////
// Export
export default Pieces;