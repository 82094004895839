
// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, {useState, useEffect, useRef} from 'react';




const CustomSelect = ({options, preselected = null, setCurrent = () => {}}) => {

    const customSelectRef = useRef();
    const selectorRef = useRef();
    const [displayOptions, setDisplayOptions] = useState(false)
    const [currentSelection, setCurrentSelection] = useState(preselected ? preselected : options[0])

    useEffect(() => {
        setCurrent(currentSelection)
    }, [currentSelection])

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if(e.target !== selectorRef.current) {
                setDisplayOptions(false)
            }
        })
    }, [])

    return (
        <div className = "custom-select" ref = {customSelectRef}>

            <div 
                className = "select-selected" 
                onClick = {() => {setDisplayOptions(true)}}
                style = {{
                    borderRadius : !displayOptions ? "20px" : "20px 20px 0px 0px"
                }}
                ref = {selectorRef}
            >
                {currentSelection}
            </div>

            <div className = {`select-items ${displayOptions ? '' : 'select-hide'}`}>
                {options.map(opt => 
                    <div className = {`opt ${opt === currentSelection ? "selectedOne" : ""}`} onClick = {() => {
                        setCurrentSelection(opt);
                        setDisplayOptions(false);
                    }}>
                        {opt}
                    </div>
                )}
            </div>

        </div>
    )
}



// ////////////////////////////////////////////////////////////////////////
// Export
export default CustomSelect;