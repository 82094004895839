const resolveImage = (programmeName, block, floor, resol, frame) => {
  return new Promise(function (resolve, reject) {
    if (frame) {
      const a = new Image();
      a.src = `/public/programmes/${programmeName}/maquette/${block}/${floor}/jpg-${resol}/${frame}.jpg`;

      a.addEventListener("load", () => {
        setTimeout(() => {
          resolve([a]);
        }, 40);
      });
    } else {
      resolve();
    }
  });
};

const resolveImages = (programmeName, block, floor, resol, frames) => {
  return new Promise(function (resolve, reject) {
    if (frames) {
      const a = new Image();
      a.src = `/public/programmes/${programmeName}/maquette/${block}/${floor}/jpg-${resol}/${frames[0]}.jpg`;
      const b = new Image();
      b.src = `/public/programmes/${programmeName}/maquette/${block}/${floor}/jpg-${resol}/${frames[1]}.jpg`;
      const c = new Image();
      c.src = `/public/programmes/${programmeName}/maquette/${block}/${floor}/jpg-${resol}/${frames[2]}.jpg`;

      c.addEventListener("load", () => {
        setTimeout(() => {
          resolve([a, b, c]);
        }, 40);
      });
    } else {
      resolve();
    }
  });
};

const loadRotations = (
  programme,
  maquette,
  lots,
  loadingDatas,
  setLoadingDatas,
  maquetteLoad,
  lotsLoad,
  resolutions,
  resIndex
) => {
  // 2- Loading premiere frame de tout
  let b = 0;
  let f = 0;
  let fr = 0;

  const loopOnUncutViews156 = (b, v, fr) => {
    const vBlock = Object.keys(maquetteLoad)[b];
    const vFloor = Object.keys(maquetteLoad[vBlock])[v];

    if (!["Aerien", "Pieton"].includes(vFloor)) {
      if (Object.keys(maquetteLoad[vBlock])[v + 1]) {
        loopOnUncutViews156(b, v + 1, 0);
      } else if (Object.keys(maquetteLoad)[b + 1]) {
        loopOnUncutViews156(b + 1, 0, 0);
      } else {
        loopOnCutViews156(0, 0, 0);
      }
    } else {
      resolveImages(programme, vBlock, vFloor, resolutions[resIndex], [
        (3 * fr).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
        (3 * fr + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
        (3 * fr + 2).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
      ]).then((imgs) => {
        maquetteLoad[vBlock][vFloor].frames[resolutions[resIndex]] = [
          ...maquetteLoad[vBlock][vFloor].frames[resolutions[resIndex]],
          ...imgs,
        ];
        if (fr < 29) {
          loopOnUncutViews156(b, v, fr + 1);
        } else {
          maquetteLoad[vBlock][vFloor].state = resolutions[resIndex];
          setLoadingDatas({
            maquette: maquetteLoad,
            lots: lotsLoad,
          });

          if (Object.keys(maquetteLoad[vBlock])[v + 1]) {
            loopOnUncutViews156(b, v + 1, 0);
          } else if (Object.keys(maquetteLoad)[b + 1]) {
            loopOnUncutViews156(b + 1, 0, 0);
          } else {
            loopOnCutViews156(0, 0, 0);
          }
        }
      });
    }
  };

  const loopOnCutViews156 = (b, v, fr) => {
    const vBlock = Object.keys(maquetteLoad)[b];
    const vFloor = Object.keys(maquetteLoad[vBlock])[v];

    if (["Aerien", "Pieton"].includes(vFloor)) {
      if (Object.keys(maquetteLoad[vBlock])[v + 1]) {
        loopOnCutViews156(b, v + 1, 0);
      } else if (Object.keys(maquetteLoad)[b + 1]) {
        loopOnCutViews156(b + 1, 0, 0);
      } else {
        console.log(`passage à la resolution ${resolutions[resIndex + 1]}`);
        if (resolutions[resIndex + 1]) {
          loadRotations(
            programme,
            maquette,
            lots,
            loadingDatas,
            setLoadingDatas,
            maquetteLoad,
            lotsLoad,
            resolutions,
            resIndex + 1
          );
        }
      }
    } else {
      resolveImages(programme, vBlock, vFloor, resolutions[resIndex], [
        (3 * fr).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
        (3 * fr + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
        (3 * fr + 2).toLocaleString("en-US", {
          minimumIntegerDigits: 3,
          useGrouping: false,
        }),
      ]).then((imgs) => {
        maquetteLoad[vBlock][vFloor].frames[resolutions[resIndex]] = [
          ...maquetteLoad[vBlock][vFloor].frames[resolutions[resIndex]],
          ...imgs,
        ];
        if (fr < 29) {
          loopOnCutViews156(b, v, fr + 1);
        } else {
          maquetteLoad[vBlock][vFloor].state = resolutions[resIndex];
          setLoadingDatas({
            maquette: maquetteLoad,
            lots: lotsLoad,
          });

          if (Object.keys(maquetteLoad[vBlock])[v + 1]) {
            loopOnCutViews156(b, v + 1, 0);
          } else if (Object.keys(maquetteLoad)[b + 1]) {
            loopOnCutViews156(b + 1, 0, 0);
          } else {
            // 3- Loading des rotations
            if (resolutions[resIndex + 1]) {
              loadRotations(
                programme,
                maquette,
                lots,
                loadingDatas,
                setLoadingDatas,
                maquetteLoad,
                lotsLoad,
                resolutions,
                resIndex + 1
              );
            }
          }
        }
      });
    }
  };

  loopOnUncutViews156(b, f, fr);
};

const appLoader = (
  programme,
  maquette,
  lots,
  loadingDatas,
  setLoadingDatas
) => {
  // lancement du chargement

  // 0- Mise en place des variables
  const maquetteLoad = {};
  const lotsLoad = {};
  const resolutions = maquette.global.resolutions
    .slice(0, -1)
    .map((x) => [x, []]);

  // 1- Recuperation du contenu total de la maquette 3D

  if (maquette.complexe) {
    maquetteLoad[maquette.complexe.name] = {};

    for (const view of Object.keys(maquette.complexe.views)) {
      maquetteLoad[maquette.complexe.name][view] = {
        state: "empty",
        maxRes: "156",
        frames: Object.fromEntries(resolutions),
      };
    }
  }

  for (const block of Object.keys(maquette.blocks)) {
    const blockName = maquette.blocks[block].name;
    maquetteLoad[blockName] = {};

    for (const view of Object.keys(maquette.blocks[block].views)) {
      if (view !== "Decoupe") {
        maquetteLoad[blockName][view] = {
          state: "empty",
          maxRes: "156",
          frames: Object.fromEntries(resolutions),
        };
      } else {
        for (const floor of maquette.blocks[block].floors) {
          if (floor.display) {
            maquetteLoad[blockName][floor.name] = {
              state: "empty",
              maxRes: "156",
              frames: Object.fromEntries(resolutions),
            };
          }
        }
      }
    }
  }

  // 1|a premier save de donnees
  setLoadingDatas({
    maquette: maquetteLoad,
    lots: lotsLoad,
  });

  // 2- Loading premiere frame de tout
  let b = 0;
  let f = 0;

  const loopOnViews = (b, v) => {
    const vBlock = Object.keys(maquetteLoad)[b];
    const vFloor = Object.keys(maquetteLoad[vBlock])[v];

    resolveImage(
      programme,
      vBlock,
      vFloor,
      "156",
      (0).toLocaleString("en-US", {
        minimumIntegerDigits: 3,
        useGrouping: false,
      })
    ).then((img) => {
      maquetteLoad[vBlock][vFloor].state = "fixe";
      maquetteLoad[vBlock][vFloor].frames["156"] = [...img];
      setLoadingDatas({
        maquette: maquetteLoad,
        lots: lotsLoad,
      });

      if (Object.keys(maquetteLoad[vBlock])[v + 1]) {
        loopOnViews(b, v + 1);
      } else if (Object.keys(maquetteLoad)[b + 1]) {
        loopOnViews(b + 1, 0);
      } else {
        // 3- Loading des rotations
        loadRotations(
          programme,
          maquette,
          lots,
          loadingDatas,
          setLoadingDatas,
          maquetteLoad,
          lotsLoad,
          [...maquette.global.resolutions].reverse().slice(0, -1),
          0
        );
      }
    });
  };

  loopOnViews(b, f);
};

export default appLoader;
