// ////////////////////////////////////////////////////////////
// Fichier pour la gestion des vims de la page detail du lot
// ////////////////////////////////////////////////////////////
// Creation : 27/05/2022    -> CGR
// Maj : 18/07/2022         -> CGR : gestion dynamique via BDD
// Maj : 08/08/2022         -> CGR : restructuration en sous fichiers
// ////////////////////////////////////////////////////////////

// //////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Imports components
import useKrpano from "react-krpano-hooks";
import Button from "../../components/button/button";
import {
  domain,
  lotContext,
  pageContext,
  programmeContext,
  programmeNameContext,
} from "../../contexts";
import Decorations from "./decoration";
import Pieces from "./pieces";

// //////////////////////////
// Components
const Visites = () => {
  // Partie visites immersives

  // //////////////////////////////
  // Properties
  const programmeName = useContext(programmeNameContext);
  const { setCurrent, setHeaderLevel } = useContext(pageContext);
  const { lotDatas, setLotDatas } = useContext(lotContext);
  const { lotParam, piece } = useParams(); // lot actif
  const [currentNavigation, setCurrentNavigation] = useState(""); // switch entre decorations et pieces
  const [nav, setNav] = useState(null); // DOM Toggle pieces/decorations
  const [roomList, setRoomList] = useState([]); // Liste des pieces de la vim
  const [currentRoom, setCurrentRoom] = useState(piece); // Piece de la vim active
  const [hotspots, setHotspots] = useState([]);
  const { containerRef, krpanoState, callKrpano } = useKrpano({
    // KRPano
    scriptPath: `/public/programmes/${programmeName}/lots/${lotParam}/vim/tour.js`,
    embeddingParams: {
      swf: `/public/programmes/${programmeName}/lots/${lotParam}/vim/tour.swf`,
      xml: `/public/programmes/${programmeName}/lots/${lotParam}/vim/tour.xml`,
      target: "react-krpano",
      html: "prefer",
    },
    width: "calc(100% - 100px)",
  });

  // //////////////////////////////
  // Functions
  const switchNavigation = () => {
    // Passage entre selecteur deco et selecteur piece

    if (currentNavigation === "piece") {
      setNav(
        <Pieces
          rooms={roomList}
          krpano={containerRef}
          current={currentRoom}
          setCurrent={setCurrentRoom}
        />
      );
    } else {
      setNav(<Decorations />);
    }
  };

  // //////////////////////////////
  // Effects
  useEffect(() => {
    // Verification si le contexte de lot est bien a jour

    if (programmeName !== null && lotDatas === null) {
      fetch(`${domain}/${programmeName}/lot/name=${lotParam}`, { mode: "cors" })
        .then((response) => response.json())
        .then((data) => {
          setLotDatas(data[lotParam]);
        })
        .catch((err) => {
          console.log("Erreur de fetch : ", err);
        });
    }
  }, [programmeName, lotDatas, lotParam]);

  useEffect(() => {
    // Gestion de l'affichage du menu toggle + navigation de la vim

    if (programmeName !== null) {
      setCurrent(`detail-lot/${lotParam}/vim`);
      setHeaderLevel(2);

      fetch(`${domain}/${programmeName}/lot/name=${lotParam}`, {
        mode: "cors",
      })
        .then((response) => response.json())
        .then((data) => {
          setRoomList(data[lotParam].vim);
          if (piece == undefined) {
            setCurrentRoom(data[lotParam].vim[0]);
          }
        });
    }
  }, [programmeName, lotParam]);

  useEffect(switchNavigation, [currentNavigation, roomList, currentRoom]);

  useEffect(() => {
    // Au chargement du krPano -> mise en place des positions et des puces

    if (krpanoState.isEmbedded && programmeName !== null) {
      // On change la scene
      callKrpano(`loadscene('scene_${currentRoom}', null, MERGE, BLEND(0.5));`);

      // On retire les anciens hotSpots
      for (let spot of hotspots) {
        callKrpano(`removehotspot(${spot});`);
      }
      // On vide la memoire
      setHotspots([]);

      fetch(`${domain}/${programmeName}/lot/${lotParam}/vim`, { mode: "cors" })
        .then((response) => response.json())
        .then((data) => {
          for (let poi of data.vim[currentRoom].pois) {
            callKrpano(`addhotspot(${poi.text});`);
            callKrpano(`hotspot[${poi.text}].loadstyle(skin_poi_geo);`);
            callKrpano(`set(hotspot[${poi.text}].ath, ${poi.x});`);
            callKrpano(`set(hotspot[${poi.text}].atv, ${poi.y});`);
            callKrpano(`set(hotspot[${poi.text}].zoom,false);`);
            callKrpano(`set(hotspot[${poi.text}].distorted,false);`);
            callKrpano(`set(hotspot[${poi.text}].zorder, 9);`);

            callKrpano(`addlayer(${poi.text}_ctn);`);
            callKrpano(
              `set(layer[${poi.text}_ctn].parent, hotspot[${poi.text}]);`
            );
            callKrpano(`layer[${poi.text}_ctn].loadstyle(skin-direction);`);
            callKrpano(`set(layer[${poi.text}_ctn].zoom,false);`);
            callKrpano(`set(layer[${poi.text}_ctn].x, 0;`);
            callKrpano(`set(layer[${poi.text}_ctn].y, 0;`);
            callKrpano(`set(layer[${poi.text}_ctn].html, ${poi.text});`);
            callKrpano(`set(layer[${poi.text}_ctn].distorted, false);`);
            callKrpano(
              `set(layer[${poi.text}_ctn].onclick, openurl(${domain}/${programmeName}/detail-lot/${lotParam}/vim/${poi.text},_self));`
            );

            callKrpano(`addlayer(${poi.text}_svg);`);
            callKrpano(
              `set(layer[${poi.text}_svg].parent, hotspot[${poi.text}]);`
            );
            callKrpano(`set(layer[${poi.text}_svg].zoom,false);`);
            callKrpano(`set(layer[${poi.text}_svg].x, 0;`);
            callKrpano(`set(layer[${poi.text}_svg].y, 16;`);
            callKrpano(`set(layer[${poi.text}_svg].distorted,false);`);
            callKrpano(`set(layer[${poi.text}_svg].zorder, 10);`);
            callKrpano(`set(layer[${poi.text}_svg].scale, 0.2);`);
            callKrpano(`set(layer[${poi.text}_svg].edge, top);`);
            callKrpano(
              `set(layer[${poi.text}_svg].url, /public/icons/VIM/fleche-l.svg);`
            );

            // Ajout du hotspot en memoire
            setHotspots((old) => [...old, poi.text]);
          }
        });
    }
  }, [programmeName, krpanoState.isEmbedded, lotParam, currentRoom]);

  // //////////////////////////////
  // Render
  return (
    <programmeContext.Consumer>
      {(datas) =>
        datas != null && (
          <div id="visites">
            <div id="visitesControllers">
              <Button
                icon="pieces"
                text="Piece"
                state={currentNavigation === "piece"}
                action={() => {
                  setCurrentNavigation("piece");
                }}
                displayText={datas.displayText}
              />
              {datas.pagesDetails.interieurs[lotParam]?.decorations.length >
                0 && (
                <Button
                  icon="decorations-2"
                  text="Décoration"
                  state={currentNavigation === "decoration"}
                  action={() => {
                    setCurrentNavigation("decoration");
                  }}
                  displayText={datas.displayText}
                />
              )}
            </div>
            <div id="visitesNavigation">
              <Pieces
                rooms={roomList}
                krpano={containerRef}
                current={currentRoom}
                setCurrent={setCurrentRoom}
                navigation={currentNavigation}
                setNavigation={setCurrentNavigation}
              />

              <Decorations
                navigation={currentNavigation}
                setNavigation={setCurrentNavigation}
              />
            </div>
            <div ref={containerRef} style={{ position: "absolute" }} />
          </div>
        )
      }
    </programmeContext.Consumer>
  );
};

// //////////////////////////
// Export
export default Visites;
