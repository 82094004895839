// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour l'affichage de la zone d'information des lots sur le comparateur
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 08/09/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAngleDown, 
    faAngleUp, 
} from '@fortawesome/free-solid-svg-icons'
import { programmeContext, comparerContext, languageContext } from '../../../../contexts';



// ////////////////////////////////////////////////////////////////////////////////
// Components

const DatasZone = ({display, isMobile}) => {

    // Properties
    const programmeDatas = useContext(programmeContext)
    const comparerDatas = useContext(comparerContext)
    const lang = useContext(languageContext)
    const scrollZone = useRef(null)
    const [currentComp, setCurrentComp] = useState(0) // Index actif de la liste de donnees
    const [lines, setLines] = useState([]) // liste des lignes de donnees de lot
    const [options, setOptions] = useState([["0", "0", "0"]])
    // Gestion du hover d'infos
    const [hoverPos, setHoverPos] = useState(0) // positionnement du hover
    const [valueA, setValueA] = useState("-") // Infos pour le hover du lot A
    const [valueB, setValueB] = useState("-") // Infos pour le hover du lot B
    const [lastScroll, setLastScroll] = useState(0) // Dernier scroll effectue


    // Functions
    const handleWheel = (e) => { // Gestion du scroll sur le carrousel
        if (e.deltaY < 0) {
            setCurrentComp(i => i-1 > -1 ? i-1 : 0);
        } else {
            setCurrentComp(i => i+1 <= options.length-1 ? i+1 : options.length-1);
        }
    }

    const handleTouch = (e) => { // Gestion du touch sur le carrousel
        setLastScroll(e.touches[0].clientY);
        if (lastScroll - e.touches[0].clientY > 10) {
            setCurrentComp(i => i-1 > -1 ? i-1 : 0);
        } else if (lastScroll - e.touches[0].clientY < -10) {
            setCurrentComp(i => i+1 <= options.length-1 ? i+1 : options.length-1);
        }
    }

    const animateScroller = (to) => { // Animation de la zone de scroll

        const who = scrollZone.current;
        
        if (who == undefined || who == null) {return;}

        let startPos = Math.abs(parseInt(who.style.transform.slice(11,-3))); //TO DO : trouver une meilleure facon

        if (isNaN(startPos)) { startPos = 0; } // On securise le startPos si aucun transform n'a encore ete fait

        let current = 0;
        let operation = 5
        
        if (to < startPos) {operation = -5}

        if (to != startPos) {
            const animation = setInterval(() => {
                current = current + operation;
                who.style.transform = `translateY(-${startPos + current}px)`;
    
                if (operation === 5 && startPos + current >= to) {
                    who.style.transform = `translateY(-${to}px)`;
                    clearInterval(animation)
                }
                if (operation === -5 && startPos + current <= to) {
                    who.style.transform = `translateY(-${to}px)`;
                    clearInterval(animation)
                }
            }, 2)
        }
    }

    const returnLineValue = (currentLot, line) => { // Valeur a afficher pour la ligne d'info
        if (!currentLot) { return "-" }
        else {
            if (line.value !== "surfaces") {
                if (currentLot[line.value] === 0) { return programmeDatas.languagesDatas[lang].comparer.infos.vide }
                else if (line.value === "etage") { return `${currentLot[line.value]?.replace("Etage", programmeDatas.languagesDatas[lang].maquette.palette.etages)}` }
                else { return `${currentLot[line.value]} ${line.suffix.replace("m²", programmeDatas.languagesDatas[lang].comparer.surface.surface)}` }
            } else {
                if (currentLot["pieces"].find((post) => {if(post.name == line.label) return true}) !== undefined) {
                    return `${currentLot["pieces"].find((post) => {if(post.name == line.label) return true}).surface} ${line.suffix.replace("m²", programmeDatas.languagesDatas[lang].comparer.surface.surface)}`
                 }
                else { return "-" }
            }
        }
    }


    // Effects
    useEffect(() => { // Gestion de la liste d'infos a partir des donnees programme

        if (programmeDatas != null) {

            setOptions(programmeDatas.pagesDetails.comparer.map(line => [line.label, line.value, line.suffix.replace("m²", programmeDatas.languagesDatas[lang].comparer.surface.surface)]))
            
            programmeDatas.pagesDetails.comparer.sort((a, b) => {
                if (a.label === "Typologie") return -1;
                if (b.label === "Typologie") return 1;
                if (a.label === "Etage") return -1;
                if (b.label === "Etage") return 1;
                if (a.value === "surface") return -1;
                if (b.value === "surface") return 1;
                if (a.label === "Prix") return -1;
                if (b.label === "Prix") return 1;
                return 0;
            });

            setLines(
                programmeDatas.pagesDetails.comparer.map(line => 
                    <div className = 'comparer-data'>
                        {/* Donnee du lot en A */}
                        <p className = 'data-value'>
                            {line.value !== "type" ? returnLineValue(comparerDatas.currentLotA, line) : lang === "fr" ? returnLineValue(comparerDatas.currentLotA, line) : comparerDatas.currentLotA ? comparerDatas.currentLotA?.numPieces-1 : "-"} 
                        </p>
                        <p className='data-name'>
                            {programmeDatas.languagesDatas[lang].lots.options[line.label] ? programmeDatas.languagesDatas[lang].lots.options[line.label] : line.label}
                        </p>
                        {/* Donnee du lot en B */}
                        <p className = 'data-value'>
                            {line.value !== "type" ? returnLineValue(comparerDatas.currentLotB, line) : lang === "fr" ? returnLineValue(comparerDatas.currentLotB, line) : comparerDatas.currentLotA ? comparerDatas.currentLotB?.numPieces-1 : "-"} 
                        </p>
                    </div>
                )
            )
        }

    }, [programmeDatas, comparerDatas])

    useEffect(() => { // Gestion du comportement du hover

        if (options.length === 0) {return}

        // Gestion de la position
        if (display !== 'full') {
            if (currentComp < 1) {
                animateScroller(0);
                setHoverPos(0);
            } else if (currentComp === options.length-1) {
                animateScroller(46 * (options.length-3));
                setHoverPos(2);
            } else {
                animateScroller(46 * (currentComp-1));
                setHoverPos(1);
            }
        } else {
            animateScroller(0);
            setHoverPos(0);
        }

        // Gestion des valeurs

        if (comparerDatas.currentLotA !== null) {
            
            setValueA(
                options[currentComp][1] !== "surfaces" ?
                    comparerDatas.currentLotA[options[currentComp][1]] != 0 ? 
                    options[currentComp][1] !== "type" ? `${comparerDatas.currentLotA[options[currentComp][1]]} ${options[currentComp][2]}` : `${lang === "fr" ? comparerDatas.currentLotA[options[currentComp][1]] : comparerDatas.currentLotA.numPieces-1} ${options[currentComp][2]}` : 
                    programmeDatas.languagesDatas[lang].comparer.infos.vide
                :
                    comparerDatas.currentLotA["pieces"].find((post, index) => {
                        if(post.name == options[currentComp][0]) return true}
                    ) != undefined ? `${comparerDatas.currentLotA["pieces"].find((post, index) => {
                        if(post.name == options[currentComp][0]) return true
                    }).surface} ${options[currentComp][2]}` : "-" 
            );
        } else {
            setValueA(`- ${options[currentComp][2]}`)
        }

        if (comparerDatas.currentLotB !== null) {
            setValueB(
                options[currentComp][1] !== "surfaces" ?
                    comparerDatas.currentLotB[options[currentComp][1]] != 0 ?
                    options[currentComp][1] !== "type" ? `${comparerDatas.currentLotB[options[currentComp][1]]} ${options[currentComp][2]}` :  `${lang === "fr" ? comparerDatas.currentLotB[options[currentComp][1]] :  comparerDatas.currentLotB.numPieces-1} ${options[currentComp][2]}` : 
                    programmeDatas.languagesDatas[lang].comparer.infos.vide
                :
                    comparerDatas.currentLotB["pieces"].find((post, index) => {
                        if(post.name == options[currentComp][0]) return true
                    }) != undefined ? `${comparerDatas.currentLotB["pieces"].find((post, index) => {
                        if(post.name == options[currentComp][0]) return true
                    }).surface} ${options[currentComp][2]}` : "-" 

            );
        } else {
            setValueB(`- ${options[currentComp][2]}`)
        }

    }, [currentComp, display, comparerDatas, options])

    useEffect(() => {
        const updateIndex = (event) => {
            if (event.key == "ArrowRight" || event.key == "ArrowDown") {
                setCurrentComp(i => i+1 <= options.length-1 ? i+1 : options.length-1);
            } else if (event.key == "ArrowLeft" || event.key == "ArrowUp") {
                setCurrentComp(i => i-1 > -1 ? i-1 : 0);
            }
        }

        document.addEventListener('keydown', updateIndex)
        return () => {document.removeEventListener('keydown', updateIndex)}

    }, [programmeDatas, currentComp])

    // Render
    return (
        <programmeContext.Consumer>{programmeDatas => programmeDatas != null &&
            <comparerContext.Consumer>{comparerDatas => 
                <div id = 'comparer-datas' className = {display}>

                    {/* Fleche de navigation haute si affichage reduit */}
                    {display !== "full" &&
                        <div className = 'comparer-data'>
                            {currentComp !== 0 &&
                                <FontAwesomeIcon icon={faAngleUp} color={programmeDatas.color} onClick={() => {setCurrentComp(i => i - 1)}}/>
                            }
                        </div>
                    }  

                    {/* Nom des lots en comparaison si affichage plein */}
                    {display === 'full' && !isMobile &&
                        <div className = 'comparer-data'>
                            <h3>{comparerDatas.currentLotA !== null ? comparerDatas.currentLotA.name : ""}</h3>
                            <div></div>
                            <h3>{comparerDatas.currentLotB !== null ? comparerDatas.currentLotB.name : ""}</h3>
                        </div>
                    }
                    {display === 'full' && isMobile &&
                        <div className = 'comparer-data'>
                        </div>
                    } 

                    {/* Liste des lignes d'infos */}
                    <div id = 'datas-scroller' className = {display} onWheel = {handleWheel} onTouchMove = {handleTouch}>
                        <div ref={scrollZone}>
                            {lines}
                        </div>
                    </div>

                    {/* Fleche de navigation basse si affichage reduit */}
                    {display !== "full" &&                        
                        <div className = 'comparer-data'>
                            {options.length  - 1 !== currentComp &&
                                <FontAwesomeIcon icon={faAngleDown} color={programmeDatas.color} onClick={() => {setCurrentComp(i => i + 1)}}/>
                            }
                        </div>
                        
                    } 
                    {/* Affichage du hover d'info si affichage reduit */}
                    {(display !== "full" && currentComp || currentComp === 0) &&
                        <div id = "comparer-hover" className={`pos${hoverPos}`} onWheel = {handleWheel} onTouchMove = {handleTouch}>
                            <div className = {`mainBackground`}>{valueA?.replace("Etage", programmeDatas.languagesDatas[lang].maquette.palette.etages)}</div>
                            <div>
                                {programmeDatas.languagesDatas[lang].lots.options[options[currentComp][0]] ? programmeDatas.languagesDatas[lang].lots.options[options[currentComp][0]] : options[currentComp][0]}
                            </div>
                            <div className = {`mainBackground`}>{valueB?.replace("Etage", programmeDatas.languagesDatas[lang].maquette.palette.etages)}</div>
                        </div>
                    }

                </div>
            }</comparerContext.Consumer>
        }</programmeContext.Consumer>
    )
}


// ////////////////////////////////////////////////////////////////////////////////
// Exports
export default DatasZone;