import React, { useContext, useState } from 'react'
import {useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom"
import { programmeContext, programmeNameContext, languageContext } from './../../../contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import Pano from "./Pano"
import { useRef } from 'react';
import { useEffect } from 'react';


const PanoContent = () => {

    const {currentInt} = useParams()
    const programmeName = useContext(programmeNameContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const lang = useContext(languageContext)
    const programmeDatas = useContext(programmeContext)
    const interieursDatas = programmeDatas.pagesDetails.interieurs

    const getRoom = (list, value) => {
        const key = Object.keys(list).find(key => list[key].titleLangs[programmeDatas.languages.findIndex(x => x === lang)] === value) || Object.keys(list)[0]
        return {name : key, title : list[key].title, icon : list[key].icon}
    }

    const room = getRoom(interieursDatas.pano[currentInt].pieces, searchParams.get('piece'), lang)
    const [miniMapStyle, setMiniMapStyle] = useState('normal')
    const miniMapSize = miniMapStyle === 'normal' ? 320 : document.body.clientHeight
    const [direction, setDirection] = useState(0)
    const [initialDir, setInitialDir] = useState(1000)

    useEffect(() => {
        document.addEventListener('click', e => {

            const x = e.x
            const y = e.y
            const width = document.body.clientWidth
            const height = document.body.clientHeight

            if (height > 599) {
                if (miniMapStyle === "normal") {
                    if (width - x > 40 && width - x < 360 && y > 10 && y < 330) {
                        setMiniMapStyle('full')
                    }
                } else {
                    if (x > (width - height) / 2 && x < width  - ((width - height) / 2)) {
                        setMiniMapStyle('normal')
                    }
                }
            }
            else {
                if (miniMapStyle === "normal") {
                    if (width - x > 29 && width - x < 172 && y > 28 && y < 172) {
                        setMiniMapStyle('full')
                    }
                } else {
                    if (x > (width - height) / 2 && x < width  - ((width - height) / 2)) {
                        setMiniMapStyle('normal')
                    }
                }
            }

            
        })

        //
        document.addEventListener('touchend', e => {

            const x = e.x
            const y = e.y
            const width = document.body.clientWidth
            const height = document.body.clientHeight

            if (height > 599) {
                if (miniMapStyle === "normal") {
                    if (width - x > 40 && width - x < 360 && y > 10 && y < 330) {
                        setMiniMapStyle('full')
                    }
                } else {
                    if (x > (width - height) / 2 && x < width  - ((width - height) / 2)) {
                        setMiniMapStyle('normal')
                    }
                }
            }
            else {
                if (miniMapStyle === "normal") {
                    if (width - x > 29 && width - x < 172 && y > 28 && y < 172) {
                        setMiniMapStyle('full')
                    }
                } else {
                    if (x > (width - height) / 2 && x < width  - ((width - height) / 2)) {
                        setMiniMapStyle('normal')
                    }
                }
            }

            
        })

    }, [miniMapStyle])

    if (!room) {return}

    return (
        <>
            <Pano
                currentInt={currentInt}
                view={room.name}
                pois={interieursDatas.pano[currentInt].pieces[room.name]?.pois}
                decoration={
                    interieursDatas.pano[currentInt].decorations[
                        interieursDatas.pano[currentInt].decorationsLanguages[lang].findIndex(x => x === searchParams.get("decoration"))
                    ]}
                prestation={searchParams.get("prestation")}
                setDirection = {setDirection}
                initialDir = {initialDir}
                setInitialDir = {setInitialDir}
            />

            {interieursDatas.pano[currentInt].useMinimap &&
                <div id = "panoMiniMap" className = {miniMapStyle}>
                    
                    <img src = {`/public/programmes/${programmeName}/interieurs/${currentInt}/minimap${searchParams.get("decoration") || searchParams.get("prestation") || ''}.png`}></img>
                    {interieursDatas.pano[currentInt].miniMapPois.map(piece => 
                        <div 
                            className = {`minimapButton ${interieursDatas.pano[currentInt].pieces[room.name].title === piece.direction ? "activRoom" : ""}`} 
                            onClick = {e => {
                                e.stopPropagation()
                                const goodOne = Object.values(interieursDatas.pano[currentInt].pieces).filter(x => x.title === piece.direction)

                                if (goodOne[0]) {
                                    searchParams.set("piece", goodOne[0].titleLangs[programmeDatas.languages.findIndex(x => x === lang)])
                                    setSearchParams(searchParams)
                                    setMiniMapStyle("normal")
                                }

                            }}
                            style = {{left : `${miniMapSize*piece.x/100}px`, top : `${miniMapSize*piece.y/100}px`}}
                        >
                            { interieursDatas.pano[currentInt].pieces[room.name].title === piece.direction && 
                                <div className = "visioCone" style = {{rotate : `${piece.offset - initialDir + direction}deg`}}>
                                    <svg 
                                        width = "82" 
                                        height = "82" 
                                        style = {{position: 'absolute', left: '-16px', top: '-16px', pointerEvents : "none"}}
                                    >
                                            <path 
                                                stroke = {programmeDatas.color}
                                                stroke-width = "1" 
                                                stroke-opacity = "0.45" 
                                                fill = {programmeDatas.color} 
                                                fill-opacity = "0.45" 
                                                d = "M 40.5,40.5 L 26.894271311943584,77.04974893028677 A 39,39 0 0 1 15.649853268456681,10.442218854001098 Z" 
                                                style = {{cursor: 'pointer', transform: 'scale(1, 1)', pointerEvents : "none"}}
                                            ></path>
                                    </svg>
                                </div>
                            }
                            <FontAwesomeIcon icon = {faEye}/>
                        </div>

                    )}             
                </div>
            }
        </>
    )
}


export default PanoContent