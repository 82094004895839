// ///////////////////////////////////////////////////////
// Fichier pour la gestion de la partie haute du menu
// lorsque la page active est le detail du lot
// ///////////////////////////////////////////////////////
// Creation : 19/08/2022    -> CGR
// ///////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports 

// Imports system
import React, { useContext } from 'react';
import {
    useNavigate, useLocation,
} from 'react-router-dom';

// Import context
import { programmeContext, programmeNameContext, returnToContext, lotContext, pageContext, languageContext } from './../../../../contexts';

//Imports src
import Button from './../../../../components/button/button';



// /////////////////////////////////////////////////////////////
// Component
const TopLevel2 = () => {

    // Properties
    const navigate = useNavigate()
    const { search } = useLocation()
    const lang = useContext(languageContext)

    // Render
    return (
        <programmeNameContext.Consumer>
            {programmeName => programmeName != null && 
                <programmeContext.Consumer>
                    {programmeDatas => programmeDatas != null && 
                        <pageContext.Consumer>
                            {page => 
                                <returnToContext.Consumer>
                                    {back => 
                                        <lotContext.Consumer>
                                            {lot => lot.lotDatas != null && 
                                                <div id = "mainTopLot">
                                                    <div onClick = {() => {
                                                            if (back.returnTo.includes('programme')) {
                                                                page.setHeaderLevel(1);
                                                                page.setCurrent('programme');
                                                            } else {
                                                                page.setHeaderLevel(0);
                                                                page.setCurrent('rechercher');
                                                            }
                                                            navigate(`/${programmeDatas.urlName}/${back.returnTo}${search}`);
                                                        }
                                                    }>
                                                        <Button icon = 'fleche-l' text = {programmeDatas.languagesDatas[lang]['detail-lot'].header.retour} displayText = {programmeDatas.displayText}/>
                                                    </div>
                                                    <h2 
                                                        id = "mainTopLotName" 
                                                        className = {`mainColor ${lot.lotDatas.name.length >= 6 && 'small'}`}
                                                    >
                                                        {lot.lotDatas.name}
                                                    </h2>
                                                    {programmeDatas.displayStatus && 
                                                        <div 
                                                            id = "mainTopLotStatus" 
                                                            className = {`${lot.lotDatas.statut === "Disponible" ? "mainBackground" : "unable"}`
                                                        }>
                                                            <p>{programmeDatas.languagesDatas[lang].lots.statut[lot.lotDatas.statut]}</p>
                                                        </div>
                                                    }
                                                </div>
                                        }</lotContext.Consumer>
                            }</returnToContext.Consumer>
                        }</pageContext.Consumer>
                }</programmeContext.Consumer>
            }</programmeNameContext.Consumer>
    )
}



// /////////////////////////////////////////////////////////////
// Export
export default TopLevel2