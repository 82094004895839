// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons alongés
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 29/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////

// //////////////////////////////////
// Imports

// Imports system
import React from "react";

// Import components
import SvgIcon from "./../svg/svg";

// Bouton
const ButtonLong = ({ icon, text, state = false, action = () => {} }) => {
  // rendu
  return (
    <div
      className={`buttonLong ${state == text && "mainBackground"}`}
      onClick={action}
    >
      <div>
        <SvgIcon icon={icon}></SvgIcon>
        <p>{text}</p>
      </div>
      <div className="buttonLongGo">
        <SvgIcon icon="fleche-r"></SvgIcon>
      </div>
    </div>
  );
};

export default ButtonLong;
