// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du player pour localiser le lot actif dans le programme
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 09/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useState } from 'react';
import {
    useParams
} from 'react-router-dom';

// Imports Contexts
import { domain, lotContext, pageContext, programmeNameContext, programmeLotsContext, maquetteContext } from '../../../contexts';

// Imports Components
import Player from './../../../components/player/player'



const LocaliserLot = ({loadLot, loadHdForFrame}) => {

    // Properties
    const {lotParam} = useParams();
    const [block, setBlock] = useState(null);
    const [floor, setFloor] = useState(null);
    const [numFrames, setNumFrames] = useState(null);
    const [start, setStart] = useState(null);
    const [focale, setFocale] = useState(null);
    const [player, setPlayer] = useState(null);
    const { lotDatas, setLotDatas } = useContext(lotContext);
    const programmeName = useContext(programmeNameContext);
    const { setCurrent, setHeaderLevel } = useContext(pageContext);
    const lotsDatas = useContext(programmeLotsContext);
    const maquetteDatas = useContext(maquetteContext);


    // Effects
    useEffect(() => { // Verification si le contexte de lot est bien a jour
 
        if (programmeName && lotsDatas && lotDatas === null) {
            setLotDatas(lotsDatas.lots.find(x => x.name === lotParam));  
        }

    }, [programmeName, lotDatas, lotParam, lotsDatas])

    useEffect(() => {

        if (programmeName && lotsDatas) {
            setCurrent(`detail-lot/${lotParam}/situation`); // maj du menu
            setHeaderLevel(2);

            // recuperation des infos du lot
            setBlock(lotsDatas.lots.find(x => x.name === lotParam).batiment);
            setFloor(lotsDatas.lots.find(x => x.name === lotParam).etage);
        }

    }, [programmeName, lotParam, lotsDatas])

    useEffect(() => {
        if (programmeName && maquetteDatas && block) {
            setFocale(maquetteDatas.blocks[block].views.Decoupe.focale);
            setStart(maquetteDatas.global.firstFrame);
            setNumFrames(maquetteDatas.blocks[block].views.Decoupe.frames);
        }
    }, [programmeName, block, maquetteDatas])

    useEffect(() => {
        if (numFrames) {

            setPlayer(
                <Player 
                    programme = {programmeName} 
                    type = "localiser"
                    navUrl = {`${block}/${floor}`}
                    block = {block} 
                    setBlock = {setBlock} 
                    floor = {floor} 
                    numFrames = {numFrames}
                    focale = {focale}
                    start = {0}
                    filterOne = {lotParam}
                    loadHdForFrame = {loadHdForFrame}
                    firstFrame = {start}
                />
            )
        }        
    }, [numFrames])


    // Render
    return (<>{player}</>)
}


export default LocaliserLot;