// /////////////////////////////////////////////////////////////////
// Hook pour controler la rotation du carrousel avec contraintes
// /////////////////////////////////////////////////////////////////
// Creation : 30/04/2022    -> CGR
// Maj : 08/08/2022 -> separation du fichier
// /////////////////////////////////////////////////////////////////


// Imports system
import React, {useState} from 'react';


// ////////////////////////////////////////////////////////////////////////
// Hook pour controler la rotation du carrousel avec contraintes
const useCarrousel = (first, max) => { // Hook dedie au comportement de navigation du carrousel

    const [index, setIndex] = useState(first); // index courant
    
    const change = (next) => { // Fonction pour controler la rotation et eviter les valeurs hors limite
        setIndex(v => {
            if (next >= 0 && next < max) {
                return next;
            } else {
                return v;
            }
        })}

    return [index, change]
}


export default useCarrousel;
