// Fichier pour la gestion des données d'écran

const ratioLimits = [1.5, 3.0] // limites de ratio

export const isOutOfRatio = (ratio) => { // la valeur de ratio donnee sort-elle des limites
    return !(ratio > ratioLimits[0] && ratio < ratioLimits[1])
}

export const screenDatas = () => { // informations d'ecran

    const screen = window.screen
    const body = document.body

    return {
        screen : {
            width : screen.width,
            height : screen.height,
            ratio : screen.width / screen.height,
            outOfStdRatio : isOutOfRatio(screen.width / screen.height)
        }, 
        body : {
            width : body.clientWidth,
            height : body.clientHeight,
            ratio : body.clientWidth / body.clientHeight,
            outOfStdRatio : isOutOfRatio(body.clientWidth / body.clientHeight)
        }
    }
}