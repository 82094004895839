// ///////////////////////////////////////////////////////
// Fichier pour la gestion de la partie haute du menu
// lorsque la page active est la partie decouvrir
// ///////////////////////////////////////////////////////
// Creation : 06/09/2022    -> CGR
// ///////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports 

// Imports system
import React, { useContext } from 'react';
import {
    Link, useLocation,
} from 'react-router-dom';

// Import context
import { pageContext, programmeContext, languageContext} from './../../../../contexts';

//Imports src
import Button from './../../../../components/button/button';



// /////////////////////////////////////////////////////////////
// Component
const TopLevel1 = () => {

    // Contexts
    const { setHeaderLevel, setCurrent } = useContext(pageContext);
    const lang = useContext(languageContext)
    const { search } = useLocation()

    // Functions
    const returnToHome = () => { // Fonction pour le retour a la page d'acceuil
        setHeaderLevel(0);
        setCurrent("");
    }

    // Render
    return (
        <programmeContext.Consumer>
            {programmeDatas => programmeDatas != null && 
                <Link to = {`/${programmeDatas.urlName}${search}`}>
                    <Button 
                        icon = 'home' 
                        text = {programmeDatas.languagesDatas[lang].header.home.home}
                        displayText = {programmeDatas.displayText}
                        action = {returnToHome}
                    />
                </Link>
        }</programmeContext.Consumer>
    )  
}



// /////////////////////////////////////////////////////////////
// Export
export default TopLevel1