import { useParams } from "react-router-dom"
import Player from "../../components/player/player"
import { useState, useContext, useEffect } from "react"
import { programmeNameContext, programmeLotsContext} from "./../../contexts"
import Palette from "../../components/palette/palette"

const LotNoUi = ({loadHdForFrame}) => {

    const {lotname} = useParams()
    const programmeName = useContext(programmeNameContext)
    const lotsInfos = useContext(programmeLotsContext)
    const [floorList, setFloorList] = useState([])
    const [currentFloor, setCurrentFloor] = useState("Niveau 0"); // gestion de l'etage

    useEffect(() => {

        if (!lotsInfos) return;

        const lotDatas = lotsInfos.lots.find((x) => x.name === lotname)

        if (lotDatas != null) {    
          if (lotDatas.sysType.includes("Triplex")) {
            setFloorList(["Niveau 0", "Niveau 1", "Niveau 2"]);
          } else if (lotDatas.sysType.includes("Duplex")) {
            setFloorList(["Niveau 0", "Niveau 1"]);
          } else {
            setFloorList(["Niveau 0"]);
          }
        }
      }, [lotsInfos]);

    if (!lotsInfos) return null

    return (
        <div id = "lotNoUi">
            <Player
                programme={programmeName}
                navUrl={`${lotsInfos.lots.find((x) => x.name === lotname)?.name}/3D/Etage${floorList.indexOf(currentFloor)}`}
                type="lots"
                numFrames={30}
                start={0}
                preloaded={1080}
                resolutions = {[900, 720, 450, 156]}
                maxResolution={1080}
                loadHdForFrame={loadHdForFrame}
            />

            {floorList.length > 1 && (
                <div id="infosLot-palettes">
                    <Palette
                    list={[...floorList].reverse()}
                    current={currentFloor}
                    setCurrent={setCurrentFloor}
                    />
                </div>
            )}
        </div>
    )
}

export default LotNoUi