// /////////////////////////////////////////////////////////////
// Fichier principal du moteur V6
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';

// Imports style
import './style/style.scss';

import ProgrammeDispacher from './components/loader/programmeDispacher';
import Redirect from './components/redirect/redirect';


// ///////////////////////////////////
// Export

const MoteurV6 = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<Redirect to={"https://virtualbuilding.fr"} />} />
                <Route path=':urlProgramme/*' element={<ProgrammeDispacher />}>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById('moteur-v6'));
root.render(
    <React.StrictMode>
        <MoteurV6 />
    </React.StrictMode>
);




/*
// Imports system
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    HashRouter,
    Routes,
    Route
} from 'react-router-dom';

// Imports style
import './style/style.scss';

import ProgrammeDispacher from './components/loader/programmeDispacher';
import Redirect from './components/redirect/redirect';
import ProgrammeLoader from './components/loader/programmeLoader';

// ///////////////////////////////////
// Export
 
const MoteurV6 = () => {

    return (
        <HashRouter>
            <Routes>
                <Route path = '' element = {<ProgrammeLoader programme = {170} />}></Route>
                <Route path = ':urlProgramme/*' element = {<ProgrammeLoader programme = {170} />}>
                </Route>
            </Routes>
        </HashRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById('moteur-v6'));
root.render(
    <React.StrictMode>
        <MoteurV6 />
    </React.StrictMode>
);
*/