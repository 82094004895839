// ///////////////////////////////////////////////////////
// Composant pour la gestion de la page d'accueil
// ///////////////////////////////////////////////////////
// Creation : 18/05/2022 (CGR)
// Maj : 08/08/2022 (CGR)       -> remplacement du fetch par context
// Maj : 20/10/2023 (CGR)       -> clean code (divide component, clean effects, add utils usage to clean file and remove code duplication with project page) 
//                              -> add img / video switch
// ///////////////////////////////////////////////////////

// ///////////////////////////////////////////////////////
// IMPORTS 

// Imports system
import React, { useState, useEffect, useContext, useRef } from 'react'

// Import utils
import { screenDatas } from './../../utils/screen'
import { detectRatio } from './../../utils/medias'

// Import context
import { noHeaderContext, pagesContext, languageContext } from './../../contexts'

// Import components
import Loading from './../../components/loading/loading'




// ///////////////////////////////////////////////////////
// COMPONENTS 

const HomeImg = ({ component }) => { // Component pour le background de type image

    const handleSize = () => {
        detectRatio(document.querySelector("#homeBackground"))
    }

    useEffect(() => {

        window.addEventListener('resize', handleSize) // event resize
        handleSize() // detection initial au construct

        return () => { window.removeEventListener('resize', handleSize) }
    }, [])

    return component
}


const HomeVideo = ({ src }) => { // Component pour le background de type video


    const [style, setStyle] = useState({ padding: '56.25% 56.25% 0 0', position: 'relative' })
    const videoContainer = useRef()
    const videoIframe = useRef()

    const handleSize = () => {
        if (screenDatas().body.ratio < 1.9) {
            setStyle({height : '100%', position:'relative'})
            videoIframe.current.style.width = `${1.9 / (screenDatas().body.ratio - 0.05) * 100}%`
            videoIframe.current.style.translate = `-${(videoIframe.current.offsetWidth - videoContainer.current.offsetWidth) / 2}px`
        } else {
            setStyle({padding:'56.25% 56.25% 0 0', position:'relative'})
            videoIframe.current.style.width = '100%'
            videoIframe.current.style.translate = `0px -${(videoIframe.current.offsetHeight - screenDatas().body.height) / 2}px`
        }
    }

    useEffect(() => {
        if (videoIframe.current) {
            window.addEventListener('resize', handleSize) // event resize
            handleSize() // detection initial au construct
        }
        return () => { window.removeEventListener('resize', handleSize) }
    }, [videoIframe])

    return <div id='video' ref={videoContainer} style={style}>
        <iframe
            ref={videoIframe}
            src={`https://player.vimeo.com/video/${src}&title=0&controls=0&muted=1&quality=1080p&autoplay=1&loop=1&color=ED4230&title=0&byline=0&portrait=0`}
            style={{
                position: "absolute",
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
            }}
            frameBorder="0"
            allow="autoplay" allowFullScreen>
        </iframe>
    </div>
}


const Home = () => { // Component principal de la page Home

    // Initialisation
    const headerState = useContext(noHeaderContext) ? 'noHeader' : 'withHeader'
    const pageDatas = useContext(pagesContext)
    const lang = useContext(languageContext)


    // Properties
    const [currentDisplay, setCurrentDisplay] = useState(null)


    // Effect
    useEffect(() => {

        const checkScreenSize = () => {
            const size = screenDatas()

            if (pageDatas.home?.video && !size.body.outOfStdRatio && size.body.height > 600) { setCurrentDisplay("video") }
            else { setCurrentDisplay("image") }
        }

        if (!currentDisplay) {
            checkScreenSize()
            window.addEventListener('resize', checkScreenSize)
        }


    }, [currentDisplay])


    // Render
    if (!pageDatas?.home) return <Loading />
    else if (!currentDisplay) return <Loading text="Mise en place du média" />
    return <div id='home' className={headerState}>
        {currentDisplay === "video" ?
            <HomeVideo src={pageDatas.home.video} />
            :
            <HomeImg component={pageDatas.home.img} />
        }
        <div id="homeTitleZone">
            <h1 id="homeTitle" className={`${pageDatas.home.color}`}>{pageDatas.home.title[lang]}</h1>
            <h2 id="homeSubTitle" className={`${pageDatas.home.color}`}>{pageDatas.home.subTitle[lang]}</h2>
        </div>
    </div>
}

// ///////////////////////////////////////////////////////
// EXPORT 
export default Home



