import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Player from "./../../../components/player/player";
import { programmeContext, programmeNameContext } from "./../../../contexts";

const Temoin = ({ loadHdForFrame }) => {
  const getTemoin = (list, value) => {
    return (
      Object.keys(list).find((key) => list[key].realName === value) ||
      Object.keys(list)[0]
    );
  };

  const { currentInt } = useParams();
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const temoinsDatas = Object.fromEntries(
    Object.entries(programmeDatas.pagesDetails.interieurs.temoins).sort(
      (a, b) => a[1].order - b[1].order
    )
  );
  const currentTemoinFolder = getTemoin(temoinsDatas, currentInt);
  const nbFrame = temoinsDatas[currentTemoinFolder].nbFrame;

  return (
    <Player
      programme={programmeName}
      navUrl={`/temoins/${currentTemoinFolder}`}
      type="interieurs"
      numFrames={nbFrame}
      start={0}
      maxResolution={1080}
      loadHdForFrame={loadHdForFrame}
    />
  );
};

export default Temoin;
