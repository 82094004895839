import React, { useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom"
import { programmeNameContext, programmeContext } from './../../../contexts'
import PanoNavigationContent from './PanoNavigationContent'



const PanoNavigation = () => {

    const programmeDatas = useContext(programmeContext)
    const navigate = useNavigate()
    const {currentInt} = useParams()
    const { pathname, search } = useLocation()

    useEffect(() => {
        if (!currentInt) {
            navigate(`/${programmeDatas.urlName}/interieurs/pano/${Object.keys(programmeDatas.pagesDetails.interieurs.pano)[0]}${search}`)
        }
    }, [currentInt])

    return (
        <div id = "pano-nav-ui">
            <PanoNavigationContent />
        </div>
    )
}


export default PanoNavigation