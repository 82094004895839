// SVG - Map
import { ReactComponent as Aeroport } from "./../svg/files/aeroport.svg";
import { ReactComponent as AnimalZooVeterinaire } from "./../svg/files/animal-zoo-veterinaire.svg";
import { ReactComponent as Arbre } from "./../svg/files/arbre.svg";
import { ReactComponent as ArtsMartiauxDojo } from "./../svg/files/arts-martiaux-dojo.svg";
import { ReactComponent as AubergeMontagne } from "./../svg/files/auberge-montagne.svg";
import { ReactComponent as Autoroute } from "./../svg/files/autoroute.svg";
import { ReactComponent as Banque } from "./../svg/files/banque.svg";
import { ReactComponent as Basket } from "./../svg/files/basket.svg";
import { ReactComponent as BorneElectrique } from "./../svg/files/borne-electrique.svg";
import { ReactComponent as Boucherie } from "./../svg/files/boucherie.svg";
import { ReactComponent as Boulangerie } from "./../svg/files/boulangerie.svg";
import { ReactComponent as Bus } from "./../svg/files/bus.svg";
import { ReactComponent as Camping } from "./../svg/files/camping.svg";
import { ReactComponent as CasinoDesJeux } from "./../svg/files/casino-des-jeux.svg";
import { ReactComponent as CentreEquestreCheval } from "./../svg/files/centre-equestre-cheval.svg";
import { ReactComponent as CentreVilleBureau } from "./../svg/files/centre-ville-bureau.svg";
import { ReactComponent as Chateau } from "./../svg/files/chateau.svg";
import { ReactComponent as Cinema } from "./../svg/files/cinema.svg";
import { ReactComponent as CollegeFacLyceeUniversite } from "./../svg/files/college-fac-lycee-universite.svg";
import { ReactComponent as CommerceShopping } from "./../svg/files/commerce-shopping.svg";
import { ReactComponent as Congres } from "./../svg/files/congres.svg";
import { ReactComponent as CrecheTetine } from "./../svg/files/creche-tetine.svg";
import { ReactComponent as EcoleMaternelleCube } from "./../svg/files/ecole-maternelle-cube.svg";
import { ReactComponent as EcolePrimaireCartable } from "./../svg/files/ecole-primaire-cartable.svg";
import { ReactComponent as Eglise } from "./../svg/files/eglise.svg";
import { ReactComponent as FontainePlaceTerme } from "./../svg/files/fontaine-place-terme.svg";
import { ReactComponent as Foot } from "./../svg/files/foot.svg";
import { ReactComponent as FriperieVetementBoutique } from "./../svg/files/friperie-vetement-boutique.svg";
import { ReactComponent as Funiculaire } from "./../svg/files/funiculaire.svg";
import { ReactComponent as GareTrain } from "./../svg/files/gare-train.svg";
import { ReactComponent as Golf } from "./../svg/files/golf.svg";
import { ReactComponent as GymSport } from "./../svg/files/gym-sport.svg";
import { ReactComponent as Hopital } from "./../svg/files/hopital.svg";
import { ReactComponent as Hotel } from "./../svg/files/hotel.svg";
import { ReactComponent as Laposte } from "./../svg/files/laposte.svg";
import { ReactComponent as LivreBiliotheque } from "./../svg/files/livre-bibliotheque.svg";
import { ReactComponent as LocationVoiture } from "./../svg/files/location-voiture.svg";
import { ReactComponent as Mairie } from "./../svg/files/mairie.svg";
import { ReactComponent as MarcheRando } from "./../svg/files/marche-rando.svg";
import { ReactComponent as Marche } from "./../svg/files/marche.svg";
import { ReactComponent as MerEtangEauLacRiviereFleuve } from "./../svg/files/mer-etang-eau-lac-riviere-fleuve.svg";
import { ReactComponent as MetroParis } from "./../svg/files/metro-paris.svg";
import { ReactComponent as Metro } from "./../svg/files/metro.svg";
import { ReactComponent as Montagne } from "./../svg/files/montagne.svg";
import { ReactComponent as MonumentHistoireChateau } from "./../svg/files/monument-histoire-chateau.svg";
import { ReactComponent as Musee } from "./../svg/files/musee.svg";
import { ReactComponent as MusiqueConcert } from "./../svg/files/musique-concert.svg";
import { ReactComponent as News } from "./../svg/files/news.svg";
import { ReactComponent as OeilPoint } from "./../svg/files/oeil-point.svg";
import { ReactComponent as OfficeTourismeInfo } from "./../svg/files/office-tourisme-info.svg";
import { ReactComponent as ParcAttraction } from "./../svg/files/parc-attraction.svg";
import { ReactComponent as Parking } from "./../svg/files/parking.svg";
import { ReactComponent as Patinoire } from "./../svg/files/patinoire.svg";
import { ReactComponent as PharmacieCaducee } from "./../svg/files/pharmacie-caducee.svg";
import { ReactComponent as Pharmacie } from "./../svg/files/pharmacie.svg";
import { ReactComponent as Photo } from "./../svg/files/photo.svg";
import { ReactComponent as Pieton } from "./../svg/files/pieton.svg";
import { ReactComponent as PiscineEchelle } from "./../svg/files/piscine-echelle.svg";
import { ReactComponent as PiscineNatation } from "./../svg/files/piscine-natation.svg";
import { ReactComponent as Plage } from "./../svg/files/plage.svg";
import { ReactComponent as PointDeVue } from "./../svg/files/point-de-vue.svg";
import { ReactComponent as Poissonier } from "./../svg/files/poissonier.svg";
import { ReactComponent as Pont } from "./../svg/files/pont.svg";
import { ReactComponent as Port } from "./../svg/files/port.svg";
import { ReactComponent as RameSportEau } from "./../svg/files/rame-sport-eau.svg";
import { ReactComponent as Rer } from "./../svg/files/RER.svg";
import { ReactComponent as RestaurantGastro } from "./../svg/files/restaurant-gastro.svg";
import { ReactComponent as Restaurant } from "./../svg/files/restaurant.svg";
import { ReactComponent as Route } from "./../svg/files/route.svg";
import { ReactComponent as Rugby } from "./../svg/files/rugby.svg";
import { ReactComponent as Skatepark } from "./../svg/files/skatepark.svg";
import { ReactComponent as Ski } from "./../svg/files/ski.svg";
import { ReactComponent as Stade } from "./../svg/files/stade.svg";
import { ReactComponent as StationEssence } from "./../svg/files/station-essence.svg";
import { ReactComponent as SupermarcheCaddie } from "./../svg/files/supermarche-caddie.svg";
import { ReactComponent as SupermarchePanier } from "./../svg/files/supermarche-panier.svg";
import { ReactComponent as Telepherique } from "./../svg/files/telepherique.svg";
import { ReactComponent as Telesiege } from "./../svg/files/telesiege.svg";
import { ReactComponent as Tennis } from "./../svg/files/tennis.svg";
import { ReactComponent as TheatreArt } from "./../svg/files/theatre-art.svg";
import { ReactComponent as TireFesse } from "./../svg/files/tire-fesse.svg";
import { ReactComponent as TramwayParis } from "./../svg/files/tramway-paris.svg";
import { ReactComponent as Tramway } from "./../svg/files/tramway.svg";
import { ReactComponent as Transilien } from "./../svg/files/transilien.svg";
import { ReactComponent as VeloPisteCyclable } from "./../svg/files/velo-piste-cyclable.svg";
import { ReactComponent as Velov } from "./../svg/files/velov.svg";
import { ReactComponent as Voiture } from "./../svg/files/voiture.svg";

// SVG - Générique
import { ReactComponent as Aide } from "./../svg/files/aide.svg";
import { ReactComponent as Ajouter } from "./../svg/files/ajouter.svg";
import { ReactComponent as Alti } from "./../svg/files/alti.svg";
import { ReactComponent as Alti1 } from "./../svg/files/alti1.svg";
import { ReactComponent as Boussole } from "./../svg/files/boussole.svg";
import { ReactComponent as CardBoard } from "./../svg/files/cardboard.svg";
import { ReactComponent as Decoration2 } from "./../svg/files/decorations-2.svg";
import { ReactComponent as Decoration } from "./../svg/files/decorations.svg";
import { ReactComponent as Description } from "./../svg/files/description.svg";
import { ReactComponent as Ensoleillement } from "./../svg/files/ensoleillement.svg";
import { ReactComponent as Envoyer } from "./../svg/files/envoyer.svg";
import { ReactComponent as Escaliers1Bas } from "./../svg/files/escalier-1-bas.svg";
import { ReactComponent as Escaliers1Haut } from "./../svg/files/escalier-1-haut.svg";
import { ReactComponent as Escaliers2Bas } from "./../svg/files/escalier-2-bas.svg";
import { ReactComponent as Escaliers2Haut } from "./../svg/files/escalier-2-haut.svg";
import { ReactComponent as Escaliers } from "./../svg/files/escaliers.svg";
import { ReactComponent as Escaliers1 } from "./../svg/files/escaliers1.svg";
import { ReactComponent as Etages } from "./../svg/files/etages.svg";
import { ReactComponent as Favoris } from "./../svg/files/favoris.svg";
import { ReactComponent as FavorisPlein } from "./../svg/files/favorisPlein.svg";
import { ReactComponent as FlecheL } from "./../svg/files/fleche-l.svg";
import { ReactComponent as FlecheR } from "./../svg/files/fleche-r.svg";
import { ReactComponent as Foret } from "./../svg/files/foret.svg";
import { ReactComponent as Fullscreen } from "./../svg/files/fullscreen.svg";
import { ReactComponent as Galerie } from "./../svg/files/galerie.svg";
import { ReactComponent as Gyroscope } from "./../svg/files/gyroscope.svg";
import { ReactComponent as Hiver } from "./../svg/files/hiver.svg";
import { ReactComponent as Home } from "./../svg/files/home.svg";
import { ReactComponent as JourNuit } from "./../svg/files/jourNuit.svg";
import { ReactComponent as Localisation } from "./../svg/files/localisation.svg";
import { ReactComponent as Localiser } from "./../svg/files/localiser.svg";
import { ReactComponent as M3d } from "./../svg/files/m3d.svg";
import { ReactComponent as ModeEte } from "./../svg/files/mode-ete.svg";
import { ReactComponent as Movie } from "./../svg/files/movie.svg";
import { ReactComponent as OptionsBalcon } from "./../svg/files/options_balcon.svg";
import { ReactComponent as OptionsCabine } from "./../svg/files/options_cabine.svg";
import { ReactComponent as OptionsCave } from "./../svg/files/options_cave.svg";
import { ReactComponent as OptionsDuplex } from "./../svg/files/options_duplex.svg";
import { ReactComponent as OptionsGarage } from "./../svg/files/options_garage.svg";
import { ReactComponent as OptionsJardin } from "./../svg/files/options_jardin.svg";
import { ReactComponent as OptionsLoggia } from "./../svg/files/options_loggia.svg";
import { ReactComponent as OptionsPiscine } from "./../svg/files/options_piscine.svg";
import { ReactComponent as OptionsSousSol } from "./../svg/files/options_soussol.svg";
import { ReactComponent as OptionsTerrasse } from "./../svg/files/options_terrasse.svg";
import { ReactComponent as Panorama } from "./../svg/files/panorama.svg";
import { ReactComponent as Pieces } from "./../svg/files/pieces.svg";
import { ReactComponent as PiecesBalcon } from "./../svg/files/pieces_Balcon.svg";
import { ReactComponent as PiecesBuanderie } from "./../svg/files/pieces_Buanderie.svg";
import { ReactComponent as PiecesBureau } from "./../svg/files/pieces_Bureau.svg";
import { ReactComponent as PiecesCellier } from "./../svg/files/pieces_Cellier.svg";
import { ReactComponent as PiecesChambreBebe } from "./../svg/files/pieces_Chambre-Bebe.svg";
import { ReactComponent as PiecesChambreEnfant } from "./../svg/files/pieces_Chambre-Enfant.svg";
import { ReactComponent as PiecesChambreParentale } from "./../svg/files/pieces_Chambre-Parentale.svg";
import { ReactComponent as PiecesCuisine } from "./../svg/files/pieces_Cuisine.svg";
import { ReactComponent as PiecesDegagement } from "./../svg/files/pieces_Degagement.svg";
import { ReactComponent as PiecesDressing } from "./../svg/files/pieces_Dressing.svg";
import { ReactComponent as PiecesFitness } from "./../svg/files/pieces_Fitness.svg";
import { ReactComponent as PiecesJardin } from "./../svg/files/pieces_Jardin.svg";
import { ReactComponent as PiecesLoggia } from "./../svg/files/pieces_Loggia.svg";
import { ReactComponent as PiecesPiscine } from "./../svg/files/pieces_Piscine.svg";
import { ReactComponent as PiecesSalleDeBain } from "./../svg/files/pieces_Salle-de-bain.svg";
import { ReactComponent as PiecesSalleDEau } from "./../svg/files/pieces_Salle-deau.svg";
import { ReactComponent as PiecesSejour } from "./../svg/files/pieces_Sejour.svg";
import { ReactComponent as PiecesSpa } from "./../svg/files/pieces_Spa.svg";
import { ReactComponent as PiecesTerrasse } from "./../svg/files/pieces_Terrasse.svg";
import { ReactComponent as Plan3d } from "./../svg/files/plan-3d.svg";
import { ReactComponent as Plan } from "./../svg/files/plan.svg";
import { ReactComponent as Prestations } from "./../svg/files/prestations.svg";
import { ReactComponent as Recherche } from "./../svg/files/recherche.svg";
import { ReactComponent as Residence } from "./../svg/files/residence.svg";
import { ReactComponent as Settings } from "./../svg/files/settings.svg";
import { ReactComponent as Situer } from "./../svg/files/situer.svg";
import { ReactComponent as Supprimer } from "./../svg/files/supprimer.svg";
import { ReactComponent as Telecharger } from "./../svg/files/telecharger.svg";
import { ReactComponent as Visites } from "./../svg/files/visites.svg";

// INFO: Dossier VIM/
import { ReactComponent as FlecheBasSvg } from "./../svg/files/VIM/fleche-bas.svg";
import { ReactComponent as FlecheDroiteSvg } from "./../svg/files/VIM/fleche-droite.svg";
import { ReactComponent as FlecheGaucheSvg } from "./../svg/files/VIM/fleche-gauche.svg";
import { ReactComponent as FlecheHautSvg } from "./../svg/files/VIM/fleche-haut.svg";

// SVG - Detail Lot
import { ReactComponent as OptionsDressing } from "./../svg/files/options_dressing.svg";
import { ReactComponent as PiecesEntree } from "./../svg/files/pieces_Degagement.svg";

import { ReactComponent as V5A3 } from "./../svg/files/v5_a3.svg";
import { ReactComponent as V5Aeroport } from "./../svg/files/v5_aeroport.svg";
import { ReactComponent as V5Alimentaire } from "./../svg/files/v5_alimentaire.svg";
import { ReactComponent as V5Arbre } from "./../svg/files/v5_arbre.svg";
import { ReactComponent as V5ArtsMartiaux } from "./../svg/files/v5_arts_martiaux.svg";
import { ReactComponent as V5AubergeMontagne } from "./../svg/files/v5_auberge_montagne.svg";
import { ReactComponent as V5Autolib } from "./../svg/files/v5_autolib.svg";
import { ReactComponent as V5Autoroute } from "./../svg/files/v5_autoroute.svg";
import { ReactComponent as V5AutorouteA3 } from "./../svg/files/v5_autoroute_a3.svg";
import { ReactComponent as V5Banque } from "./../svg/files/v5_banque.svg";
import { ReactComponent as V5Basket } from "./../svg/files/v5_basket.svg";
import { ReactComponent as V5Bibliotheque } from "./../svg/files/v5_bibliotheque.svg";
import { ReactComponent as V5Boucherie } from "./../svg/files/v5_boucherie.svg";
import { ReactComponent as V5Boulangerie } from "./../svg/files/v5_boulangerie.svg";
import { ReactComponent as V5Bouygues } from "./../svg/files/v5_bouygues.svg";
import { ReactComponent as V5Bus } from "./../svg/files/v5_bus.svg";
import { ReactComponent as V5Caducee } from "./../svg/files/v5_caducee.svg";
import { ReactComponent as V5Casino } from "./../svg/files/v5_casino.svg";
import { ReactComponent as V5Casino2 } from "./../svg/files/v5_casino_2.svg";
import { ReactComponent as V5CentreEquestre } from "./../svg/files/v5_centre_equestre.svg";
import { ReactComponent as V5CentreVille } from "./../svg/files/v5_centreville.svg";
import { ReactComponent as V5Cern } from "./../svg/files/v5_cern.svg";
import { ReactComponent as V5Chateau } from "./../svg/files/v5_chateau.svg";
import { ReactComponent as V5Cimetiere } from "./../svg/files/v5_cimetiere.svg";
import { ReactComponent as V5Cinema } from "./../svg/files/v5_cinema.svg";
import { ReactComponent as V5Commerce } from "./../svg/files/v5_commerce.svg";
import { ReactComponent as V5Concert } from "./../svg/files/v5_concert.svg";
import { ReactComponent as V5Concert2 } from "./../svg/files/v5_concert_2.svg";
import { ReactComponent as V5Congres } from "./../svg/files/v5_congres.svg";
import { ReactComponent as V5Douane } from "./../svg/files/v5_douane.svg";
import { ReactComponent as V5Eglise } from "./../svg/files/v5_eglise.svg";
import { ReactComponent as V5Entreprise } from "./../svg/files/v5_entreprise.svg";
import { ReactComponent as V5Etang } from "./../svg/files/v5_etang.svg";
import { ReactComponent as V5Fontaine } from "./../svg/files/v5_fontaine.svg";
import { ReactComponent as V5Friperie } from "./../svg/files/v5_friperie.svg";
import { ReactComponent as V5Gare } from "./../svg/files/v5_gare.svg";
import { ReactComponent as V5Golf } from "./../svg/files/v5_golf.svg";
import { ReactComponent as V5GrandParisExpress } from "./../svg/files/v5_grand_paris_express.svg";
import { ReactComponent as V5Hopital } from "./../svg/files/v5_hopital.svg";
import { ReactComponent as V5Hotel } from "./../svg/files/v5_hotel.svg";
import { ReactComponent as V5Mairie } from "./../svg/files/v5_mairie.svg";
import { ReactComponent as V5Marche } from "./../svg/files/v5_marche.svg";
import { ReactComponent as V5Metro } from "./../svg/files/v5_metro.svg";
import { ReactComponent as V5MetroCarte } from "./../svg/files/v5_metro_carte.svg";
import { ReactComponent as V5Mjc } from "./../svg/files/v5_mjc.svg";
import { ReactComponent as V5Montagne } from "./../svg/files/v5_montagne.svg";
import { ReactComponent as V5Musee } from "./../svg/files/v5_musee.svg";
import { ReactComponent as V5News } from "./../svg/files/v5_news.svg";
import { ReactComponent as V5Novartis } from "./../svg/files/v5_novartis.svg";
import { ReactComponent as V5OfficeToursime } from "./../svg/files/v5_office_tourisme.svg";
import { ReactComponent as V5Parc } from "./../svg/files/v5_parc.svg";
import { ReactComponent as V5Parking } from "./../svg/files/v5_parking.svg";
import { ReactComponent as V5Patinoire } from "./../svg/files/v5_patinoire.svg";
import { ReactComponent as V5Pharmacie } from "./../svg/files/v5_pharmacie.svg";
import { ReactComponent as V5Piscine } from "./../svg/files/v5_piscine.svg";
import { ReactComponent as V5PisteCyclable } from "./../svg/files/v5_piste_cyclable.svg";
import { ReactComponent as V5Plage } from "./../svg/files/v5_plage.svg";
import { ReactComponent as V5Pont } from "./../svg/files/v5_pont.svg";
import { ReactComponent as V5Port } from "./../svg/files/v5_port.svg";
import { ReactComponent as V5Poste } from "./../svg/files/v5_poste.svg";
import { ReactComponent as V5Primaire } from "./../svg/files/v5_primaire.svg";
import { ReactComponent as V5Programme } from "./../svg/files/v5_programme.svg";
import { ReactComponent as V5Projet } from "./../svg/files/v5_projet.svg";
import { ReactComponent as V5Rafting } from "./../svg/files/v5_rafting.svg";
import { ReactComponent as V5Rer } from "./../svg/files/v5_rer.svg";
import { ReactComponent as V5Restaurant } from "./../svg/files/v5_restaurant.svg";
import { ReactComponent as V5RestaurantChef } from "./../svg/files/v5_restaurant_chef.svg";
import { ReactComponent as V5RestaurantCloche } from "./../svg/files/v5_restaurant_cloche.svg";
import { ReactComponent as V5Rugby } from "./../svg/files/v5_rugby.svg";
import { ReactComponent as V5SalleDeSport } from "./../svg/files/v5_salle_de_sport.svg";
import { ReactComponent as V5Ski } from "./../svg/files/v5_ski.svg";
import { ReactComponent as V5Spot } from "./../svg/files/v5_spot.svg";
import { ReactComponent as V5Spot2 } from "./../svg/files/v5_spot_2.svg";
import { ReactComponent as V5Stade } from "./../svg/files/v5_stade.svg";
import { ReactComponent as V5StationEssence } from "./../svg/files/v5_station_essence.svg";
import { ReactComponent as V5Synagogue } from "./../svg/files/v5_synagogue.svg";
import { ReactComponent as V5Tabac } from "./../svg/files/v5_tabac.svg";
import { ReactComponent as V5Telecabine } from "./../svg/files/v5_telecabine.svg";
import { ReactComponent as V5Telesiege } from "./../svg/files/v5_telesiege.svg";
import { ReactComponent as V5Tennis } from "./../svg/files/v5_tennis.svg";
import { ReactComponent as V5Tetine } from "./../svg/files/v5_tetine.svg";
import { ReactComponent as V5Theatre } from "./../svg/files/v5_theatre.svg";
import { ReactComponent as V5Tourisme } from "./../svg/files/v5_tourisme.svg";
import { ReactComponent as V5Train } from "./../svg/files/v5_train.svg";
import { ReactComponent as V5Transilien } from "./../svg/files/v5_transilien.svg";
import { ReactComponent as V5Transilien2 } from "./../svg/files/v5_transilien_2.svg";
import { ReactComponent as V5Transport } from "./../svg/files/v5_transport.svg";
import { ReactComponent as V5Universite } from "./../svg/files/v5_universite.svg";
import { ReactComponent as V5Velov } from "./../svg/files/v5_velov.svg";
import { ReactComponent as V5Velov2 } from "./../svg/files/v5_velov_2.svg";
import { ReactComponent as V5Vitra } from "./../svg/files/v5_vitra.svg";
import { ReactComponent as V5Voiture } from "./../svg/files/v5_voiture.svg";
import { ReactComponent as V5Zoo } from "./../svg/files/v5_zoo.svg";
import { ReactComponent as V5Zoo2 } from "./../svg/files/v5_zoo_2.svg";

import { ReactComponent as TestMapRer } from "./../svg/files/map_RER.svg";
import { ReactComponent as TestMapAeropot } from "./../svg/files/map_aeroport.svg";
import { ReactComponent as TestMapAutoroute } from "./../svg/files/map_autoroute.svg";
import { ReactComponent as TestMapBus } from "./../svg/files/map_bus.svg";
import { ReactComponent as TestMapMetro } from "./../svg/files/map_metro.svg";
import { ReactComponent as TestMapMetroParis } from "./../svg/files/map_metro_paris.svg";
import { ReactComponent as TestMapParking } from "./../svg/files/map_parking.svg";
import { ReactComponent as TestMapPieton } from "./../svg/files/map_pieton.svg";
import { ReactComponent as TestMapRoute } from "./../svg/files/map_route.svg";
import { ReactComponent as TestMapSkatepark } from "./../svg/files/map_skatepark.svg";
import { ReactComponent as TestMapTramway } from "./../svg/files/map_tramway.svg";
import { ReactComponent as TestMapTramway2 } from "./../svg/files/map_tramway2.svg";
import { ReactComponent as TestMapTramwayParis } from "./../svg/files/map_tramway_paris.svg";
import { ReactComponent as TestMapTransilien } from "./../svg/files/map_transilien.svg";
import { ReactComponent as TestMapVelo } from "./../svg/files/map_velo.svg";
import { ReactComponent as TestMapVoiture } from "./../svg/files/map_voiture.svg";
import { ReactComponent as TestPhoAutoroute } from "./../svg/files/pho_autoroute.svg";
import { ReactComponent as TestPhoBus } from "./../svg/files/pho_bus.svg";
import { ReactComponent as TestPhoGare } from "./../svg/files/pho_gare.svg";
import { ReactComponent as TestPhoMetro } from "./../svg/files/pho_metro.svg";
import { ReactComponent as TestPhoParking } from "./../svg/files/pho_parking.svg";
import { ReactComponent as TestPhoPieton } from "./../svg/files/pho_pieton.svg";
import { ReactComponent as TestPhoPort } from "./../svg/files/pho_port.svg";
import { ReactComponent as TestPhoRoute } from "./../svg/files/pho_route.svg";
import { ReactComponent as TestPhoTramway } from "./../svg/files/pho_tramway.svg";
import { ReactComponent as TestPhoVelo } from "./../svg/files/pho_velo.svg";
import { ReactComponent as TestPhoVoiture } from "./../svg/files/pho_voiture.svg";

import { ReactComponent as VimSvg } from "./../svg/files/vim.svg";

let svgHelper = {};

//TODO: Rendre le tout dynamique, éviter de créer systématiquement une ligne par .svg

//TODO: Gérer cas sans icon (éventuellement dans button.js)
svgHelper[""] = <Description />;

//DESC: Boutons génériques./
svgHelper["aide"] = <Aide />;
svgHelper["ajouter"] = <Ajouter />;
svgHelper["alti"] = <Alti />;
svgHelper["alti1"] = <Alti1 />;
svgHelper["cardboard"] = <CardBoard />;
svgHelper["ensoleillement"] = <Ensoleillement />;
svgHelper["escaliers"] = <Escaliers />;
svgHelper["escaliers1"] = <Escaliers1 />;
svgHelper["escalier-1-haut"] = <Escaliers1Haut />;
svgHelper["escalier-1-bas"] = <Escaliers1Bas />;
svgHelper["escalier-2-haut"] = <Escaliers2Haut />;
svgHelper["escalier-2-bas"] = <Escaliers2Bas />;
svgHelper["envoyer"] = <Envoyer />;
svgHelper["fullscreen"] = <Fullscreen />;
svgHelper["gyroscope"] = <Gyroscope />;
svgHelper["home"] = <Home />;
svgHelper["settings1"] = <Settings />;
svgHelper["situer"] = <Situer />;
svgHelper["supprimer"] = <Supprimer />;
svgHelper["telecharger"] = <Telecharger />;
//DESC: Onglets nav     ./
svgHelper["alti"] = <Alti />;
svgHelper["decorations"] = <Decoration />;
svgHelper["decorations-2"] = <Decoration2 />;
svgHelper["description"] = <Description />;
svgHelper["etages"] = <Etages />;
svgHelper["favoris"] = <Favoris />;
svgHelper["favorisPlein"] = <FavorisPlein />;
svgHelper["fleche-gauche"] = <FlecheGaucheSvg />;
svgHelper["fleche-droite"] = <FlecheDroiteSvg />;
svgHelper["fleche-haut"] = <FlecheHautSvg />;
svgHelper["fleche-bas"] = <FlecheBasSvg />;
svgHelper["fleche-l"] = <FlecheL />;
svgHelper["fleche-r"] = <FlecheR />;
svgHelper["galerie"] = <Galerie />;
svgHelper["localisation"] = <Localisation />;
svgHelper["localiser"] = <Localiser />;
svgHelper["m3d"] = <M3d />;
svgHelper["movie"] = <Movie />;
svgHelper["mode_ete"] = <ModeEte />;
svgHelper["panorama"] = <Panorama />;
svgHelper["prestations"] = <Prestations />;
svgHelper["pieces"] = <Pieces />;
svgHelper["plan"] = <Plan />;
svgHelper["recherche"] = <Recherche />;
svgHelper["residence"] = <Residence />;
svgHelper["settings"] = <Settings />;
svgHelper["visites"] = <Visites />;
svgHelper["hiver"] = <Hiver />;
svgHelper["foret"] = <Foret />;
svgHelper["jourNuit"] = <JourNuit />;
svgHelper["mode-ete"] = <ModeEte />;
//DESC: Icons d'options
svgHelper["options_balcon"] = <OptionsBalcon />;
svgHelper["options_cave"] = <OptionsCave />;
svgHelper["options_cabine"] = <OptionsCabine />;
svgHelper["options_dressing"] = <OptionsDressing />;
svgHelper["options_duplex"] = <OptionsDuplex />;
svgHelper["options_garage"] = <OptionsDuplex />;
svgHelper["options_jardin"] = <OptionsJardin />;
svgHelper["options_loggia"] = <OptionsLoggia />;
svgHelper["options_piscine"] = <OptionsPiscine />;
svgHelper["options_soussol"] = <OptionsSousSol />;
svgHelper["options_terrasse"] = <OptionsTerrasse />;
svgHelper["options_garage"] = <OptionsGarage />;
//DESC: Icons de pieces
svgHelper["pieces_Balcon"] = <PiecesBalcon />;
svgHelper["pieces_Buanderie"] = <PiecesBuanderie />;
svgHelper["pieces_Bureau"] = <PiecesBureau />;
svgHelper["pieces_Cellier"] = <PiecesCellier />;
svgHelper["pieces_Chambre-Bebe"] = <PiecesChambreBebe />;
svgHelper["pieces_Chambre-Enfant"] = <PiecesChambreEnfant />;
svgHelper["pieces_Chambre-Parentale"] = <PiecesChambreParentale />;
svgHelper["pieces_Cuisine"] = <PiecesCuisine />;
svgHelper["pieces_Degagement"] = <PiecesDegagement />;
svgHelper["pieces_Entree"] = <PiecesEntree />;
svgHelper["pieces_Fitness"] = <PiecesFitness />;
svgHelper["pieces_Jardin"] = <PiecesJardin />;
svgHelper["pieces_Loggia"] = <PiecesLoggia />;
svgHelper["pieces_Piscine"] = <PiecesPiscine />;
svgHelper["pieces_Salle-de-bain"] = <PiecesSalleDeBain />;
svgHelper["pieces_Salle-deau"] = <PiecesSalleDEau />;
svgHelper["pieces_Sejour"] = <PiecesSejour />;
svgHelper["pieces_Dressing"] = <PiecesDressing />;
svgHelper["pieces_Terrasse"] = <PiecesTerrasse />;
svgHelper["pieces_Spa"] = <PiecesSpa />;

// DESC : Icons Map | v5
svgHelper["aeroport"] = <Aeroport />;
svgHelper["animal-zoo-veterinaire"] = <AnimalZooVeterinaire />;
svgHelper["arbre"] = <Arbre />;
svgHelper["arts-martiaux-dojo"] = <ArtsMartiauxDojo />;
svgHelper["auberge-montagne"] = <AubergeMontagne />;
svgHelper["autoroute"] = <Autoroute />;
svgHelper["banque"] = <Banque />;
svgHelper["basket"] = <Basket />;
svgHelper["borne-electrique"] = <BorneElectrique />;
svgHelper["boucherie"] = <Boucherie />;
svgHelper["boulangerie"] = <Boulangerie />;
svgHelper["bus"] = <Bus />;
svgHelper["camping"] = <Camping />;
svgHelper["casino-des-jeux"] = <CasinoDesJeux />;
svgHelper["centre-equestre-cheval"] = <CentreEquestreCheval />;
svgHelper["centre-ville-bureau"] = <CentreVilleBureau />;
svgHelper["chateau"] = <Chateau />;
svgHelper["cinema"] = <Cinema />;
svgHelper["college-fac-lycee-universite"] = <CollegeFacLyceeUniversite />;
svgHelper["commerce-shopping"] = <CommerceShopping />;
svgHelper["congres"] = <Congres />;
svgHelper["creche-tetine"] = <CrecheTetine />;
svgHelper["ecole-maternelle-cube"] = <EcoleMaternelleCube />;
svgHelper["ecole-primaire-cartable"] = <EcolePrimaireCartable />;
svgHelper["eglise"] = <Eglise />;
svgHelper["fontaine-place-terme"] = <FontainePlaceTerme />;
svgHelper["foot"] = <Foot />;
svgHelper["friperie-vetement-boutique"] = <FriperieVetementBoutique />;
svgHelper["funiculaire"] = <Funiculaire />;
svgHelper["gare-train"] = <GareTrain />;
svgHelper["golf"] = <Golf />;
svgHelper["gym-sport"] = <GymSport />;
svgHelper["hopital"] = <Hopital />;
svgHelper["hotel"] = <Hotel />;
svgHelper["laposte"] = <Laposte />;
svgHelper["livre-bibliotheque"] = <LivreBiliotheque />;
svgHelper["location-voiture"] = <LocationVoiture />;
svgHelper["mairie"] = <Mairie />;
svgHelper["marche"] = <Marche />;
svgHelper["marche-rando"] = <MarcheRando />;
svgHelper["mer-etang-eau-lac-riviere-fleuve"] = <MerEtangEauLacRiviereFleuve />;
svgHelper["metro"] = <Metro />;
svgHelper["metro-paris"] = <MetroParis />;
svgHelper["montagne"] = <Montagne />;
svgHelper["monument-histoire-chateau"] = <MonumentHistoireChateau />;
svgHelper["musee"] = <Musee />;
svgHelper["musique-concert"] = <MusiqueConcert />;
svgHelper["news"] = <News />;
svgHelper["oeil-point"] = <OeilPoint />;
svgHelper["office-tourisme-info"] = <OfficeTourismeInfo />;
svgHelper["parc-attraction"] = <ParcAttraction />;
svgHelper["parking"] = <Parking />;
svgHelper["patinoire"] = <Patinoire />;
svgHelper["pharmacie"] = <Pharmacie />;
svgHelper["pharmacie-caducee"] = <PharmacieCaducee />;
svgHelper["photo"] = <Photo />;
svgHelper["pieton"] = <Pieton />;
svgHelper["piscine-echelle"] = <PiscineEchelle />;
svgHelper["piscine-natation"] = <PiscineNatation />;
svgHelper["plage"] = <Plage />;
svgHelper["point-de-vue"] = <PointDeVue />;
svgHelper["poissonier"] = <Poissonier />;
svgHelper["pont"] = <Pont />;
svgHelper["port"] = <Port />;
svgHelper["rame-sport-eau"] = <RameSportEau />;
svgHelper["RER"] = <Rer />;
svgHelper["restaurant"] = <Restaurant />;
svgHelper["restaurant-gastro"] = <RestaurantGastro />;
svgHelper["route"] = <Route />;
svgHelper["rugby"] = <Rugby />;
svgHelper["skatepark"] = <Skatepark />;
svgHelper["ski"] = <Ski />;
svgHelper["station-essence"] = <StationEssence />;
svgHelper["supermarche-caddie"] = <SupermarcheCaddie />;
svgHelper["supermarche-panier"] = <SupermarchePanier />;
svgHelper["stade"] = <Stade />;
svgHelper["telepherique"] = <Telepherique />;
svgHelper["telesiege"] = <Telesiege />;
svgHelper["tennis"] = <Tennis />;
svgHelper["theatre-art"] = <TheatreArt />;
svgHelper["tire-fesse"] = <TireFesse />;
svgHelper["tramway"] = <Tramway />;
svgHelper["tramway-paris"] = <TramwayParis />;
svgHelper["transilien"] = <Transilien />;
svgHelper["velo-piste-cyclable"] = <VeloPisteCyclable />;
svgHelper["Velov"] = <Velov />;
svgHelper["voiture"] = <Voiture />;
svgHelper["plan_3d"] = <Plan3d />;

svgHelper["v5_a3"] = <V5A3 />;
svgHelper["v5_aeroport"] = <V5Aeroport />;
svgHelper["v5_alimentaire"] = <V5Alimentaire />;
svgHelper["v5_arbre"] = <V5Arbre />;
svgHelper["v5_arts_martiaux"] = <V5ArtsMartiaux />;
svgHelper["v5_auberge_montagne"] = <V5AubergeMontagne />;
svgHelper["v5_autolib"] = <V5Autolib />;
svgHelper["v5_autoroute"] = <V5Autoroute />;
svgHelper["v5_autoroute_a3"] = <V5AutorouteA3 />;
svgHelper["v5_banque"] = <V5Banque />;
svgHelper["v5_basket"] = <V5Basket />;
svgHelper["v5_bibliotheque"] = <V5Bibliotheque />;
svgHelper["v5_boucherie"] = <V5Boucherie />;
svgHelper["v5_boulangerie"] = <V5Boulangerie />;
svgHelper["v5_bouygues"] = <V5Bouygues />;
svgHelper["v5_bus"] = <V5Bus />;
svgHelper["v5_caducee"] = <V5Caducee />;
svgHelper["v5_casino"] = <V5Casino />;
svgHelper["v5_casino_2"] = <V5Casino2 />;
svgHelper["v5_cern"] = <V5Cern />;
svgHelper["v5_centre_equestre"] = <V5CentreEquestre />;
svgHelper["v5_centreville"] = <V5CentreVille />;
svgHelper["v5_chateau"] = <V5Chateau />;
svgHelper["v5_cimetiere"] = <V5Cimetiere />;
svgHelper["v5_cinema"] = <V5Cinema />;
svgHelper["v5_commerce"] = <V5Commerce />;
svgHelper["v5_concert"] = <V5Concert />;
svgHelper["v5_concert_2"] = <V5Concert2 />;
svgHelper["v5_congres"] = <V5Congres />;
svgHelper["v5_douane"] = <V5Douane />;
svgHelper["v5_eglise"] = <V5Eglise />;
svgHelper["v5_entreprise"] = <V5Entreprise />;
svgHelper["v5_etang"] = <V5Etang />;
svgHelper["v5_fontaine"] = <V5Fontaine />;
svgHelper["v5_friperie"] = <V5Friperie />;
svgHelper["v5_gare"] = <V5Gare />;
svgHelper["v5_golf"] = <V5Golf />;
svgHelper["v5_grand_paris_express"] = <V5GrandParisExpress />;
svgHelper["v5_hopital"] = <V5Hopital />;
svgHelper["v5_hotel"] = <V5Hotel />;
svgHelper["v5_mairie"] = <V5Mairie />;
svgHelper["v5_marche"] = <V5Marche />;
svgHelper["v5_metro"] = <V5Metro />;
svgHelper["v5_metro_carte"] = <V5MetroCarte />;
svgHelper["v5_mjc"] = <V5Mjc />;
svgHelper["v5_montagne"] = <V5Montagne />;
svgHelper["v5_musee"] = <V5Musee />;
svgHelper["v5_news"] = <V5News />;
svgHelper["v5_novartis"] = <V5Novartis />;
svgHelper["v5_office_toursime"] = <V5OfficeToursime />;
svgHelper["v5_parc"] = <V5Parc />;
svgHelper["v5_parking"] = <V5Parking />;
svgHelper["v5_patinoire"] = <V5Patinoire />;
svgHelper["v5_pharmacie"] = <V5Pharmacie />;
svgHelper["v5_piscine"] = <V5Piscine />;
svgHelper["v5_piste_cyclable"] = <V5PisteCyclable />;
svgHelper["v5_plage"] = <V5Plage />;
svgHelper["v5_pont"] = <V5Pont />;
svgHelper["v5_port"] = <V5Port />;
svgHelper["v5_poste"] = <V5Poste />;
svgHelper["v5_primaire"] = <V5Primaire />;
svgHelper["v5_programme"] = <V5Programme />;
svgHelper["v5_projet"] = <V5Projet />;
svgHelper["v5_rafting"] = <V5Rafting />;
svgHelper["v5_rer"] = <V5Rer />;
svgHelper["v5_restaurant"] = <V5Restaurant />;
svgHelper["v5_restaurant_chef"] = <V5RestaurantChef />;
svgHelper["v5_restaurant_cloche"] = <V5RestaurantCloche />;
svgHelper["v5_rugby"] = <V5Rugby />;
svgHelper["v5_salle_de_sport"] = <V5SalleDeSport />;
svgHelper["v5_ski"] = <V5Ski />;
svgHelper["v5_spot"] = <V5Spot />;
svgHelper["v5_spot_2"] = <V5Spot2 />;
svgHelper["v5_stade"] = <V5Stade />;
svgHelper["v5_station_essence"] = <V5StationEssence />;
svgHelper["v5_synagogue"] = <V5Synagogue />;
svgHelper["v5_tabac"] = <V5Tabac />;
svgHelper["v5_telecabine"] = <V5Telecabine />;
svgHelper["v5_telesiege"] = <V5Telesiege />;
svgHelper["v5_tennis"] = <V5Tennis />;
svgHelper["v5_tetine"] = <V5Tetine />;
svgHelper["v5_theatre"] = <V5Theatre />;
svgHelper["v5_tourisme"] = <V5Tourisme />;
svgHelper["v5_train"] = <V5Train />;
svgHelper["v5_transport"] = <V5Transport />;
svgHelper["v5_transilien"] = <V5Transilien />;
svgHelper["v5_transilien_2"] = <V5Transilien2 />;
svgHelper["v5_universite"] = <V5Universite />;
svgHelper["v5_velov"] = <V5Velov />;
svgHelper["v5_velov_2"] = <V5Velov2 />;
svgHelper["v5_vitra"] = <V5Vitra />;
svgHelper["v5_voiture"] = <V5Voiture />;
svgHelper["v5_zoo"] = <V5Zoo />;
svgHelper["v5_zoo_2"] = <V5Zoo2 />;

svgHelper["map_aeroport"] = <TestMapAeropot />;
svgHelper["map_autoroute"] = <TestMapAutoroute />;
svgHelper["map_bus"] = <TestMapBus />;
svgHelper["map_metro"] = <TestMapMetro />;
svgHelper["map_metro_paris"] = <TestMapMetroParis />;
svgHelper["map_parking"] = <TestMapParking />;
svgHelper["map_pieton"] = <TestMapPieton />;
svgHelper["map_RER"] = <TestMapRer />;
svgHelper["map_route"] = <TestMapRoute />;
svgHelper["map_skatepark"] = <TestMapSkatepark />;
svgHelper["map_tramway"] = <TestMapTramway />;
svgHelper["map_tramway_paris"] = <TestMapTramwayParis />;
svgHelper["map_tramway2"] = <TestMapTramway2 />;
svgHelper["map_transilien"] = <TestMapTransilien />;
svgHelper["map_velo"] = <TestMapVelo />;
svgHelper["map_voiture"] = <TestMapVoiture />;
svgHelper["pho_autoroute"] = <TestPhoAutoroute />;
svgHelper["pho_bus"] = <TestPhoBus />;
svgHelper["pho_metro"] = <TestPhoMetro />;
svgHelper["pho_parking"] = <TestPhoParking />;
svgHelper["pho_pieton"] = <TestPhoPieton />;
svgHelper["pho_gare"] = <TestPhoGare />;
svgHelper["pho_port"] = <TestPhoPort />;
svgHelper["pho_route"] = <TestPhoRoute />;
svgHelper["pho_tramway"] = <TestPhoTramway />;
svgHelper["pho_velo"] = <TestPhoVelo />;
svgHelper["pho_voiture"] = <TestPhoVoiture />;

svgHelper["boussole"] = <Boussole />;
svgHelper["vim"] = <VimSvg />;

export default svgHelper;
