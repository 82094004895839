// /////////////////////////////////////////////////////////////
// Fichier principal du moteur V6
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useRef } from 'react';
import {
    useParams,
} from 'react-router-dom';


// Imports src
import { 
    domain
} from './../../contexts';

import Page404 from './../../pages/page404/page404';
import ProgrammeLoader from './programmeLoader'


const ProgrammeDispacher = () => {

    // Composant pour la recuperation du parametre d'url -- retourne une 404 si n'existe pas
    
    const {urlProgramme} = useParams();
    const olreadyChecked = useRef(false);
    const [programmeContent, setProgrammeContent] = useState(null);

    useEffect(() => {

        if (olreadyChecked.current) {return;}
        olreadyChecked.current = true;

        fetch(`${domain}/programme/${urlProgramme}/getid`, { mode: 'cors' })
        .then(response => response.json())
        .then(data => setProgrammeContent(<ProgrammeLoader programme={data.id} />))
        .catch(() => setProgrammeContent(<Page404 />))
    }, [])

    return (<>{programmeContent}</>)
}

export default ProgrammeDispacher