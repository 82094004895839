// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons standards envellopes par un lien
// ////////////////////////////////////////////////////////////////////////////////
// Creation :   13/12/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './button';

// Bouton
const LinkedButton = ({
    route,
    icon, 
    text, 
    state = false, 
    action = () => {}, 
    displayText = "with-hover"
}) => {

    
    // propreties
    const [activ, setActive] = useState(false);


    // effects
    useEffect(() => { // verification de l'etat du bouton
        setActive(() => state === true ? "activ" : "");
    }, [state])


    // rendu
    return (
        <Link tabIndex="-1" className = "linkedBtn" to = {route}>
            <Button 
                icon={icon} 
                text={text} 
                state={state} 
                action={action} 
                displayText={displayText}
            ></Button>
        </Link>

    )
}

export default LinkedButton;
