// /////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des elements 3D en surimpression des images pre-calcules
// /////////////////////////////////////////////////////////////////////////////////
// Creation : 21/04/2022  CGR
// /////////////////////////////////////////////////////////////////////////////////

// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Import context
import {
  languageContext,
  noHeaderContext,
  pageContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
} from "../../contexts";

// Components
import InputNumber from "../../components/inputs/inputNumber";
import CustomSelect from "./../../components/inputs/customSelect";
import SvgIcon from "./../../components/svg/svg";
import Lot from "./lot";

// ////////////////////////////////////////////////////////////////////////
// Page rechercher

const search = (source, filters = {}, floors = []) => {
  let result = source;

  // filtre de prix
  if (filters.prix) {
    result = result.filter((lot) => lot.prix === 0 || lot.prix <= filters.prix);
  }
  // filtre de surface min
  if (filters.minSurface) {
    result = result.filter(
      (lot) => lot.surface === 0 || lot.surface >= filters.minSurface
    );
  }
  // filtre de surface max
  if (filters.maxSurface) {
    result = result.filter(
      (lot) => lot.surface === 0 || lot.surface <= filters.maxSurface
    );
  }
  // filtre de batiment
  if (filters.blocks) {
    result = result.filter(
      (lot) => lot.batiment === null || filters.blocks.includes(lot.batiment)
    );
  }
  // filtre de type
  if (filters.types) {
    result = result.filter(
      (lot) => lot.numPieces === 0 || filters.types.includes(lot.numPieces)
    );
  }
  // filtre d'orientation
  if (filters.orientations) {
    result = result.filter(
      (lot) =>
        lot.orientation === "Non-spécifiée" ||
        filters.orientations.filter((d) => lot.orientation.includes(d))
          .length !== 0
    );
  }
  // filtre d'etages
  result = result.filter(
    (lot) => lot.etage === null || floors.indexOf(lot.etage) >= filters.minFloor
  );
  result = result.filter(
    (lot) => lot.etage === null || floors.indexOf(lot.etage) <= filters.maxFloor
  );
  // filtre d'options
  if (filters.options) {
    result = result.filter(
      (lot) =>
        filters.options.filter((o) => lot.options.includes(o)).length !== 0
    );
  }

  return result;
};

const Rechercher = () => {
  // Gestion de la page pour le moteur de recherche

  // Properties

  // params
  const {
    prix,
    surfaceMin,
    surfaceMax,
    types,
    opts,
    directions,
    floorMin,
    floorMax,
  } = useParams();
  const navigate = useNavigate();
  // Contexts
  const { setCurrent } = useContext(pageContext);
  const programmeName = useContext(programmeNameContext);
  const lang = useContext(languageContext);
  const programmeDatas = useContext(programmeContext);
  const lotDatas = useContext(programmeLotsContext); // liste initale des lots pour eviter un fetch
  const noHeader = useContext(noHeaderContext);
  const loc = useLocation();

  // States
  const [lots, setLots] = useState([]); // liste des lots
  const [typologies, setTypologies] = useState(null); // typologies disponibles pour ce programme

  const [orientationsChoices, setOrientationsChoices] = useState(null); // orientations disponibles pour ce programme
  const [optionsChoices, setOptionsChoices] = useState(null); // typologies disponibles pour ce programme
  const [blockChoices, setBlockChoices] = useState(null);

  const [blocks, setBlocks] = useState([]); // filtres par blocks

  const [minSurface, setMinSurface] = useState(surfaceMin ? surfaceMin : null); // filtre de surface minimum
  const [maxSurface, setMaxSurface] = useState(surfaceMax ? surfaceMax : null); // filtre de surface maximum

  const [minFloor, setMinFloor] = useState(
    floorMin
      ? floorMin
      : programmeDatas.maquette.visiblesFloors[0]?.replace(
          "Etage",
          programmeDatas.languagesDatas[lang].maquette.palette.etages
        )
  ); // filtre de surface minimum
  const [maxFloor, setMaxFloor] = useState(
    floorMax
      ? floorMax
      : programmeDatas.maquette.visiblesFloors[
          programmeDatas.maquette.visiblesFloors.length - 1
        ]?.replace(
          "Etage",
          programmeDatas.languagesDatas[lang].maquette.palette.etages
        )
  ); // filtre de surface maximum
  // const [floor, setFloor] = useState(floorSel ? floorSel : "Tous");

  const [maxPrix, setMaxPrix] = useState(prix ? prix : null); // filtre de prix maximum
  const [pieces, setPieces] = useState(
    types ? types.split(",").map((val) => parseInt(val)) : []
  ); // filtre par nombre de pieces
  const [options, setOptions] = useState(opts ? opts.split(",") : []); // filtre par options
  const [orientations, setOrientations] = useState(
    directions ? directions.split(",") : []
  ); // orientations disponibles pour ce programme
  const [displayPrices, setDisplayPrices] = useState(false); // utiliser les filtres de prix
  // const [actOnMin, setActOnMin] = useState(false);
  const [roomFilter, setRoomFilter] = useState(false); // Filtre de nombre de pieces
  const [optionsFilter, setOptionsFilter] = useState(false); // Filtre d'options
  const [surfaceFilter, setSurfaceFilter] = useState(false); // Filtre de surface
  const [orientationFilter, setOrientationFilter] = useState(false); // Filtre d'orientation
  const [prixFilter, setPrixFilter] = useState(false); // Filtre de prix maximum
  const [blockFilter, setBlockFilter] = useState(false); // Filtre de batiment
  const [floorFilter, setFloorFilter] = useState(false); // Filtre d'etage
  const [noResultsMessage, setNoResultsMessage] = useState(null);
  // Variables
  let useFavs = false; // Utiliser la fonction favoris
  let displayDetailLot = false; // Utiliser le detail du lot

  // Fonctions

  const needSmallNames = (names) => {
    const lotsNameCheck = names.filter((name) => name.length > 8);
    if (lotsNameCheck.length !== 0) {
      return true;
    }
    return false;
  };

  const updateLotsFromFilters = () => {
    // lancement de l'appel API pour la recherche des lots

    setLots([]);
    setNoResultsMessage(
      <p id="results-list-wait">recherche des résultats en cours ...</p>
    );
    const useSmallName = needSmallNames(Object.keys(lotDatas.lots));
    useFavs = programmeDatas.pages.includes("comparer");
    displayDetailLot = programmeDatas.pages.includes("detail-lot");
    const displayPrices = programmeDatas.displayPrices;

    const filter = {};
    if (maxPrix) {
      filter.prix = maxPrix;
    }
    if (minSurface) {
      filter.minSurface = minSurface;
    }
    if (maxSurface) {
      filter.maxSurface = maxSurface;
    }
    if (blocks.length !== 0) {
      filter.blocks = blocks;
    }
    if (pieces.length !== 0) {
      filter.types = pieces;
    }
    if (orientations.length !== 0) {
      filter.orientations = orientations.map((o) => {
        if (o === "N") return "Nord";
        else if (o === "S") return "Sud";
        else if (o === "O") return "Ouest";
        else if (o === "E") return "Est";
      });
    }
    filter.minFloor = programmeDatas.maquette.floors
      .map((x) =>
        x.replace(
          "Etage",
          programmeDatas.languagesDatas[lang].maquette.palette.etages
        )
      )
      .indexOf(minFloor);
    filter.maxFloor = programmeDatas.maquette.floors
      .map((x) =>
        x.replace(
          "Etage",
          programmeDatas.languagesDatas[lang].maquette.palette.etages
        )
      )
      .indexOf(maxFloor);
    if (options.length !== 0) {
      filter.options = options;
    }

    setLots(() => {
      const result = search(
        lotDatas.lots,
        filter,
        programmeDatas.maquette.floors
      );
      if (result.length > 0) {
        setNoResultsMessage(null);
        return result.map((lot) => (
          <Lot
            key={lot.name}
            name={lot.name}
            statut={lot.statut}
            surface={lot.surface}
            prix={displayPrices ? lot.prix : 0}
            type={lot.type}
            etage={lot.etage}
            display={displayDetailLot ? programmeDatas.displayIndispo : "None"}
            useFavs={useFavs}
            rooms={lot.numPieces}
            useSmallName={useSmallName}
          />
        ));
      } else {
        setNoResultsMessage(
          <p id="results-list-wait">Aucun lot ne correspont à la recherche.</p>
        );
        return [];
      }
    });

    // changement de l'url
    const minUrl = minSurface != null ? `/smin=${minSurface}` : "";
    const maxUrl = maxSurface != null ? `/smax=${maxSurface}` : "";
    const maxPxUrl = maxPrix != null ? `/pmax=${maxPrix}` : "";
    const typesUrl = pieces.length != 0 ? `/tp=${pieces.join(",")}` : "";
    const optionsUrl = options.length != 0 ? `/op=${options.join(",")}` : "";
    const dirUrl =
      orientations.length != 0 ? `/dir=${orientations.join(",")}` : "";

    navigate(
      `/${programmeDatas.urlName}/${
        noHeader
          ? `rechercher${minUrl}${maxUrl}${maxPxUrl}${typesUrl}${optionsUrl}${dirUrl}${loc.search}?noheader=true`
          : `rechercher${minUrl}${maxUrl}${maxPxUrl}${typesUrl}${optionsUrl}${dirUrl}${loc.search}`
      }`
    );
  };

  const updatePiecesFilter = (value) => {
    // mise a jour du hook de choix de pieces
    if (pieces.includes(value)) {
      setPieces(pieces.filter((val) => val != value));
    } else {
      setPieces((old) => [...old, value]);
    }
  };

  const wheelEvent = (e) => {
    console.log(e);
    e.target.scrollTop = 0;
  };

  const updateOptionsFilter = (option) => {
    // mise a jour du hook de choix des options
    if (options.includes(option)) {
      setOptions(options.filter((val) => val != option));
    } else {
      setOptions((old) => [...old, option]);
    }
  };

  const updateOrientationsFilter = (orientation) => {
    // mise a jour du hook de choix d'orientation
    if (orientations.includes(orientation)) {
      setOrientations(orientations.filter((val) => val != orientation));
    } else {
      setOrientations((old) => [...old, orientation]);
    }
  };

  const updateBlocksFilter = (block) => {
    // mise a jour du hook des block
    if (blocks.includes(block)) {
      setBlocks(blocks.filter((val) => val != block));
    } else {
      setBlocks((old) => {
        const newOne = [...old, block];
        if (newOne.length === programmeDatas.maquette.blocks.length) {
          return [];
        } else {
          return newOne;
        }
      });
    }
  };

  const launchSearchFromUser = () => {
    // mise a jour de la recherche au clic sur le bouton
    updateLotsFromFilters();
  };

  // Effects
  useEffect(() => {
    // Initialisation des informations du moteur de recherche et lancement d'une premiere recherhe sans filtres

    if (programmeDatas && lotDatas) {
      // maj du menu
      setCurrent("rechercher");

      // recuperation des affichages des ui de filtres puis recherche initiale
      setRoomFilter(programmeDatas.pagesDetails.rechercher.roomFilter);
      setOptionsFilter(programmeDatas.pagesDetails.rechercher.optionsFilter);
      setSurfaceFilter(programmeDatas.pagesDetails.rechercher.surfaceFilter);
      setOrientationFilter(
        programmeDatas.pagesDetails.rechercher.orientationFilter
      );
      setPrixFilter(programmeDatas.pagesDetails.rechercher.prixFilter);
      setBlockFilter(programmeDatas.pagesDetails.rechercher.blockFilter);
      setFloorFilter(programmeDatas.pagesDetails.rechercher.floorFilter);

      const useSmallName = needSmallNames(lotDatas.lots);
      const useFavs = programmeDatas.pages.includes("comparer");
      const displayDetailLot = programmeDatas.pages.includes("detail-lot");
      const display = programmeDatas.displayIndispo;
      const displayPrices = programmeDatas.displayPrices;
      setDisplayPrices(programmeDatas.displayPrices);

      if (
        prix ||
        surfaceMin ||
        surfaceMax ||
        types ||
        opts ||
        directions ||
        floorMax ||
        floorMin
      ) {
        updateLotsFromFilters();
      } else {
        setLots(
          lotDatas.lots.map((lot) => {
            return (
              <Lot
                key={lot.name}
                name={lot.name}
                statut={lot.statut}
                surface={lot.surface}
                prix={displayPrices ? lot.prix : 0}
                type={lot.type}
                etage={lot.etage}
                display={displayDetailLot ? display : "None"}
                useFavs={useFavs}
                rooms={lot.numPieces}
                useSmallName={useSmallName}
              />
            );
          })
        );
      }
    }
  }, [lotDatas, programmeDatas]);

  useEffect(() => {
    // mise a jour des filtres de recherche

    if (programmeDatas !== null) {
      // mise en place des choix de typologie
      setTypologies(
        programmeDatas.lots.global.typologies.map((type) => {
          return (
            <div
              key={type}
              className={`pieces-input btnToggle ${
                pieces.includes(type) ? "filterActive" : ""
              }`}
              onClick={() => {
                updatePiecesFilter(type);
              }}
            >
              {parseInt(lang === "fr" ? type : type - 1)}
            </div>
          );
        })
      );
      // mise en place des choix d'options
      setOptionsChoices(
        programmeDatas.pagesDetails.rechercher.optionList.map((option) => (
          <div
            key={option.value}
            className={`options-input btnToggle ${
              options.includes(option.value) ? "filterActive" : ""
            }`}
            onClick={() => {
              updateOptionsFilter(option.value);
            }}
          >
            <SvgIcon icon={option.icon} />
            <p>
              {programmeDatas.languagesDatas[lang].lots.options[option.value]
                ? programmeDatas.languagesDatas[lang].lots.options[option.value]
                : option.value}
            </p>
          </div>
        ))
      );

      // mise en place des choix d'orientations
      setOrientationsChoices(
        programmeDatas.lots.global.orientations.map((orientation) => {
          return (
            <div
              key={orientation}
              className={`pieces-input btnToggle ${
                orientations.includes(orientation) ? "filterActive" : ""
              }`}
              onClick={() => {
                updateOrientationsFilter(orientation);
              }}
            >
              {orientation}
            </div>
          );
        })
      );

      console.log(programmeDatas.maquette.blocks);
      // mise en place des choix de batiments
      setBlockChoices(
        programmeDatas.maquette.blocks.map((block) => {
          return (
            <div
              key={block}
              className={`block-input btnToggle ${
                blocks.includes(block) ? "filterActive" : ""
              }`}
              onClick={() => {
                updateBlocksFilter(block);
              }}
            >
              {block}
            </div>
          );
        })
      );
    }
  }, [programmeDatas, pieces, options, blocks]);

  // Rendu
  return (
    <programmeContext.Consumer>
      {(datas) =>
        datas !== null && (
          <div id="rechercher">
            <div id="rechercher-form">
              <div id="rechercher-mobile-scroller">
                <div id="form-inputs">
                  {blockFilter && datas.maquette.blocks.length > 1 && (
                    <>
                      <label>
                        {
                          datas.languagesDatas[lang].rechercher.filters
                            .batiments
                        }
                      </label>
                      <div id="block-form">{blockChoices}</div>
                    </>
                  )}

                  {floorFilter && (
                    <>
                      <label>
                        {datas.languagesDatas[lang].rechercher.filters.etages}
                      </label>
                      <div id="etages-form">
                        <CustomSelect
                          options={datas.maquette.visiblesFloors.map((x) =>
                            x.replace(
                              "Etage",
                              datas.languagesDatas[lang].maquette.palette.etages
                            )
                          )}
                          preselected={minFloor}
                          setCurrent={setMinFloor}
                        />
                        <CustomSelect
                          options={datas.maquette.visiblesFloors.map((x) =>
                            x.replace(
                              "Etage",
                              datas.languagesDatas[lang].maquette.palette.etages
                            )
                          )}
                          preselected={maxFloor}
                          setCurrent={setMaxFloor}
                        />
                      </div>
                    </>
                  )}

                  {roomFilter && (
                    <>
                      <label>
                        {datas.languagesDatas[lang].rechercher.filters.pieces}
                      </label>
                      <div id="pieces-form">{typologies}</div>
                    </>
                  )}

                  {optionsFilter == true &&
                    optionsChoices != null &&
                    optionsChoices.length != 0 && (
                      <>
                        <label>
                          {
                            datas.languagesDatas[lang].rechercher.filters
                              .options
                          }
                        </label>
                        <div id="options-form">{optionsChoices}</div>
                      </>
                    )}

                  {surfaceFilter && (
                    <>
                      <label>
                        {datas.languagesDatas[lang].rechercher.filters.surface}
                      </label>
                      <div id="surface-form">
                        <InputNumber
                          value={minSurface}
                          setValue={setMinSurface}
                          min={0}
                          max={maxSurface != null ? maxSurface : 800}
                          placeholder="min."
                          color={datas.color}
                        />
                        <InputNumber
                          value={maxSurface}
                          setValue={setMaxSurface}
                          min={minSurface != null ? minSurface : 0}
                          max={500}
                          placeholder="max."
                          color={datas.color}
                        />
                      </div>
                    </>
                  )}

                  {orientationFilter && (
                    <>
                      <label>Orientation</label>
                      <div id="orientation-form">
                        <div
                          className={`pieces-input btnToggle ${
                            orientations.includes("N") ? "filterActive" : ""
                          }`}
                          onClick={() => {
                            updateOrientationsFilter("N");
                          }}
                        >
                          N
                        </div>
                        <div
                          className={`pieces-input btnToggle ${
                            orientations.includes("O") ? "filterActive" : ""
                          }`}
                          onClick={() => {
                            updateOrientationsFilter("O");
                          }}
                        >
                          O
                        </div>
                        <div
                          className={`pieces-input btnToggle ${
                            orientations.includes("S") ? "filterActive" : ""
                          }`}
                          onClick={() => {
                            updateOrientationsFilter("S");
                          }}
                        >
                          S
                        </div>
                        <div
                          className={`pieces-input btnToggle ${
                            orientations.includes("E") ? "filterActive" : ""
                          }`}
                          onClick={() => {
                            updateOrientationsFilter("E");
                          }}
                        >
                          E
                        </div>
                      </div>
                    </>
                  )}

                  {prixFilter && displayPrices && (
                    <>
                      <label>Prix (en €)</label>
                      <div id="surface-form">
                        <InputNumber
                          value={maxPrix}
                          setValue={setMaxPrix}
                          min={0}
                          max={10000000}
                          placeholder="max."
                          color={datas.color}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div
                  id="rechercher-post"
                  className="mainBackground"
                  onClick={launchSearchFromUser}
                >
                  <p id="post-text-info">
                    {datas.languagesDatas[lang].rechercher.filters.input[0]}
                  </p>
                  <p id="post-text-detail">{`(${lots.length} ${datas.languagesDatas[lang].rechercher.filters.input[1]})`}</p>
                </div>
              </div>
            </div>

            <div id="rechercher-results">
              <div id="results-title">
                <h3>
                  {datas.languagesDatas[lang].rechercher.resultats.resultats}
                </h3>
                <p>{`(${lots.length})`}</p>
              </div>

              <div id="results-list">
                {lots.length > 0 ? lots : noResultsMessage}
              </div>
            </div>
          </div>
        )
      }
    </programmeContext.Consumer>
  );
};

// ////////////////////////////////////////////////////////////////////////
// Export
export default Rechercher;
