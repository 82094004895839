import React from "react";

const Annotation = ({text, leftoffset, top = false}) => {

    return (
        <div id = {top ? 'annotation-top' : 'annotation'} style={{
            left: `calc(50% + ${leftoffset}px)`,
        }}>
            <p id = 'annotationText'>
                {text}
            </p>
        </div>
    )
}

export default Annotation;
