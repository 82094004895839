// ///////////////////////////////////////////////////////////////////////////////
// Component pour la gestion du moteur de recherche de la page programme
// ///////////////////////////////////////////////////////////////////////////////
// Creation 17/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////

// ///////////////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import Button from "../../../components/button/button";
import {
  languageContext,
  maquetteContext,
  miniMoteurContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
} from "../../../contexts";
import SearchResult from "./searchResult/searchResult";

// ///////////////////////////////////
// Components

const SearchNavigations = ({
  manyBlocks, // Plusieurs sous maquettes pour le projet
  block, // batiement actif
  setBlock, // modifier le batiment actif
  floor, // etage actif
  setFloor, // modifier l'etage actifs
  setForcedLotHover, // activer le hover d'un lot
}) => {
  // Propriete
  const [lots, setLots] = useState([]);
  const [filterChoices, setFilterChoices] = useState(null); // liste des filtres
  const [optionsChoices, setOptionsChoices] = useState(null); // liste des options du programme
  const [typesChoices, setTypeChoices] = useState([]);
  const [displayBlock, setDisplayBlock] = useState(true); // afficher le nom du block dans la grille de resultats
  const [useResponsive, setUseResponsive] = useState(false); // detection de l'interface en fonction de la& taille de la fenetre
  const [displayState, setDisplayState] = useState(false); // gestion du toggle recherche, resultat en mode responsiv
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const maquetteDatas = useContext(maquetteContext);
  const lotsDatas = useContext(programmeLotsContext);
  const { surface, options, pieces, filter, label, setLabel } =
    useContext(miniMoteurContext);
  const lang = useContext(languageContext);

  // Fonctions
  const handleValidForm = () => {
    // event a la validation de la recherche
    updateLotsFromFilters();
    setDisplayState(true);
  };

  const search = (source, filters = {}, floors = []) => {
    let result = source;

    // filtre de surface min
    if (filters.minSurface) {
      result = result.filter(
        (lot) => lot.surface === 0 || lot.surface >= filters.minSurface
      );
    }
    // filtre de batiment
    if (filters.block) {
      result = result.filter(
        (lot) => lot.batiment === null || filters.block.includes(lot.batiment)
      );
    }
    // filtre de type
    if (filters.types) {
      result = result.filter(
        (lot) => lot.numPieces === 0 || filters.types.includes(lot.numPieces)
      );
    }
    // filtre d'etages
    if (filters.floor) {
      result = result.filter(
        (lot) => lot.etage === null || lot.etage === filters.floor
      );
    }
    // filtre d'options
    if (filters.options) {
      result = result.filter((lot) =>
        [filters.options.value].some((o) => lot.options.includes(o))
      );
    }

    return result;
  };

  // Fonctions
  const updateLotsFromFilters = () => {
    // lancement de l'appel API pour la recherche des lots

    let useSmallName = false;
    const lotsNameCheck = lotsDatas.lots.filter((lot) => lot.name.length > 5);
    if (lotsNameCheck.length !== 0) {
      useSmallName = true;
    }

    const filters = {};
    if (surface) {
      filters.minSurface = surface;
    }
    if (
      (filter === "Bâtiment" || filter === "Étage") &&
      !["Complexe", "Générale", "Global"].includes(block)
    ) {
      filters.block = block;
    }
    if (filter === "Étage" && !["Aerien", "Pieton"].includes(floor)) {
      filters.floor = floor;
    }
    if (pieces.length !== 0) {
      filters.types = pieces.map((x) => x.value);
    }
    if (options && options.length !== 0) {
      filters.options = options;
    }

    setLots(() => {
      const result = search(
        lotsDatas.lots,
        filters,
        programmeDatas.maquette.floors
      );
      if (result.length > 0) {
        return result.map((lot) => (
          <SearchResult
            key={lot.name}
            name={lot.name}
            type={lot.type}
            etage={lot.etage}
            surface={lot.surface}
            statut={lot.statut}
            block={displayBlock === true ? lot.batiment : undefined}
            setBlock={() => {
              setBlock(lot.batiment);
            }}
            setFloor={() => {
              setFloor(lot.etage);
            }}
            setForcedLotHover={setForcedLotHover}
            rooms={lot.numPieces - 1}
            useSmallName={useSmallName}
          />
        ));
      } else {
        return [];
      }
    });
  };

  // Effects

  useEffect(() => {
    // Gestion de l'interface de filtres
    if (manyBlocks) {
      setFilterChoices([
        {
          value: "Résidence",
          label: programmeDatas.languagesDatas[lang].maquette.search.residence,
        },
        {
          value: "Bâtiment",
          label: programmeDatas.languagesDatas[lang].maquette.search.batiment,
        },
        {
          value: "Étage",
          label: programmeDatas.languagesDatas[lang].maquette.search.etage,
        },
      ]);
    } else {
      setFilterChoices([
        {
          value: "Résidence",
          label: programmeDatas.languagesDatas[lang].maquette.search.batiment,
        },
        {
          value: "Étage",
          label: programmeDatas.languagesDatas[lang].maquette.search.etage,
        },
      ]);
    }
  }, [manyBlocks]);

  useEffect(() => {
    // Lancement d'une recherche initiale, sans filtre au chargement du composant

    if (programmeName && maquetteDatas && lotsDatas) {
      // Gestion du responsive
      const detectRatio = () => {
        if (document.documentElement.clientHeight < 500) {
          setUseResponsive(true);
        } else {
          setUseResponsive(false);
        }
      };

      detectRatio();
      window.addEventListener("resize", detectRatio);

      // Mise en place de la liste des options
      setDisplayBlock(
        Object.keys(maquetteDatas.blocks).length > 1 ||
          (Object.keys(maquetteDatas.blocks).length == 1 &&
            maquetteDatas.complexe != undefined)
      );

      setOptionsChoices([
        { value: "Toutes", label: "Toutes" },
        ...Object.values(lotsDatas.infos.options).map((option) => {
          return { value: option, label: option };
        }),
      ]);
      setTypeChoices(
        lotsDatas.infos.typologies.map((type) => {
          return {
            value: type,
            label: lang === "fr" || lang === "tertiaire" ? type : type - 1,
          };
        })
      );

      return () => {
        window.removeEventListener("resize", detectRatio);
      };
    }
  }, [programmeName, maquetteDatas, lotsDatas]);

  useEffect(() => {
    if (lotsDatas) {
      updateLotsFromFilters();
    }
  }, [lotsDatas, displayBlock]);

  useEffect(() => {
    if (label === "") {
      setLabel(programmeDatas.languagesDatas[lang].maquette.search.residence);
    }
  }, [label]);

  useEffect(() => {
    // Verification si la recherche contient au moins un lot
    if (Array.isArray(lots) && lots.length == 0) {
      setLots(<p id="no-lots">Aucun lot disponible pour ces critères.</p>);
    }
  }, [lots]);

  // Render
  return (
    <miniMoteurContext.Consumer>
      {(values) =>
        values && (
          <div id="programmeSearch">
            {(!useResponsive || !displayState) && (
              <form id="search-zone">
                <div id="search-form">
                  {/* Filtre de recherche */}
                  <label>
                    {
                      programmeDatas.languagesDatas[lang].maquette.search
                        .filtres
                    }
                    <Select
                      classNamePrefix="typeSearch"
                      value={{ value: values.filter, label: values.label }}
                      onChange={(e) => {
                        values.setFilter(e.value);
                        values.setLabel(e.label);
                      }}
                      options={filterChoices}
                      blurInputOnSelect={true}
                      isSearchable={false}
                    />
                  </label>

                  {/* Filtre de pieces */}
                  <label>
                    {programmeDatas.languagesDatas[lang].maquette.search.pieces}
                    <Select
                      classNamePrefix="typeSearch"
                      isMulti={true}
                      value={values.pieces}
                      onChange={(e) => {
                        values.setPieces(e);
                      }}
                      options={typesChoices}
                      placeholder={
                        programmeDatas.languagesDatas[lang].maquette.search
                          .piecesPlaceholder
                      }
                      blurInputOnSelect={true}
                      isSearchable={false}
                    />
                  </label>

                  {optionsChoices && optionsChoices.length !== 1 && (
                    <label>
                      {
                        programmeDatas.languagesDatas[lang].maquette.search
                          .option
                      }
                      <Select
                        classNamePrefix="typeSearch"
                        value={values.options}
                        onChange={(e) => {
                          e.value == "Toutes"
                            ? values.setOptions(null)
                            : values.setOptions(e);
                        }}
                        options={optionsChoices}
                        placeholder={
                          programmeDatas.languagesDatas[lang].maquette.search
                            .optionsPlaceholder
                        }
                        blurInputOnSelect={true}
                        isSearchable={false}
                      />
                    </label>
                  )}

                  {/* Filtres de surfaces */}
                  <label>
                    {
                      programmeDatas.languagesDatas[lang].maquette.search
                        .surface
                    }
                    <input
                      type="text"
                      placeholder="min."
                      value={values.surface}
                      onChange={(e) => {
                        values.setSurface(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  className="valid-search mainBackground"
                  onClick={handleValidForm}
                >
                  {programmeDatas.languagesDatas[lang].maquette.search.input}
                </div>
              </form>
            )}

            {useResponsive && displayState && (
              <div id="search-switcher">
                <Button
                  text={"Paramètres"}
                  icon={"settings"}
                  action={() => {
                    setDisplayState(false);
                  }}
                ></Button>
              </div>
            )}

            {(!useResponsive || displayState) && (
              <div id="result-zone">{lots}</div>
            )}
          </div>
        )
      }
    </miniMoteurContext.Consumer>
  );
};

// ///////////////////////////////////
// Exports

export default SearchNavigations;
