// ///////////////////////////////////////////////////////
// Fichier pour la gestion de la partie haute du menu
// ///////////////////////////////////////////////////////
// Creation : 30/03/2022    -> CGR
// ///////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports system
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Import context
import { programmeContext, pageContext, programmeNameContext, noHeaderContext } from './../../../contexts';

//Imports components
import TopLevel1 from './mainTopNavigationsLevels/topLevel1';
import TopLevel2 from './mainTopNavigationsLevels/topLevel2';



// /////////////////////////////////////////////////////////////
// Component

// const TopNavigationLvl0 = () => {
//     return (<></>)
// }


const MainTopNavigation = () => {

    // init
    const noHeader = useContext(noHeaderContext);
    const contextDatas = useContext(programmeContext);
    const programmeName = useContext(programmeNameContext);
    const loc = useLocation();
    // const {lot} = useParams(); // nom du lot en cours -> issue de param url

    // properties
    const [needHome, setNeedHome] = useState(false); // Le programme utilise-t-il une page d'accueil
    const [logo, setLogo] = useState(null); // logo du programme


    // effects
    useEffect(() => {

        if (programmeName !== null && contextDatas !== null) {
            if (!noHeader) {
                setNeedHome(contextDatas.pages.includes("home"));
            }
            if (contextDatas.displayProgrammeLogo) {
                setLogo(contextDatas.logo != "" ?
                    <img id='logoProjet' src={`/public/programmes/${programmeName}/logos/${contextDatas.logo}`} /> :
                    <p id='nomProjet'>{contextDatas.realName}</p>);
            }
            else {
                setLogo(null);
            }
        }

    }, [programmeName, contextDatas])

    // rendu
    return (
        <pageContext.Consumer>
            {page => page != null &&
                <div id='mainTop'>
                    {logo}
                    {(page.headerLevel === 1 & needHome) ?
                        <TopLevel1 />
                        : null
                    }
                    {page.headerLevel === 2 &&
                        <TopLevel2 />
                    }
                </div>
            }</pageContext.Consumer>
    )
}

export default MainTopNavigation;
