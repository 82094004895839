// ///////////////////////////////////////////////////////////////////////////////
// Hooks pour la gestion du prechargement de la maquette 3D
// ///////////////////////////////////////////////////////////////////////////////
// Creation 17/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////



// Imports system
import React, {useEffect, useState } from 'react';
import { domain } from '../../../contexts';


const usePreloader = (programme) => {
    
    const loaded = {"lots" : {}, "maquette" : {}}; // tableau des images

    const resols = [156, 450, 720]
    const [datas, setDatas] = useState(null);
    const [frame, setFrame] = useState(0);



    const loadAllFramesImg = (path, index, resol, last) => {
    }

    const loadHdImg = (path, index) => {

        const strIndex = index.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});

        const img = new Image();
        img.src = `${path}/${strIndex}.jpg`;
    }

    const loadHdForOneFrame = () => { // Fonction pour charger l'ensemble des hd pour une frame donnee
        const toLoad = []
        const blocks = Object.keys(datas.blocks);

        // preparation de la liste de chargement
        for (let block of blocks) {
            if (datas.blocks[block].views["Aerien"] != undefined) {
                toLoad.push([block, 'Aerien', datas.blocks[block].views["Aerien"].frames])
            }
            if (datas.blocks[block].views["Pieton"] != undefined) {
                toLoad.push([block, 'Pieton', datas.blocks[block].views["Pieton"].frames])
            }
            for (let floor of datas.blocks[block].floors) {
                toLoad.push([block, floor.name, datas.blocks[block].views["Decoupe"].frames])
            }
        }

        for (let load of toLoad) {
            loadHdImg(`/public/programmes/${programme}/maquette/${load[0]}/${load[1]}/jpg-2160`, frame);                
        }
    }

    const loadLot = (path, name) => { // Fonction pour le chargement des images d'un pdv
            
        loaded.lots[name] = {} // ajout du lot au dictionnaire des chargements
        // resols = [156, 450, 720, 900]; // liste des resolutions

        for (let resol of resols) {   
            
            loaded.lots[name][resol] = [] // ajout de la resolution au dictionnaire des chargements
            for (let i=0 ; i<30 ; i++) {
                const img = new Image();
                const strIndex = i.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false});
                img.src = `${path}Etage0/jpg-${resol}/${strIndex}.jpg`;
                
                img.addEventListener('load', () => {
                    loaded.lots[name][resol].push(img); // ajout de l'image chargee a la resol
                })
            }
        }
    }

    useEffect(() => {
        if (programme) {
            fetch(`${domain}/${programme}/maquette`, {mode: 'cors'})
            .then(response => response.json())
            .then(data => {

                setDatas(data);

            }).catch((err) => {
                console.log("Erreur de fetch : ", err);
            }) 
        }
    }, [programme])

    useEffect(() => {

        if (programme && datas !== null) {

            const toLoad = []
            const blocks = Object.keys(datas.blocks);

            // preparation de la liste de chargement
            for (let block of blocks) {
                if (datas.blocks[block].views["Aerien"] != undefined) {
                    toLoad.push([block, 'Aerien', datas.blocks[block].views["Aerien"].frames])
                }
                if (datas.blocks[block].views["Pieton"] != undefined) {
                    toLoad.push([block, 'Pieton', datas.blocks[block].views["Pieton"].frames])
                }
                for (let floor of datas.blocks[block].floors) {
                    toLoad.push([block, floor.name, datas.blocks[block].views["Decoupe"].frames])
                }
            }

            for (let load of toLoad) {
                const isLast = load == toLoad[toLoad.length-1];
                loadAllFramesImg(`/public/programmes/${programme}/maquette/${load[0]}/${load[1]}/jpg-`, 0, 156, isLast);                
            }
        }
    }, [programme, datas])

    useEffect(() => {
        if (datas != null) {
            loadHdForOneFrame();
        }
    }, [datas, frame])

    return [setFrame, loadLot];
}

export default usePreloader
