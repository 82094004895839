// ////////////////////////////////////////////////////////////
// Fichier pour la gestion des decorations d'une VIM
// ////////////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ////////////////////////////////////////////////////////////


// //////////////////////////
// Imports

// Imports system
import React, { useState } from 'react';
// Imports components;
import ButtonLong from '../../components/button/buttonLong';


// //////////////////////////
// Components

const Decorations = ({decorations, current, setCurrent,  navigation}) => { // Toggle pour le changement de decouration

    // Fonctions
    const navigate = (where) => {
        setCurrent(where);
    }

    return (
        <div className = {`vim-deco ${navigation}`}>
            {decorations &&
                decorations.map((decoration) => {
                        return (
                            <ButtonLong 
                            key = {decoration} 
                            icon= {`pieces_${decoration.replaceAll(" ", "-").replaceAll("é", "e")}`} 
                            text = {decoration} 
                            state = {current === decoration ? decoration : ""} 
                            action = {() => {
                                navigate(decoration);
                            }} 
                        />
                    )
                })
            }
        </div>
    )
}


// //////////////////////////
// Export
export default Decorations;