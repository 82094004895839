// ///////////////////////////////////////////////////////
// Composant pour la gestion de la page galerie
// ///////////////////////////////////////////////////////
// Creation : 30/04/2022    -> CGR
// Maj : 08/08/2022 -> remplacement du fetch par context
// ///////////////////////////////////////////////////////


// Imports system
import React, {useState, useEffect, useContext, useRef} from 'react';

// Import context
import { noHeaderContext, pageContext, pagesContext } from './../../contexts';

// Imports components
import useCarrousel from './hooks/useCarrousel';
import GalerieScreen from './galerieScreen';
import Carrousel from './carrousel/carrousel';
import Annotation from '../../components/annotation/annotation';


// ////////////////////////////////////////////////////////////////////////
// Component
const Galerie = () => { // Classe principale de la galerie

    // Proprietes
    const [isCollapsed, setIsCollapsed] = useState(false); // Etat d'affichage du carrousel
    const [inactivTime, setInactivTime] = useState(0);
    const [autoChangeDelay, setAutoChangeDelay] = useState(5000);
    const {setCurrent, setHeaderLevel } = useContext(pageContext);
    const pageDatas = useContext(pagesContext);
    const noHeader = useContext(noHeaderContext)
    const olreadyChecked = useRef(false);
    const [index, setIndex] = useCarrousel(0, pageDatas.galerie.allMedias.length); // Image active
    const [annotation, setAnnotation] = useState(null); // Annotation de l'image active


    useEffect(() => {
        if (pageDatas) {

            if (olreadyChecked.current) {return;}
            olreadyChecked.current = true;

            setHeaderLevel(1);
            setCurrent("galerie");
            setTimeout(() => { // Pour les chargement un peu lent, test un peu plus tardif
                setCurrent("galerie");
            }, (200));

            setAutoChangeDelay(pageDatas.galerie.autoChangeDelay)
            if (pageDatas.galerie.useAutoChange) {
                setInterval(() => {
                    setInactivTime(i => i + 1);
                }, 1000)
            }
            setAnnotation(pageDatas.galerie.annotationText)

        }

    }, [pageDatas])


    useEffect(() => {
        if (pageDatas.galerie.allMedias[index].type !== "video") {
            if(inactivTime >= autoChangeDelay) {
                if (index == pageDatas.galerie.allMedias.length - 1) {
                    setIndex(0);
                } else {
                    setIndex(index + 1);
                }
            }
        }        
    }, [inactivTime, autoChangeDelay])

    useEffect(() => {
        setInactivTime(0);
    }, [index])


    // Rendu
    return (
        <div id = 'galerie' className={`${noHeader ? "no-header" : ""}`}>
            {(pageDatas && pageDatas.galerie) &&
                <>
                    <GalerieScreen src = {pageDatas.galerie.allMedias[index].type === "video" ? pageDatas.galerie.allMedias[index].videoSrc : pageDatas.galerie.allMedias[index].fullName} isCollapsed = {isCollapsed} index = {index} setIndex = {setIndex} type = {pageDatas.galerie.allMedias[index].type}/>
                    <Carrousel medias = {pageDatas.galerie.allMedias} index = {index} setIndex = {setIndex} isCollapsed = {isCollapsed} setIsCollapsed = {setIsCollapsed}/>
                    {annotation && <Annotation text={annotation} leftoffset={50} top={true}></Annotation>}
                </>
            }
        </div>
    )
}


export default Galerie;
