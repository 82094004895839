// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons standards de la V6
// ////////////////////////////////////////////////////////////////////////////////
// Creation :   21/09/2022 FTR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React from 'react';

// Import context
import { domain, programmeNameContext } from '../../../../contexts';

// Bouton
const POIProjet = ({image, color, text}) => {

    // rendu
    return (
        <programmeNameContext.Consumer>
            {programmeName => programmeName && 
                <div className='poi poiProjet'>
                    <div className='pin' style={{backgroundColor : color}}>
                        {image ? <img src={`/programme/${programmeName}/localisation/carte/${image}`}/> : <div className='pinCenter'></div>}
                        {/*<img src="http://moteur-v6-backend/static/clients/Ogic/logo/ogic.jpg"/>*/}
                    </div>
                    <div className="title">{text}</div>
                    <div className='shadow pulse'></div>
                </div>
            }
        </programmeNameContext.Consumer>
    )
}

export default POIProjet;
