// /////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des elements 3D en surimpression des images pre-calcules
// /////////////////////////////////////////////////////////////////////////////////
// Creation : 21/04/2022  CGR
// /////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, {useState, useEffect, useContext} from 'react';

// Three
import * as THREE from 'three'
import {useThree} from "@react-three/fiber"

// Components
import PlayerToolTip from './playerToolTip/playerToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'



// ////////////////////////////////////////////////////////////////////////
// Moteur 3D
const ThreeJsPlayer = ({
    programme,
    frame, 
    block, 
    floor, 
    focale, 
    setSurvol, 
    puces,
    setPuces, 
    setBlock = undefined, 
    filterOne = undefined, 
    lots = undefined,
    alreadyOveredLot = undefined, 
    setAlreadyOveredLot = undefined,
    zoom = 1,
    setExtActiv = () => {},
    maquetteDatas = null,
    jsonDatas = null
}) => {

    // Three
    const { scene, camera } = useThree();
    const [zones, setZones] = useState([]); // Elements jsx pour affichage des zones
    const [hoveredZone, setHoveredZone] = useState(null); // zone en cours de survol
    const [hoveredZoneMemory, setHoveredZoneMemory] = useState(null); // tampon pour detection des changement de survol
    const [oldHoveredZone, setOldHoveredZone] = useState(null); // ancienne zone survolée pour permettre le retrait de l'opacité
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [preOvered, setPreOvered] = useState(alreadyOveredLot);
    const [cutEvent, setCutEvent] = useState(false); // Etat des ecoutes d'evenements -> true permet de desactiver le suivi souris
    const [pucesAreSettings, setPucesAreSettings] = useState(false);
    // const [maquetteDatas, setMaquetteDatas] = useState(null);
    // const maquetteDatas = useContext(maquetteContext);
    // const jsonDatas = useContext(jsonContext);


    // Fonctions
    const clearScene = () => { // Fonction de nettoyage de la scene 3D -> retrait puces, zones
        // Del des zones
        let obj;
        for (let i = scene.children.length - 1; i >= 0; i--) {
            obj = scene.children[i];
            if (obj !== camera) {
                scene.remove(obj);
            }
        }
    }

    const updateCamera = () => { // Mise a jour de la camera au changement de frame
    
        if (jsonDatas) {

            const frameCamera = jsonDatas[block][floor].frames[frame];

            // maj cam
            if (frameCamera != null) {

                camera.setFocalLength(focale);
                camera.position.set(frameCamera[0], frameCamera[1], frameCamera[2]);
                camera.rotation.set(
                    (THREE.Math.degToRad(90-frameCamera[3])), 
                    (THREE.Math.degToRad(frameCamera[4])), 
                    (THREE.Math.degToRad(-frameCamera[5]))
                ) 
                camera.updateMatrixWorld();
            }  
        }
    }

    const buildZones = () => { // Fonction pour la construction des zones 3D

        if (block != undefined && jsonDatas && lots) {
            setZones([]); // reset des zones 3D
            clearScene();

            if (Object.keys(jsonDatas[block]).includes(floor)) {
                // Partie zones
                const viewZones = jsonDatas[block][floor].zones;
                if (viewZones != undefined) {

                    Object.keys(viewZones).map((lot, i) => {

                        // Si on filtre un lot alors on zappe tous les autres
                        if (filterOne != undefined && lot != filterOne) {return;}

                        let parent = new THREE.Object3D(); // nouvel objet 3D

                        for(let poly of viewZones[lot]){ // Parcours des traces de la zone
                            let zonePts=[];
                            for (let point of poly){ // Parcours des points de chaque trace
                                zonePts.push( new THREE.Vector2 (parseFloat(point[0]), parseFloat(point[1]))) ;
                            }
                            let zoneShape = new THREE.Shape(zonePts);
                            let geometry = new THREE.ExtrudeGeometry(zoneShape, {depth: 0,  bevelEnabled: false})

                            let theZone = null;
                            if (lots.lots) {
                                theZone = lots.lots.find(x => x.name === lot);
                            }                            
                            
                            if (theZone) {
                                if (maquetteDatas.global.zonesDisplay === "Masqués" && theZone.statut !== "Disponible") {return;}
                                const color = theZone.statut == "Disponible" ? new THREE.Color(lots.infos.colors[theZone.type]).convertSRGBToLinear() : '#3a3a3a'
                                let object = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: color, opacity: filterOne != undefined ? 1.0 : 0.8 }));
                                object.position.z = parseFloat(poly[0][2]);
                                parent.add(object); // ajout du trace a l'objet
                                setZones(old => [...old, object]); // ajout en memoire des zones
                            } else {
                                const color = 'black';
                                let object = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: color, opacity: filterOne != undefined ? 1.0 : 0.8 }));
                                object.position.z = parseFloat(poly[0][2]);
                                parent.add(object); // ajout du trace a l'objet
                            }
                        }
                        parent.zoneId = lot;
                        scene.add(parent); // ajout du trace dans la 3D
                    })

                } else {
                    setZones([]);
                }
            }
        }
    }

    const buildPuces = () => { // Mise en place des position de puce au changement de frame

        // Gestion du nouveau comportement des puces
        setPuces([]);
        if (maquetteDatas) {

            let vw = maquetteDatas.blocks[block]
            if (!vw) {vw = maquetteDatas.complexe}

            const view = vw.views[floor]
            if (view) {
                const viewPuces = view['puces']

                if (viewPuces) {
                    Object.keys(viewPuces).map((puce) => {
                        const pos = new THREE.Vector3();
                        pos.x = viewPuces[puce].x;
                        pos.y = viewPuces[puce].y;
                        pos.z = viewPuces[puce].z; 

                        pos.project(camera);

                        const pagePosition = convertWorldToHtmlRelativeCoordinates(pos.x, pos.y);
                        if (viewPuces[puce].type === "sous-maquette") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces" 
                                onClick = {() => {
                                    setBlock(viewPuces[puce].link)  
                                }}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            >{puce}</div>]); // ajout en memoire des zones
                        } else if (viewPuces[puce].type === "vim-ext") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces vim" 
                                onClick= {() => setExtActiv(viewPuces[puce].link)}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            ><FontAwesomeIcon icon={faEye}/><p>{puce}</p></div>]); // ajout en memoire des zones
                        } else if (viewPuces[puce].type === "info") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces info" 
                                onClick= {() => {}}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            >{puce}</div>]); // ajout en memoire des zones
                        }
                    })   
                }
            }
        }
    }

    const setToolTipPosition = (zone) => { // Fonction pour placer le toolTip de lot a la position de la zone
        const middle = new THREE.Vector3();
    
        const geo = zone.children[0].geometry;
        geo.computeBoundingBox();
        camera.updateMatrixWorld();

        middle.x = (geo.boundingBox.max.x + geo.boundingBox.min.x) / 2;
        middle.y = (geo.boundingBox.max.y + geo.boundingBox.min.y) / 2;
        middle.z = (geo.boundingBox.max.z + geo.boundingBox.min.z) / 2;
    
        zone.children[0].localToWorld(middle);
        if (middle.z == 0) {
            setTimeout(() => {
                setToolTipPosition(zone);
            }, 10)
            return;
        }
    
        middle.project(camera);
        const position2D = convertWorldToHtmlRelativeCoordinates(middle.x, middle.y);
    
        // Maj contenu React 
        setSurvol(<PlayerToolTip programme = {programme} name = {zone.zoneId} type = "T2" surface = {42.12} x = {position2D.x} y = {position2D.y}/>);    
    };

    const setZoneOpacity = (zone, val) => { // Fonction pour la modification de l'opacite d'une zone 3D
        for (let i in zone.children){
            zone.children[i].material.opacity=val;
        }
    };

    const convertWorldToHtmlRelativeCoordinates = (vx, vy) => { // Fonction pour obtenir la position 2D depuis une coordonée 3D de la scene
        // Calcul
        const playerDom = document.getElementById("player-3D");
        const widthHalf = playerDom.offsetWidth / 2;
        const heightHalf = playerDom.offsetHeight / 2;
        let x = ( vx * widthHalf ) + widthHalf;
        let y = -( vy * heightHalf ) + heightHalf - 50;
        // Conversion + envoi
        x = parseInt(x); y = parseInt(y);
        return {x: x, y: y};
    };

    // Events
    const handleMouseMove = (event) => { // Detection des mouvements de souris pour la gestion des survols de zone

        // On coupe les survol dans le cas ou un lot specifique soit filtre
        if (event.target.id === 'lotHover' || event.target.id === 'lotHover-link' || filterOne != undefined) {
            return;
        }

        const playerDom = document.getElementById("player-3D");

        const mouseX = (event.offsetX / playerDom.offsetWidth) * 2 - 1; 
        const mouseY = - (event.offsetY / playerDom.offsetHeight) * 2 + 1; 

        const mouse = new THREE.Vector2(mouseX, mouseY); // Gestionnaire de curseur pour la scene 3D
        const projector = new THREE.Raycaster(); // Creation du traceur
        projector.setFromCamera(mouse, camera);

        const intersects = projector.intersectObjects(zones);

        if ( intersects.length > 0 ) {
            // On retire d'abord le hover
            let zone = intersects[0].object.parent;
            setToolTipPosition(zone);
            setZoneOpacity(zone, 1); 
            setHoveredZone(zone);
            // setHoveredZoneMemory(zone);

        } else {
            setOldHoveredZone(hoveredZone);
            setHoveredZone(null);
            setSurvol(null);
        }
        
    }

    // Effects
    useEffect(() => { // recuperations des infos du Json

        if (programme !== undefined) {
            // Gestion de la camera
            camera.rotation.order = 'ZYX'; // correction de l'alignement des axes pour coller avec max
            camera.far = 1000000; // gestion du clipping pour afficher les elements lointains
        }

    }, [programme])

    useEffect(() => { // Gestion du preOver -> survol issu de parametre (exemple via url ou clic moteur recherche)
        setPreOvered(alreadyOveredLot);
    }, [alreadyOveredLot])

    useEffect(() => { // Gestion du preOver -> ajout d'un timer pour couper automatiquement le preOver apres un certain temps
        let interval;

        if (preOvered != undefined) {
            setCutEvent(true);
            interval = setTimeout(() => {
                setPreOvered(undefined);
            }, 3000)
        }
        return (() => {clearInterval(interval)})
        
    }, [preOvered])

    useEffect(() => { // Mise a jour des zones pour chaque etage
        buildZones();
    }, [block, floor, filterOne, jsonDatas])

    useEffect(() => { // Mise a jour de la camera

        // recuperation de la frame
        updateCamera();
        buildPuces();

    }, [maquetteDatas, jsonDatas, block, floor, frame])

    useEffect(() => { // Mise en place des puces

        if (isFirstOpen == true) { // On laisse un petit delais pour que la scene 3D se charge totalement a la premiere ouverture
            setTimeout(() => {
                setIsFirstOpen(false);
            }, 1000)
            return;
        }

    }, [isFirstOpen, jsonDatas, block, floor, frame])

    useEffect(() => { // Mise en place des events des que les zones sont en place
        // Creation des event
        document.getElementById("player-3D").removeEventListener("mousemove", handleMouseMove);

        if (zones.length != 0) {

            if (cutEvent == false) {
                document.getElementById("player-3D").addEventListener("mousemove", handleMouseMove);
            }
        
            // Gestion du cas de preOver
            if (preOvered != undefined) {
                zones.map((zone) => {
                    if(zone.parent.zoneId == preOvered) {
                        setToolTipPosition(zone.parent);
                        setZoneOpacity(zone.parent, 1); 
                        setHoveredZone(zone.parent);
                    }
                })
            } else {
                setOldHoveredZone(hoveredZone);
                setHoveredZone(null);
                setCutEvent(false);
                setSurvol(null);
            }
        }

        return () => {
            const played3D = document.getElementById("player-3D");
            if (played3D != undefined) { played3D.removeEventListener("mousemove", handleMouseMove) }
        }

      
    }, [zones, preOvered, cutEvent])

    useEffect(() => { // Gestion du comportement au survol d'une zone
        if (hoveredZone != hoveredZoneMemory) {
            setOldHoveredZone(hoveredZoneMemory);
            setHoveredZoneMemory(hoveredZone);
        }
    }, [hoveredZone, hoveredZoneMemory])

    useEffect(() => { // Action pour le retrait de la surbrillance de l'ancienne zone survolée
        if (oldHoveredZone != null) {
            setZoneOpacity(oldHoveredZone, 0.8)
        }
    }, [oldHoveredZone])


    useEffect(() => { // Mise en place des puces au changement de vue
        setPuces([])
        if (maquetteDatas) {

            let vw = maquetteDatas.blocks[block]
            if (!vw) {vw = maquetteDatas.complexe}

            const view = vw.views[floor]
            if (view) {
                const viewPuces = view['puces']

                if (viewPuces) {
                    Object.keys(viewPuces).map((puce) => {
                        const pos = new THREE.Vector3();
                        pos.x = viewPuces[puce].x;
                        pos.y = viewPuces[puce].y;
                        pos.z = viewPuces[puce].z; 

                        pos.project(camera);

                        const pagePosition = convertWorldToHtmlRelativeCoordinates(pos.x, pos.y);
                        if (viewPuces[puce].type === "sous-maquette") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces" 
                                onClick = {() => {
                                    setBlock(viewPuces[puce].link)  
                                }}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            >{puce}</div>]); // ajout en memoire des zones
                        } else if (viewPuces[puce].type === "vim-ext") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces vim" 
                                onClick= {() => setExtActiv(viewPuces[puce].link)}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            ><FontAwesomeIcon icon={faEye}/><p>{puce}</p></div>]); // ajout en memoire des zones
                        } else if (viewPuces[puce].type === "info") {
                            setPuces(old => [...old, <div 
                                key = {puce} 
                                className = "puces info" 
                                onClick= {() => {}}
                                style={{'left' : `${pagePosition.x}px`, 'top' : `${pagePosition.y}px`}}
                            >{puce}</div>]); // ajout en memoire des zones
                        }
                    })   
                }
            }
        }
    }, [maquetteDatas, block, floor, zoom])

    
    // Render
    return (
        <>
            <ambientLight intensity = {1} />
            <directionalLight position = {[-2, 5, 2]} intensity = {1} /> 
        </>
    )
}


// ////////////////////////////////////////////////////////////////////////
// Export
export default ThreeJsPlayer;
