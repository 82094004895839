// ///////////////////////////////////////////////////////////////////////////////
// Hooks pour la gestion du cache
// ///////////////////////////////////////////////////////////////////////////////
// Creation 30/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////

// Imports system
import React, {useState, useEffect} from 'react';


function getSessionStorage(key) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return '[]';
    } else if (stored === 'null') {
      return '[]';
    }
    return JSON.parse(stored);
  }
  
const useSessionStorage = (key) => {


    const [value, setValue] = useState(getSessionStorage(key));

    const setForRange = (value) => {
        const actual = getSessionStorage(key);

        if (actual === '[]') {
            setValue([value]);
            return;
        }

        if (getSessionStorage(key).includes(value)) {
            actual.splice(actual.indexOf(value), 1);
            setValue(actual);
        } else {
            actual.push(value);
            setValue(actual);
        }                        
    }
  
    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);


    return [value, setForRange];
  }

export default useSessionStorage;