// Imports system
import React, { useState, useEffect } from 'react';
import useMobile from '../../globalHooks/useMobile';
import { useSearchParams  } from 'react-router-dom'

// Composant pour la navigation d'un player 3D
const Palette = ({list, current, setCurrent, fixedTop = null, fixedBottom = null, extraEvent = () => {}, useSearch = null}) => {

  
    // /////////////////////////////////////////
    // Properties
    fixedTop = typeof(fixedTop) === "string" ? {value : fixedTop, label : fixedTop} : fixedTop
    fixedBottom = typeof(fixedBottom) === "string" ? {value : fixedBottom, label : fixedBottom} : fixedBottom
    list = list.map(e => {if (typeof(e) === "string") {return {label : e, value : e}} else {return e}})

    const isMobile = useMobile();
    
    // etat
    const [searchParams, setSearchParams] = useSearchParams ()
    const [translate, setTranslate] = useState(0);
    const [index, setIndex] = useState(list.findIndex(x => x.value === current));
    const [touchPos, setTouchPos] = useState(0);
    const [touchMove, setTouchMove] = useState(0);
    const [height, setHeight] = useState(() => {
      if (list.length < 5) {
        return list.length * 40;
      } else if (isMobile) {
        return 3 * 40;
      } else {
        return 5 * 40;
      }
    });
  
    // /////////////////////////////////////////
    // Fonctions



    const calculTranslation = (index) => { // Fonction pour definir la position de la roulette
      if (list.length < 5) {
        setTranslate(0);
      } 
      else if(isMobile) {
        if (index < 2) {
          setTranslate(0);
        } else if (index > list.length - 2) {
          setTranslate(list.length - 3);
        } else {
          setTranslate(index - 1);
        }  
      }
      else {
        if (index < 3) {
          setTranslate(0);
        } else if (index > list.length - 3) {
          setTranslate(list.length - 5);
        } else {
          setTranslate(index - 2);
        }  
      }
    }
  
    const calculIndicatorPosition = () => { // Fontion pour definir la position du curseur de selection
      if (current == fixedTop?.value) {
        return (0)
      } else if (current == fixedBottom?.value) {
        return (height + 40)
      } else if(isMobile) {
        if (list.length < 5) {
          return (index + (fixedTop?.value != null ? 1 : 0)) * 40
        } else {
          if (index < 2) {
            return (index + (fixedTop?.value != null ? 1 : 0)) * 40
          } else if (index > list.length - 2) {
            return 40 * (fixedTop?.value != null ? 3 : 2) - ((list.length-1 - index) * 40)
          } else {
            return 40 * (fixedTop?.value != null ? 2 : 1) 
          }
        }
      } 
      else {
          if (list.length < 5) {
            return (index + (fixedTop?.value != null ? 1 : 0)) * 40
          } else {
            if (index < 3) {
              return (index + (fixedTop?.value != null ? 1 : 0)) * 40
            } else if (index > list.length - 3) {
              return 40 * (fixedTop?.value != null ? 5 : 4) - ((list.length-1 - index) * 40)
            } else {
              return 40 * (fixedTop?.value != null ? 3 : 2) 
            }
          }
      }
      
    }

    const change = (to) => {
      if (useSearch) {
        searchParams.set(useSearch, to)
        setSearchParams(searchParams)
      }
      setCurrent(to)
    }
  
  
    // /////////////////////////////////////////
    // Event
    const handleWheel = (e) => { // Event au scroll
      extraEvent(); // appel de la fonction supplementaire
      if (e.deltaY < 0) {
          if (index > 0) {setIndex(i => i - 1)}
          else if (fixedTop?.value != null) {
            change(fixedTop?.value)
            setIndex(-1)
            setTranslate(0)
          }
      } else {
          if (index < list.length-1) {setIndex(i => i + 1)}
          else if (fixedBottom?.value != null) {
            change(fixedBottom?.value)
            setIndex(list.length)
            setTranslate(isMobile ? list.lenght -3 : list.length - 5)  
          }
      }
    }
  
    // /////////////////////////////////////////
    // Effect

    useEffect(() => {
      if (list.length < 5) {
        setHeight(list.length * 40);
      } else if (isMobile) {
        setHeight(3 * 40);
      } else {
        setHeight(5 * 40);
      }
    }, [list, isMobile]);
  
    useEffect(() => { // Verification initiale

      if (current == fixedTop?.value) {
        setIndex(-1)
      } else if (current == fixedBottom?.value) {
        setIndex(list.length)
        calculTranslation(list.length)
      }
    }, [])
  
    useEffect(() => { // Mise a jour de l'etage au changement d'index sur la roulette
      if (index != -1 && index < list.length) {
        change(list[index].value)
        calculTranslation(index)
      }
    }, [index])

    useEffect(() => {
      if (current != fixedTop?.value && current != fixedBottom?.value) {
        // if (index != list.indexOf(current)) {
          setIndex(list.findIndex(x => x.value === current));
        // }
      } else if (current === fixedTop?.value && index !== -1) { // Verifications au moment ou l'ou force le current (par ex : vue nuit)
        setIndex(-1)
      }
    }, [current])

    useEffect(() => {
      const mov = -Math.round(touchMove / 40);
      extraEvent(); // appel de la fonction supplementaire
      
      setIndex(i => {
        const nextPos = i + mov;
        
        if (nextPos <= -1) {
          change(fixedTop?.value);
          return -1;
        } else if (nextPos >= list.length) {
          change(fixedBottom?.value);
          return list.length;
        }
        return(nextPos)
      });
    }, [touchMove])
  

    useEffect(() => {
      if (current === fixedBottom?.value && index !== list.length) {
        setIndex(list.length)
      }
    }, [list.length])
  
    // /////////////////////////////////////////
    // Render
    return (
      <nav 
        className = "selector" 
        onTouchStart={(e) => setTouchPos(e.changedTouches[0].clientY)}
        onTouchEnd={(e) => setTouchMove(touchPos - e.changedTouches[0].clientY)}
      >
  
        {fixedTop?.value &&
          <button 
            className = {`choice top ${current == fixedTop?.value ? "current-activ" : ""}`}
            onClick = {() => {
              extraEvent(); // appel de la fonction supplementaire
              change(fixedTop?.value)
              setIndex(-1)
              setTranslate(0)
            }}
            onWheel = {handleWheel}
          >{fixedTop.label}</button>
        }
  
        <div className = "roulette" style = {{height : `${height}px`}}>
          <div className = "roulette-content" style = {{transform : `translateY(-${translate * 40}px)`}} onWheel = {handleWheel}>
            {list.map((elem, i) => {
              return (
                <button 
                  key = {elem.value} 
                  onClick = {() => {
                    extraEvent(); // appel de la fonction supplementaire
                    setIndex(i)
                  }}
                  style = {{opacity : list.length >= 5 ? `${i == index ? 1 : (1 / Math.abs(index - i))}` : '1'}}
                  className = {`choice ${current == elem ? "current-activ" : ""}`}
                >{elem.label}</button >
              )})
            }
          </div>
        </div>
  
        {fixedBottom?.value &&
          <button 
            className = {`choice top ${current == fixedBottom?.value ? "current-activ" : ""}`}
            onClick = {() => {
              extraEvent(); // appel de la fonction supplementaire
              change(fixedBottom?.value)
              setTranslate(isMobile ? list.length - 3 : list.length - 5)
              setIndex(list.length)
            }}
            onWheel = {handleWheel}
          >{fixedBottom.label}</button>
        }
  

        {index === -1 && 
          <div className = "indicator" style = {{top : `${calculIndicatorPosition(index)}px`}}>{fixedTop?.label}</div>
        }

        {index === list.length && 
          <div className = "indicator" style = {{top : `${calculIndicatorPosition(index)}px`}}>{fixedBottom?.label}</div>
        }

        {![-1, list.length].includes(index) &&
          <div className = "indicator" style = {{top : `${calculIndicatorPosition(index)}px`}}>{list[index]?.label}</div>
        }
  
      </nav>
    )
  }

  export default Palette;