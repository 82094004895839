// /////////////////////////////////////////////////////////////////////////////////
// Composant pour les input de type number
// /////////////////////////////////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// /////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, {useState} from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

const InputNumber = ({value, setValue, min, max, placeholder = null, color = "a25a45"}) => {

    const [translateY, setTranslateY] = useState(0);

    const minusOne = () => {
        let decal = 0;
        const anim = setInterval(() => {
            decal = decal + 10;
            if (decal >= 86) {
                setValue(v => v-1)
                setTranslateY(0);
                clearInterval(anim);
            } else {
                setTranslateY(decal);
            }
        }, 1)
    }

    const plusOne = () => {
        let decal = 0;
        const anim = setInterval(() => {
            decal = decal - 10;
            if (decal <= -86) {
                setValue(v => {
                    if (v === null || v === undefined) return min;
                    return v+1
                })
                setTranslateY(0);
                clearInterval(anim);
            } else {
                setTranslateY(decal);
            }
        }, 1)
    }

    const keyboardEnter = (e) => {

        if (e.target.value == '') {
            setValue(null);
        }

        const asNumber = parseFloat(e.target.value); 
        if (!isNaN(asNumber)) {
            setValue(asNumber);
        } 
    }

    const keyboardValidation = (e) => {

        if (e.target.value == '') {return}
        
        const asNumber = parseFloat(e.target.value); 
        if (!isNaN(asNumber)) {
            if (asNumber > max ) {
                setValue(max);
            }
            
            if (asNumber < min) {
                setValue(min);
            }
        } 
    }
    return (
        <div className = 'inputNumber'>
            <div className='arrowZone'>
                {value > min &&
                    <div onClick = {minusOne}>
                        <FontAwesomeIcon icon={faCaretLeft} color={color}/>
                    </div> 
                }
            </div>

            <div className = 'inputNumber-slider' style={{transform : `translateY(${translateY}px)`}}>
                <p>{value - 1}</p>
                <input value={value} placeholder = {placeholder} onChange={keyboardEnter} onBlur={keyboardValidation} ></input>
                <p>{value + 1}</p>
            </div>

            <div className='arrowZone'>
                {value < max &&
                    <div onClick = {plusOne}>
                        <FontAwesomeIcon icon={faCaretRight} color={color}/>
                    </div> 
                }
            </div>

        </div>
    )
}

export default InputNumber;