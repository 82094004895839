// /////////////////////////////////////////////////////////////
// Fichier principal du moteur V6
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useRef } from 'react';

// Imports src
import { 
    domain,
    programmeContext,
    programmeLotsContext,
    maquetteContext,
    jsonContext,
    pagesContext
} from './../../contexts';

import ProgrammeRouter from './../../layout/routers/programmeRouter';
import { useLocation } from 'react-router-dom';


// ///////////////////////////////////
// Export

const ProgrammeLoader = ({programme}) => {
    // Composant pour le chargement d'un programme

    const [programmeDatas, setProgrammeDatas] = useState(null);
    const [programmeLotsDatas, setProgrammeLotsDatas] = useState(null);
    const [maquetteDatas, setMaquetteDatas] = useState(null);
    const [jsonDatas, setJsonDatas] = useState(null);
    const [pagesDatas, setPagesDatas] = useState({});
    const [howManyLoading, setHowManyLoading] = useState(0);
    const olreadyChecked = useRef(false);
    const [firstPageLoaded, setFirstPageLoaded] = useState(false);
    const location = useLocation();


    const loadHome = (datas) => {

        const tpDatas = {...datas}
        if (tpDatas.img && tpDatas.img !== "") {
            tpDatas.img = <img id = "homeBackground" src = {`/public/programmes/${programmeDatas.name}/home/${tpDatas.img}`}/> ;
        }

        return tpDatas
    }
    const loadProject = (datas) => {
        const tpDatas = {...datas}
        if (tpDatas.src && tpDatas.src !== "") {
            tpDatas.src = <img id = "descriptionImg" src = {`/public/programmes/${programmeDatas.name}/projet/${tpDatas.src}.jpg`}/> ;
        }
        return tpDatas
    }
    const loadGalerie = (datas) => {
        const tpDatas = {...datas}
        const tpMedias = []

        let i = 0;
        for (const media of tpDatas.allMedias) {
            const tpMedia = {...media}

            if (tpMedia.fullName && tpMedia.fullName !== "") {
                tpMedia.fullName = <img id = {`media-${i}`} src = {`/public/programmes/${programmeDatas.name}/galerie/${tpMedia.fullName}`}/>
            }
            tpMedias.push(tpMedia);
            i++;
        }

        tpDatas.allMedias = tpMedias;
        return tpDatas 
    }

    const fetchDatas = () => {
        fetch(`${domain}/programme/${programme}/`, { mode: 'cors' })
        .then(response => response.json())
        .then(data => {
            setProgrammeDatas(data)
        })
    }

    useEffect(() => { // Recuperation des donnees globales pour le programme
        if (programme) {

            if (window.caches && caches) {

                // Purge des anciens caches pour eviter les conflits
                if (window.caches && caches) {
                    caches.keys().then((keyList) =>
                           Promise.all(
                               keyList.filter(x => x !== "v6-neocache-v1").map((key) => {return caches.delete(key);}),
                        ))
                    }

                // recuperation de la version pour savoir si besoin de clear cache storage
                fetch(`${domain}/programme/${programme}/version`, { mode: 'cors' })
                .then(response => {
                        caches.open("v6-neocache-v1")
                        .then(c => {
                            c.match(`${domain}/programme/${programme}/version`).then(x => {
                                if (x) {
                                    response.json()
                                    .then(jsr => {
                                        x.json()
                                        .then(jsj => {
                                            if (jsr.version !== jsj.version) {
                                                // clear cache if needed
                                                caches.keys().then((keyList) =>
                                                    Promise.all(
                                                        keyList.map((key) => {return caches.delete(key);}),
                                                    ).then(() => {
                                                        // fetch des données apres le clear cache
                                                        fetchDatas()
                                                    }))
                                            } else {fetchDatas()}
                                        })  

                                    })
                                } else { 
                                    c.put(`${domain}/programme/${programme}/version`, response)
                                    fetchDatas() 
                                }
                            })
                        })
                })
                .catch(e => {
                    console.log("erreur sur la gestion du cache")
                    fetchDatas()
                })
            } else {
                fetchDatas()
            }
        }
    }, [programme])

    useEffect(() => {

        if (programmeDatas) {
            if (olreadyChecked.current) {return;}
            olreadyChecked.current = true;
  
            // /////////////////////////////////////////
            // chargement des lots
            fetch(`${domain}/programme/${programme}/lots/`, { mode: 'cors' })
            .then(response => response.json())
            .then(data => setProgrammeLotsDatas(data))        
            .catch(() => {})
            .finally(() => setHowManyLoading(hml => hml+1))
            
            // ////////////////////////////////////////
            // chargement de la maquette
            if (programmeDatas.pages.includes("maquette 3D")) {
                fetch(`${domain}/${programmeDatas.name}/maquette`, { mode: 'cors' })
                .then(response => response.json())
                .then(mDatas => {
                    setMaquetteDatas(mDatas);
                    
                    // chargement du json
                    fetch(`/public/programmes/${programmeDatas.name}/media.json`, { mode: 'cors' })
                    .then(response => response.json())
                    .then(jsDatas => {
                        setJsonDatas(jsDatas);                                      
                    })
                    .catch(() => {})
                    .finally(() => setHowManyLoading(hml => hml+1))
                })
                .catch(() => {})
                .finally(() => setHowManyLoading(hml => hml+1))
            } else {
                setHowManyLoading(hml => hml+2)
            }

            // ///////////////////////////////////////
            // chargement des contenus

            if (!location.pathname.endsWith(programmeDatas.name)) { // Chargement en priorite de la page d'arrivee
                const pageToLoad = location.pathname.split("/")[location.pathname.split("/").indexOf(programmeDatas.name)+1]

                // Arrivee sur page galerie
                if (pageToLoad === "projet") {
                    setPagesDatas(d => { return {...d, 'projet' : loadProject(programmeDatas.pagesDetails.projet)}})
                    setFirstPageLoaded(true);

                    if (programmeDatas.pages.includes('galerie')) {
                        setPagesDatas(d => { return {...d, 'galerie' : loadGalerie(programmeDatas.pagesDetails.galerie)}})
                    }
                } 
                // Arrivee sur page galerie
                else if (pageToLoad === "galerie") {
                    setPagesDatas(d => { return {...d, 'galerie' : loadGalerie(programmeDatas.pagesDetails.galerie)}})
                    setFirstPageLoaded(true);

                    if (programmeDatas.pages.includes('projet')) {
                        setPagesDatas(d => { return {...d, 'projet' : loadProject(programmeDatas.pagesDetails.projet)}})
                    }
                } 

                // Arrivee sur toute autre page
                else {
                    if (programmeDatas.pages.includes('projet')) {
                        setPagesDatas(d => { return {...d, 'projet' : loadProject(programmeDatas.pagesDetails.projet)}})
                    }
                    if (programmeDatas.pages.includes('galerie')) {
                        setPagesDatas(d => { return {...d, 'galerie' : loadGalerie(programmeDatas.pagesDetails.galerie)}})
                    }
                    setFirstPageLoaded(true);
                }
                
                if (programmeDatas.pages.includes('home')) {
                    setPagesDatas(d => { return {...d, 'home' : loadHome(programmeDatas.pagesDetails.home)}})
                }


            } else { // Chargement normal
                if (programmeDatas.pages.includes("home")) {
                    setPagesDatas(d => { return {...d, 'home' : loadHome(programmeDatas.pagesDetails.home)}} )
                    setFirstPageLoaded(true);

                    if (programmeDatas.pages.includes('projet')) {
                        setPagesDatas(d => { return {...d, 'projet' : loadProject(programmeDatas.pagesDetails.projet)}})
                    }
                    if (programmeDatas.pages.includes('galerie')) {
                        setPagesDatas(d => { return {...d, 'galerie' : loadGalerie(programmeDatas.pagesDetails.galerie)}})
                    }
                } else {
                    setFirstPageLoaded(true);

                    if (programmeDatas.pages.includes('projet')) {
                        setPagesDatas(d => { return {...d, 'projet' : loadProject(programmeDatas.pagesDetails.projet)}})
                    }
                    if (programmeDatas.pages.includes('galerie')) {
                        setPagesDatas(d => { return {...d, 'galerie' : loadGalerie(programmeDatas.pagesDetails.galerie)}})
                    }
                }
            }

        }
    }, [programmeDatas])


    return (

        // Chargement
        howManyLoading < 2 ?
            
            <div id = "programmeLoader">
                <h3>Chargement du programme</h3>

                {programmeDatas ?
                    <p>Recuperation des informations du programme</p>
                    :
                    <p>Veuillez patienter</p>
                }
            </div>
        :
        // Page prete a l'affichage
        <programmeContext.Provider value={programmeDatas}>
            <programmeLotsContext.Provider value={programmeLotsDatas}>
                <maquetteContext.Provider value={maquetteDatas}>
                    <jsonContext.Provider value={jsonDatas}>
                        <pagesContext.Provider value={pagesDatas}>

                            {firstPageLoaded &&
                                <ProgrammeRouter />
                            }

                        </pagesContext.Provider>
                    </jsonContext.Provider>
                </maquetteContext.Provider>
            </programmeLotsContext.Provider>
        </programmeContext.Provider>
    )
}

export default ProgrammeLoader;