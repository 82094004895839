// ///////////////////////////////////////////////////////////////////////////////
// Component pour la gestion d'un lot resultant du moteur de recherche maquette 3D
// ///////////////////////////////////////////////////////////////////////////////
// Creation 17/05/2022          CGR
// update : 06/09/2022          CGR :  separation du fichier
// ///////////////////////////////////////////////////////////////////////////////

// Imports system
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { languageContext, programmeContext } from "../../../../contexts";

const SearchResult = ({
  name,
  type,
  etage,
  surface,
  statut,
  block,
  setBlock,
  setFloor,
  setForcedLotHover,
  rooms = 0,
  useSmallName = false,
}) => {
  // Proprietes
  let navigate = useNavigate(); // pour la gestion des routes
  const loc = useLocation();
  const [sup, setSup] = useState("");
  const programmeDatas = useContext(programmeContext);
  const lang = useContext(languageContext);

  // Event
  const handleClick = () => {
    if (etage == null) {
      navigate(
        `/${programmeDatas.urlName}/detail-lot/${name}/plan${loc.search}`,
        { replace: true }
      );
    } else {
      setBlock();
      setTimeout(() => {
        setFloor();
        setTimeout(() => {
          setForcedLotHover(name);
          setTimeout(() => {
            setForcedLotHover(null);
          }, 3000); // TODO : Clairement pas le bon lieu ou faire ca -> a mettre dans le player3D
        }, 200);
      }, 50);
    }
  };

  const isTypeNumber = (value) => {
    return Number.isFinite(value);
  };

  // Effects
  useEffect(() => {
    // gestion de l'affichage de l'etage au chargement du composant
    if (isNaN(etage)) {
      setSup("");
    } else if (etage == 1) {
      setSup("er");
    } else {
      setSup("ème");
    }
  }, []);

  return (
    <div onClick={handleClick}>
      <div className="result-lot" key={name}>
        <div className="result-lot-title">
          <h2 className={`mainColor ${useSmallName && "small"}`}>{name}</h2>
          {programmeDatas.displayStatus && (
            <div
              className={`result-lot-title-status ${
                statut === "Disponible" || statut === "Dispo."
                  ? "mainBackground"
                  : "unable"
              }`}
            >
              {programmeDatas.languagesDatas[lang].lots.statut[statut]}
            </div>
          )}
        </div>
        <div className="result-lot-info">
          <p>
            {programmeDatas.languagesDatas[lang].maquette.search.results.type}
          </p>
          <p className="lotHover-info-data">
            {lang === "fr" || type === "Studio" || !isTypeNumber(type)
              ? type
              : rooms}
          </p>
        </div>

        {/* Affichage conditionnel de batiment si l'information existe */}
        {block != undefined && (
          <div className="result-lot-info">
            <p>
              {
                programmeDatas.languagesDatas[lang].maquette.search.results
                  .batiment
              }
            </p>
            <p className="lotHover-info-data">{block}</p>
          </div>
        )}

        {/* Affichage conditionnel de l'etage si l'information existe */}
        {etage != undefined && (
          <div className="result-lot-info">
            <p>
              {
                programmeDatas.languagesDatas[lang].maquette.search.results
                  .etage
              }
            </p>
            <p className="lotHover-info-data">
              {etage.replace(
                "Etage",
                programmeDatas.languagesDatas[lang].maquette.palette.etages
              )}
              <sup>{sup}</sup>
            </p>
          </div>
        )}

        <div className="result-lot-info">
          <p>
            {
              programmeDatas.languagesDatas[lang].maquette.search.results
                .surface
            }
          </p>
          <p className="lotHover-info-data">{surface}</p>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
