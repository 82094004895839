// ///////////////////////////////////////////////////////
// Composant pour la gestion du viewer de la galerie
// ///////////////////////////////////////////////////////
// Creation : 30/04/2022    -> CGR
// Maj : 08/08/2022 -> separation du fichier
// ///////////////////////////////////////////////////////


// Imports system
import React, {useState, useEffect, useRef} from 'react';

// Component
const GalerieScreen = ({src, isCollapsed, index, setIndex, type}) => { // Ecran d'affichage de l'image active


    // Properties
    const [touchPos, setTouchPos] = useState(0);
    const [touchMove, setTouchMove] = useState(0);
    const videoIframe = useRef()

    // Effect
    useEffect(() => {
        if (Math.abs(touchMove) > 40) {
            if (touchMove < 0) {
                setIndex(index+1);
                setTouchMove(0);
            } else {
                setIndex(index-1);
                setTouchMove(0);
            }
        } else {
            setTouchMove(0);
        }
    }, [index, touchMove])


    // Render
    return (
        <div 
            id = 'galerie-screen' 
            className = {`${isCollapsed && "fullScreen"}`}
            onTouchStart = {(e) => setTouchPos(e.changedTouches[0].clientX)}
            onTouchEnd = {(e) => setTouchMove(touchPos - e.changedTouches[0].clientX)}
        >
            {
                type === "video" ?
                <iframe
                    ref={videoIframe}
                    src={`https://player.vimeo.com/video/${src}&title=0&controls=0&muted=1&quality=1080p&autoplay=1&loop=1&color=ED4230&title=0&byline=0&portrait=0`}
                    frameBorder="0"
                    allow="autoplay" allowFullScreen
                >
                </iframe>
                :
                src
            }
        </div>
    )
}

export default GalerieScreen;