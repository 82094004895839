// ///////////////////////////////////////////////////////
// Composant pour la gestion des geolocalisation krPano
// ///////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// ///////////////////////////////////////////////////////

// Imports system
import React, { useEffect, useContext, useState } from 'react';
import useKrpano from 'react-krpano-hooks';
import { programmeContext, programmeNameContext } from '../../../contexts';
import Annotation from '../../../components/annotation/annotation';
import SvgIcon from "./../../../components/svg/svg";


const Geolocalisation = ({ }) => { // Partie découvrir

    // /////////////////////////////
    // Properties
    const programmeName = useContext(programmeNameContext);
    const programmeData = useContext(programmeContext);
    const [canUseGyro, setCanUseGyro] = useState(false);
    const [gyro, setGyro] = useState(false);
    const [cardBoard, setCardBoard] = useState(false);
    const { containerRef, setKrpano, callKrpano, krpanoState } = useKrpano({
        scriptPath: `/public/programmes/${programmeName}/localisation/geolocalisation/geoloc/tour.js`,
        embeddingParams: {
            swf: `/public/programmes/${programmeName}/localisation/geolocalisation/geoloc/tour.swf`,
            xml: `/public/programmes/${programmeName}/localisation/geolocalisation/geoloc/tour.xml`,
            target: 'react-krpano',
            html: 'prefer'
        }
    })

    const annotation = programmeData.pagesDetails.localisation.geolocalisation.scenes.geoloc.annotationText;

    // /////////////////////////////
    // Effects
    useEffect(() => {

        // variable global pour effet krPano
        window.endVr = () => {
            setCardBoard(false)
            setGyro(false)
        }
        
        setCanUseGyro(navigator.platform !== "Win32")
    }, [])

    useEffect(() => { // Au chargement du krPano -> mise en place des positions et des puces

        if (programmeData && krpanoState.isEmbedded) {

            const data = programmeData.pagesDetails.localisation.geolocalisation.scenes;

            const setBase = (poi) => {
                callKrpano(`addhotspot(L${poi.id});`);
                callKrpano(`hotspot[L${poi.id}].loadstyle(skin_poi_geo);`);
                callKrpano(`set(hotspot[L${poi.id}].ath, ${poi.x});`);
                callKrpano(`set(hotspot[L${poi.id}].atv, ${poi.y});`);
                callKrpano(`set(hotspot[L${poi.id}].zoom,false);`);
                callKrpano(`set(hotspot[L${poi.id}].distorted,false);`);
                callKrpano(`set(hotspot[L${poi.id}].bgcolor, 0xffffff);`);
                callKrpano(`set(hotspot[L${poi.id}].bgalpha, 1);`);
                callKrpano(`set(hotspot[L${poi.id}].width, 13);`);
                callKrpano(`set(hotspot[L${poi.id}].height, 13);`);
                callKrpano(`set(hotspot[L${poi.id}].zorder, 9);`);
                callKrpano(`set(hotspot[L${poi.id}].edge, center);`);
                callKrpano(`set(hotspot[L${poi.id}].bgborder, 2);`);
                callKrpano(`set(hotspot[L${poi.id}].bgroundedge, 6);`);
                callKrpano(`set(hotspot[L${poi.id}].align, center);`);
                callKrpano(`set(hotspot[L${poi.id}].zorder, 10);`);
            }

            const setBar = (poi) => {
                callKrpano(`addlayer(L${poi.id}_bar);`);
                callKrpano(`set(layer[L${poi.id}_bar].parent, hotspot[L${poi.id}]);`);
                callKrpano(`layer[L${poi.id}_bar].loadstyle(skin_bar_large);`);
                callKrpano(`set(layer[L${poi.id}_bar].zoom,false);`);
                callKrpano(`set(layer[L${poi.id}_bar].x, 0;`);
                callKrpano(`set(layer[L${poi.id}_bar].y, 14;`);
                callKrpano(`set(layer[L${poi.id}_bar].width, 2;`);
                callKrpano(`set(layer[L${poi.id}_bar].distorted,false);`);
                callKrpano(`set(layer[L${poi.id}_bar].height, ${poi.length});`);
                callKrpano(`set(layer[L${poi.id}_bar].edge, bottomcenter);`);
                callKrpano(`set(layer[L${poi.id}_bar].align, bottomcenter);`);
            }


            // Gestion des poi
            for (let poi of data.geoloc.pois) {

                setBase(poi);
                setBar(poi);

                callKrpano(`addlayer(L${poi.id}_ctn);`);
                callKrpano(`set(layer[L${poi.id}_ctn].parent, hotspot[L${poi.id}]);`);
                if (poi.icon !== null) {
                    callKrpano(`layer[L${poi.id}_ctn].loadstyle(skin_text_large_poi);`);
                } else {
                    callKrpano(`layer[L${poi.id}_ctn].loadstyle(skin_text_large_nopoi);`);
                }
                callKrpano(`set(layer[L${poi.id}_ctn].zoom,false);`);
                callKrpano(`set(layer[L${poi.id}_ctn].x, 6;`);
                callKrpano(`set(layer[L${poi.id}_ctn].y, ${poi.length + 38};`);
                callKrpano(`set(layer[L${poi.id}_ctn].html, ${poi.name});`);
                callKrpano(`set(layer[L${poi.id}_ctn].distorted, false);`);
                callKrpano(`set(layer[L${poi.id}_ctn].sprite.id, poi);`);
                if (poi.link !== "") {
                    if (poi.linkType === "direct") {
                        callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}",_self));`);
                    } else {
                        callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}",_blank));`);
                    }
                }

                if (poi.icon != null) {
                    callKrpano(`addlayer(L${poi.id}_csv);`);
                    callKrpano(`set(layer[L${poi.id}_csv].parent, hotspot[L${poi.id}]_ctn);`);
                    callKrpano(`set(layer[L${poi.id}_csv].zoom,false);`);
                    callKrpano(`set(layer[L${poi.id}_csv].x, ${-3 * poi.name.length};`);
                    callKrpano(`set(layer[L${poi.id}_csv].y, -${poi.length + 31};`);
                    callKrpano(`set(layer[L${poi.id}_csv].distorted,false);`);
                    callKrpano(`set(layer[L${poi.id}_csv].zorder, 10);`);
                    callKrpano(`set(layer[L${poi.id}_csv].scale, 0.17);`);
                    callKrpano(`set(layer[L${poi.id}_csv].edge, top);`);
                    callKrpano(`set(layer[L${poi.id}_csv].url, /public/icons/${poi.icon}.svg);`);
                }

            }

            // Gestion des poi projets
            for (let poi of data.geoloc.poisProjet) {

                if (poi.image !== "") {

                    setBase(poi);
                    setBar(poi);

                    callKrpano(`addlayer(L${poi.id}_ctn);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].parent, hotspot[L${poi.id}]);`);
                    callKrpano(`layer[L${poi.id}_ctn].loadstyle(skin_img_projet);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].zoom,false);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].distorted, false);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].sprite.id, poi);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].backgroundcolor, 0x${programmeData.color.slice(1)});`);
                    callKrpano(`set(layer[L${poi.id}_ctn].url, /public/${poi.image});`);
                    callKrpano(`set(layer[L${poi.id}_ctn].y, -${poi.length + 50});`);


                    if (poi.link !== "") {
                        if (poi.linkType === "direct") {
                            callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}" ,_self));`);
                        } else {
                            callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}",_blank));`);
                        }
                    }
                } else {

                    setBase(poi);
                    setBar(poi);

                    callKrpano(`addlayer(L${poi.id}_ctn);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].parent, hotspot[L${poi.id}]);`);
                    callKrpano(`layer[L${poi.id}_ctn].loadstyle(skin_text_large_projet);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].zoom,false);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].backgroundcolor, 0x${programmeData.color.slice(1)});`);
                    callKrpano(`set(layer[L${poi.id}_ctn].html, ${poi.name});`);
                    callKrpano(`set(layer[L${poi.id}_ctn].distorted, false);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].sprite.id, poi);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].edge, bottomcenter);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].align, bottomcenter);`);
                    callKrpano(`set(layer[L${poi.id}_ctn].x, 0)`);
                    callKrpano(`set(layer[L${poi.id}_ctn].y, ${poi.length + 16});`);
                    callKrpano(`set(layer[L${poi.id}_ctn].padding, 16);`);
                    setTimeout(() => {
                        callKrpano(`set(layer[L${poi.id}_ctn].html, -);`);
                        callKrpano(`set(layer[L${poi.id}_ctn].html, ${poi.name});`);
                        setTimeout(() => {
                            callKrpano(`set(layer[L${poi.id}_ctn].html, -);`);
                            callKrpano(`set(layer[L${poi.id}_ctn].html, ${poi.name});`);
                            setTimeout(() => {
                                callKrpano(`set(layer[L${poi.id}_ctn].html, -);`);
                                callKrpano(`set(layer[L${poi.id}_ctn].html, ${poi.name});`);
                            }, 1500)
                        }, 1500)
                    }, 300)

                    if (poi.link !== "") {
                        if (poi.linkType === "direct") {
                            callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}",_self));`);
                        } else {
                            callKrpano(`set(layer[L${poi.id}_ctn].onclick, openurl("${poi.link}",_blank));`);
                        }
                    }


                }
            }

            setTimeout(() => {
                setKrpano("view.hlookat", data.geoloc.xStart);
                setKrpano("view.vlookat", data.geoloc.yStart);
                setKrpano("view.fov", data.geoloc.fovStart);
                setKrpano("view.fovmax", data.geoloc.fovMax);
                setKrpano("view.maxpixelzoom", 0);
                setKrpano("view.fovmin", data.geoloc.fovMin);
            }, 100);

        }

    }, [programmeData, krpanoState.isEmbedded])

    useEffect(() => {
        const kr = containerRef.current.children[0]
    
        if (kr) {
            if (cardBoard) { kr.call("webvr.enterVR();") } 
            else  { 
                kr.call("webvr.exitVR();") 
            }
        }
    }, [cardBoard])
    
    useEffect(() => {
        const kr = containerRef.current.children[0]
    
        if (kr) {
            if (gyro) { kr.set("plugin[skin_gyro].enabled", 'true') } 
            else  { kr.set("plugin[skin_gyro].enabled", 'false') }
        }
    }, [gyro])

    return (
        <div id='geolocalisation'>
            <div ref={containerRef} style={{ position: 'absolute', width: '100%', height: '100%' }} />

            {(programmeData.useVRforKr && canUseGyro) &&
                <div id = "gyro">

                    <button className = {`no-hover ${gyro ? "mainBackground" : "inactive"}`} onClick = {() => setGyro(g => {
                        if (g) {setCardBoard(false); return !g}
                        else {return !g}
                    })}>
                        <SvgIcon icon = "gyroscope" />
                    </button>     

                    <button className = {`no-hover ${cardBoard ? "mainBackground" : "inactive"}`} onClick = {() => setCardBoard(c => {
                        if (c) {return !c}
                        // else {setGyro(true); return !c}
                    })}>
                        <SvgIcon icon = "cardboard" />
                    </button>
                </div>
            }

            {annotation && <Annotation text={annotation}></Annotation>}
        </div>
    )
}


export default Geolocalisation;