import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Palette from "../../../components/palette/palette";
import { programmeContext, programmeNameContext } from "./../../../contexts";

const TemoinNavigationContent = () => {
  const { currentInt } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const temoinsDatas = Object.fromEntries(
    Object.entries(programmeDatas.pagesDetails.interieurs.temoins).sort(
      (a, b) => a[1].order - b[1].order
    )
  );

  return (
    <div>
      <Palette
        list={Object.values(temoinsDatas).map((item) => item.realName)}
        current={currentInt}
        setCurrent={(e) =>
          navigate(
            `/${programmeDatas.urlName}/interieurs/temoins/${e}${search}`
          )
        }
      ></Palette>
    </div>
  );
};

export default TemoinNavigationContent;
