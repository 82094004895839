// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons standards de la V6
// ////////////////////////////////////////////////////////////////////////////////
// Creation :   21/09/2022 FTR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React from 'react';

// Import components
import SvgIcon from '../../../../components/svg/svg'

// Bouton
const POI = ({icon, color, text}) => {

    // rendu
    return (
        <div className='poi'>
            <div className='pin' style={{backgroundColor : color}}>
                <SvgIcon icon={icon}/>
            </div>
            <div className="title">{text}</div>
            <div className='shadow'></div>
        </div>
    )
}

export default POI;
