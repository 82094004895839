import React, { useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { programmeNameContext, programmeContext, pageContext, noHeaderContext, languageContext } from './../../contexts'
import VerticalSwitch from '../../components/inputs/inputVerticalSwitch'
import Temoin from './temoin/Temoin'
import TemoinNavigation from './temoin/TemoinNavigation'
import PanoContent from './pano/PanoContent'
import PanoNavigation from './pano/PanoNavigation'
import Annotation from './../../components/annotation/annotation'
import FullScreen from './../../layout/fullScreen/fullScreen'

const Interieurs = ({ loadHdForFrame }) => {

    const { pathname, search } = useLocation()
    const noHeader = useContext(noHeaderContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const programmeDatas = useContext(programmeContext)
    const lang = useContext(languageContext)
    const { setCurrent, setHeaderLevel } = useContext(pageContext);
    const navigate = useNavigate()

    // Effects
    useEffect(() => {
        if (programmeDatas) {

            setHeaderLevel(1);
            setCurrent("interieurs");
            setTimeout(() => { // Pour les chargement un peu lent, test un peu plus tardif
                setCurrent("interieurs");
            }, (200));

            // test du cas si l'url n'est pas complete
            if (pathname === `/${programmeDatas.urlName}/interieurs`) {
                navigate(`/${programmeDatas.urlName}/interieurs/${Object.keys(programmeDatas.pagesDetails.interieurs.pano).length ? `pano/${Object.keys(programmeDatas.pagesDetails.interieurs.pano)[0]}${search}` : `temoins${search}`}`)
            }
        }
    }, [programmeDatas, pathname])



    return (
        <div id="interieurs" style={{ zIndex: noHeader ? 4 : 1 }} className={`${searchParams.get("isolate") ? "isolate" : ""}`}>
            <div id='interieursControllers' className={`${noHeader ? "no-header" : ""}`}>

                {!searchParams.get("isolate") &&
                    <>
                        {(Object.keys(programmeDatas.pagesDetails.interieurs.pano).length !== 0 && Object.keys(programmeDatas.pagesDetails.interieurs.temoins).length !== 0) &&
                            <VerticalSwitch
                                initialState={pathname.includes('temoins')}
                                onChange={() => { navigate(`/${programmeDatas.urlName}/interieurs/${pathname.includes('temoins') ? `pano/${Object.keys(programmeDatas.pagesDetails.interieurs.pano)[0]}${search}` : `temoins${search}`}`) }}
                                top={{ icon: 'visites', text: programmeDatas.languagesDatas[lang].interieurs.toggle.visite }}
                                bottom={{ icon: 'plan_3d', text: programmeDatas.languagesDatas[lang].interieurs.toggle.temoin }}
                                isAbsolute={false}
                            />
                        }</>
                }

                {noHeader &&
                    <div id='noHeaderFullScreen'>
                        <FullScreen />
                    </div>}


                <Routes>
                    <Route path="pano/:currentInt" element={<PanoNavigation />} />
                    <Route path="temoins/*" element={<TemoinNavigation />} />
                </Routes>
            </div>

            <Routes>
                <Route path="pano/:currentInt" element={<PanoContent />} />
                <Route path="temoins/:currentInt" element={<Temoin loadHdForFrame={loadHdForFrame} />} />
            </Routes>

            {programmeDatas.pagesDetails.interieurs.annotationText && <Annotation text={programmeDatas.pagesDetails.interieurs.annotationText} leftoffset={50} ></Annotation>}
        </div>
    )
}

export default Interieurs