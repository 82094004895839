// ////////////////////////////////////////////////////////////
// Component de gestions des toogles de contenu (non header)
// ////////////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ////////////////////////////////////////////////////////////


// //////////////////////////
// Imports

// Imports system
import React, { useEffect, useState } from 'react';
// Imports components
import ButtonLong from '../button/buttonLong';


// ///////////////////////////
// Component
const Toogle = ({list, current, setCurrent, from = "text"}) => {

    // properties
    const [toogleContent, setToogleContent] = useState(null)

    useEffect(() => {
        setToogleContent(
            list.map(btn => 
                <ButtonLong icon={btn.icon} text={btn.text} state={current} action = {() => {setCurrent(btn[from])}}/>
            )
        )
    }, [list, current])

    return (
        <div className='toogle'>
            {toogleContent}
        </div>
    )
}

export default Toogle;
