// ///////////////////////////////////////////////////////
// Composant pour la gestion de la page les exterieurs
// ///////////////////////////////////////////////////////
// Creation : 25/07/2022    -> CGR
// ///////////////////////////////////////////////////////

// ///////////////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useRef, useState } from "react";
import useKrpano from "react-krpano-hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SvgIcon from "./../../components/svg/svg";

// Import context
import {
  languageContext,
  noHeaderContext,
  pageContext,
  programmeContext,
  programmeNameContext,
} from "./../../contexts";

// Imports components
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Annotation from "../../components/annotation/annotation";
import Button from "../../components/button/button";
import LinkedButton from "../../components/button/linkedButton";
import Toogle from "../../components/toggles/toggle";

// ///////////////////////////////////
// Component
const Pano = ({
  viewName,
  view,
  variante,
  count,
  pois = [],
  setDirection,
  setInitialDir,
}) => {
  // Partie krPano

  // /////////////////////////////
  // Properties
  const lang = useContext(languageContext);
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const [hotspots, setHotspots] = useState([]);
  const [positionMemory, setPositionMemory] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const updateType = useRef("view");
  const [canUseGyro, setCanUseGyro] = useState(false);
  const [gyro, setGyro] = useState(false);
  const [cardBoard, setCardBoard] = useState(false);
  const { containerRef, callKrpano, krpanoState } = useKrpano({
    scriptPath: `/public/sources/krPanoBuilder/viewer/krpano.js`,
    embeddingParams: {
      swf: `/public/programmes/${programmeName}/exterieurs/tour.swf`,
      xml: `/public/programmes/${programmeName}/exterieurs/tour.xml`,
      target: "react-krpano",
      html: "prefer",
    },
  });

  // /////////////////////////////
  // Functions

  const setKrDatas = () => {
    callKrpano(
      `set(view.fov, ${programmeDatas.pagesDetails.exterieurs.views[viewName].fov});`
    );
    callKrpano(
      `set(view.hlookat, ${programmeDatas.pagesDetails.exterieurs.views[viewName].xStart});`
    );
    callKrpano(
      `set(view.vlookat, ${programmeDatas.pagesDetails.exterieurs.views[viewName].yStart});`
    );
    callKrpano(`set(view.limitview, range)`);
    callKrpano(
      `set(view.hlookatmin, ${programmeDatas.pagesDetails.exterieurs.views[viewName].ranges.xMin});`
    );
    callKrpano(
      `set(view.hlookatmax, ${programmeDatas.pagesDetails.exterieurs.views[viewName].ranges.xMax});`
    );
    callKrpano(
      `set(view.vlookatmin, ${programmeDatas.pagesDetails.exterieurs.views[viewName].ranges.yMin});`
    );
    callKrpano(
      `set(view.vlookatmax, ${programmeDatas.pagesDetails.exterieurs.views[viewName].ranges.yMax});`
    );

    setKrPoi();
  };

  const setKrPoi = () => {
    for (let spot of hotspots) {
      callKrpano(`removehotspot(${spot});`);
    }
    // On vide la memoire
    setHotspots([]);
    setTimeout(() => {
      // Gestion des poi
      for (const poi of pois) {
        callKrpano(`addhotspot(poi_${poi.id}_ctn);`);
        callKrpano(`hotspot[poi_${poi.id}_ctn].loadstyle(skin-direction);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].ath, ${poi.x});`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].atv, ${poi.y});`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].zoom,false);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].distorted, false);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].scale, 0.8);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].width, 60);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].height, 60);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].x, 0);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].y, 8);`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].onclick, js(window.somVar(${poi.link})));`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].onhover, tween(scale, 1.2, 0.15); tween(hotspot[poi_${poi.id}_svg].scale, 0.7, 0.15); tween(hotspot[poi_${poi.id}_svg].y, -35, 0.15); tween(hotspot[poi_${poi.id}_txt].y, 62, 0.15);)`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].onout, tween(scale, 0.8, 0.15); tween(hotspot[poi_${poi.id}_svg].scale, 0.4, 0.15); tween(hotspot[poi_${poi.id}_svg].y, -13, 0.15); tween(hotspot[poi_${poi.id}_txt].y, 50, 0.15);)`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].bgcolor, 0x${poi.color.slice(1)});`);
        callKrpano(`set(hotspot[poi_${poi.id}_ctn].bgroundedge, 30;`);

        callKrpano(`addhotspot(poi_${poi.id}_txt);`);
        callKrpano(`hotspot[poi_${poi.id}_txt].loadstyle(skin-direction-text);`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].ath, ${poi.x});`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].atv, ${poi.y});`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].height, 120);`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].textalign, centerbottom);`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].bg, false);`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].zoom,false);`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].html, ${poi.text[programmeDatas.languages.findIndex((x) => x === lang)]});`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].distorted, false);`);
        callKrpano( `set(hotspot[poi_${poi.id}_txt].onclick, js(window.somVar(${poi.link})));`);
        callKrpano(`set(hotspot[poi_${poi.id}_txt].css, "overflow : visible; color: black; font-size: 20px; font-family: BarlowCondensed-SemiBold; width:auto; border-radius:4px; pointer-events:none!important; ";`);

        callKrpano(`addhotspot(poi_${poi.id}_svg);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].ath, ${poi.x});`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].atv, ${poi.y});`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].x, 80;`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].y, -13;`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].distorted,false);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].zorder, 10);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].scale, 0.4);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].edge, center);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].url, /public/icons/VIM/${poi.icon}.svg);`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].onhover, tween(hotspot[poi_${poi.id}_ctn].scale, 1.2, 0.15); tween(scale, 0.7, 0.15); tween(y, -35, 0.15); tween(hotspot[poi_${poi.id}_txt].height, 140, 0.15);)`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].onout, tween(hotspot[poi_${poi.id}_ctn].scale, 0.8, 0.15); tween(scale, 0.4, 0.15); tween(y, -13, 0.15); tween(hotspot[poi_${poi.id}_txt].height, 120, 0.15);)`);
        callKrpano(`set(hotspot[poi_${poi.id}_svg].onclick, js(window.somVar(${poi.link})));`);

        // Ajout du hotspot en memoire
        setHotspots((old) => [...old, `poi_${poi.id}`]);
      }
    }, 2);
  };

  // /////////////////////////////
  // Effects
  useEffect(() => {

    // variable global pour effet krPano
    window.endVr = () => {
        setCardBoard(false)
        setGyro(false)
    }

    setCanUseGyro(navigator.platform !== "Win32")
    // const detectGyro = (e) => {
    //     if(e.alpha || e.beta || e.gamma) {setCanUseGyro(true)}
    //     else {setCanUseGyro(false)}
    // }

    // window.addEventListener("deviceorientation", detectGyro, true)

}, [])

  useEffect(() => {
    if (containerRef.current !== undefined) {
      if (containerRef.current.children.length === 2) {
        containerRef.current.removeChild(containerRef.current.children[0]);
      }
    }
  }, [containerRef, view]);

  useEffect(() => {
    if (variante) {
      // au changement de décoration / prestation
      const kr = containerRef.current.children[0];
      if (kr) {
        for (let spot of hotspots) {
          callKrpano(`removehotspot(${spot});`);
        }

        const posMem = [
          kr.get("view.hlookat"),
          kr.get("view.vlookat"),
          kr.get("view.camroll"),
        ];
        updateType.current = "variante";
        setForceUpdate((l) => l + 1);
        setPositionMemory(posMem);
        setKrPoi();
      }
    }
  }, [variante]);

  useEffect(() => {
    if (krpanoState.isEmbedded && view && view !== "") {
      for (let spot of hotspots) {
        callKrpano(`removehotspot(${spot});`);
      }
      // On vide la memoire
      setHotspots([]);

      callKrpano(
        `loadscene('${
          count > 1 ? "scene_" : ""
        }ext${view}-${variante}', null, MERGE, BLEND(0.4));`
      );
      if (
        krpanoState.isEmbedded &&
        programmeDatas &&
        viewName &&
        viewName !== ""
      ) {
        setKrDatas();
      }
    }
  }, [krpanoState.isEmbedded, view, variante, viewName, pois]);

  useEffect(() => {
    // if (updateType.current === "room") {
    const update = () =>
      setTimeout(() => {
        const kr = containerRef.current.children[0];
        if (kr) {
          setKrDatas();
          const hlookat = kr.get("view.hlookat");
          const camroll = kr.get("view.camroll");
          setInitialDir(camroll + hlookat);
        } else {
          update();
        }
      }, 50);

    update();
    // }
  }, [view]);

  useEffect(() => {
    // Gestion de la direction de la puce minimap en live

    let int;
    if (krpanoState.isEmbedded) {
      int = setInterval(() => {
        const kr = containerRef.current.children[0];
        if (kr) {
          const hlookat = kr.get("view.hlookat");
          const camroll = kr.get("view.camroll");
          setDirection(camroll + hlookat);
        }
      }, 100);
    }
    return () => clearInterval(int);
  }, [krpanoState.isEmbedded]);

  useEffect(() => {
    if (positionMemory) {
      const kr = containerRef.current.children[0];

      kr.set("view.hlookat", positionMemory[0]);
      kr.set("view.vlookat", positionMemory[1]);
      kr.set("view.camroll", positionMemory[2]);
    }
  }, [positionMemory]);

  useEffect(() => {
    const kr = containerRef.current.children[0]

    if (kr) {
        if (cardBoard) { kr.call("webvr.enterVR();") } 
        else  { 
            kr.call("webvr.exitVR();") 
        }
    }
}, [cardBoard])

useEffect(() => {
    const kr = containerRef.current.children[0]

    if (kr) {
        if (gyro) { kr.set("plugin[skin_gyro].enabled", 'true') } 
        else  { kr.set("plugin[skin_gyro].enabled", 'false') }
    }
}, [gyro])

  // /////////////////////////////
  // Render
  return (
    <div id="pano">
      <div
        ref={containerRef}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />

      {(programmeDatas.useVRforKr && canUseGyro) &&
        <div id = "gyro">

            <button className = {`no-hover ${gyro ? "mainBackground" : "inactive"}`} onClick = {() => setGyro(g => {
                if (g) {setCardBoard(false); return !g}
                else {return !g}
            })}>
                <SvgIcon icon = "gyroscope" />
            </button>     

            <button className = {`no-hover ${cardBoard ? "mainBackground" : "inactive"}`} onClick = {() => setCardBoard(c => {
                if (c) {return !c}
                // else {setGyro(true); return !c}
            })}>
                <SvgIcon icon = "cardboard" />
            </button>
        </div>
      }
    </div>
  );
};

const Exterieurs = ({ fromMaquette = false, initialView = null }) => {
  // Properties
  const { search } = useLocation();
  const noHeader = useContext(noHeaderContext);
  const { vue, variante } = useParams();
  const [extList, setExtList] = useState([]);
  const { setCurrent, setHeaderLevel } = useContext(pageContext);
  const lang = useContext(languageContext);
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const exterieursDatas = programmeDatas.pagesDetails.exterieurs;
  const [currentExt, setCurrentExt] = useState(vue ? vue : "");
  const [currentVar, setCurrentVar] = useState(
    variante
      ? variante
      : programmeDatas.pagesDetails.exterieurs.variantes[0].split("|")[0]
  );
  const [annotation, setAnnotation] = useState();
  const [miniMapStyle, setMiniMapStyle] = useState("normal");
  const miniMapSize =
    miniMapStyle === "normal" ? 320 : document.body.clientHeight;
  const [direction, setDirection] = useState(0);
  const [initialDir, setInitialDir] = useState(1000);
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    if (programmeName && programmeDatas) {
      if (!fromMaquette) {
        setCurrent(`exterieurs`);
        setHeaderLevel(1);
      }

      let list = [];
      for (let ext of programmeDatas.pagesDetails.exterieurs.viewsOrder) {
        list.push({
          icon: null,
          text: programmeDatas.pagesDetails.exterieurs.views[ext].titleLangs[
            programmeDatas.languages.findIndex((x) => x === lang)
          ],
          direction: ext,
          displayInUi:
            programmeDatas.pagesDetails.exterieurs.views[ext].displayInUi,
        });
      }

      if (list.length != 0) {
        if (vue) {
          setCurrentExt(vue);
        } else if (initialView) {
          setCurrentExt(initialView);
        } else {
          setCurrentExt(list[0].text);
        }
      }
      setExtList(list);
      setAnnotation(programmeDatas.pagesDetails.exterieurs.annotationText);
    }
  }, [programmeName, programmeDatas]);

  useEffect(() => {
    if (programmeDatas) {
      window.somVar = (name) => {
        const to = extList.filter((datas) => datas.text === name)[0];
        setCurrentExt(to.text);
        if (!initialView) {
          navigate(
            `/${programmeDatas.urlName}/exterieurs/${to.text}/${currentVar}${search}`
          );
        }
      };
    }
  }, [programmeDatas, extList, currentVar]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const x = e.x;
      const y = e.y;
      const width = document.body.clientWidth;
      const height = document.body.clientHeight;

      if (height > 599) {
        if (miniMapStyle === "normal") {
          if (width - x > 40 && width - x < 360 && y > 10 && y < 330) {
            setMiniMapStyle("full");
          }
        } else {
          if (x > (width - height) / 2 && x < width - (width - height) / 2) {
            setMiniMapStyle("normal");
          }
        }
      } else {
        if (miniMapStyle === "normal") {
          if (width - x > 29 && width - x < 172 && y > 28 && y < 172) {
            setMiniMapStyle("full");
          }
        } else {
          if (x > (width - height) / 2 && x < width - (width - height) / 2) {
            setMiniMapStyle("normal");
          }
        }
      }
    });

    //
    document.addEventListener("touchend", (e) => {
      const x = e.x;
      const y = e.y;
      const width = document.body.clientWidth;
      const height = document.body.clientHeight;

      if (height > 599) {
        if (miniMapStyle === "normal") {
          if (width - x > 40 && width - x < 360 && y > 10 && y < 330) {
            setMiniMapStyle("full");
          }
        } else {
          if (x > (width - height) / 2 && x < width - (width - height) / 2) {
            setMiniMapStyle("normal");
          }
        }
      } else {
        if (miniMapStyle === "normal") {
          if (width - x > 29 && width - x < 172 && y > 28 && y < 172) {
            setMiniMapStyle("full");
          }
        } else {
          if (x > (width - height) / 2 && x < width - (width - height) / 2) {
            setMiniMapStyle("normal");
          }
        }
      }
    });
  }, [miniMapStyle]);

  return (
    <div id="exterieurs">
      {programmeDatas.pagesDetails.exterieurs.variantes.length !== 1 && (
        <div id="exterieursControllers">
          {!initialView ? (
            <>
              {programmeDatas.pagesDetails.exterieurs.variantes.map((v, e) => (
                <LinkedButton
                  route={`/${programmeDatas.urlName}/exterieurs/${currentExt}/${
                    v.split("|")[0]
                  }${search}`}
                  icon={v.split("|")[1]}
                  text={
                    programmeDatas.pagesDetails.exterieurs.variantesLanguages[
                      programmeDatas.languages.findIndex((x) => x === lang)
                    ][e]
                  }
                  state={currentVar === v.split("|")[0]}
                  action={() => {
                    setCurrentVar(v.split("|")[0]);
                  }}
                  displayText={programmeDatas.displayText}
                ></LinkedButton>
              ))}
            </>
          ) : (
            <>
              {programmeDatas.pagesDetails.exterieurs.variantes.map((v, e) => (
                <Button
                  icon={v.split("|")[1]}
                  text={
                    programmeDatas.pagesDetails.exterieurs.variantesLanguages[
                      programmeDatas.languages.findIndex((x) => x === lang)
                    ][e]
                  }
                  state={currentVar === v.split("|")[0]}
                  action={() => {
                    setCurrentVar(v.split("|")[0]);
                  }}
                  displayText={programmeDatas.displayText}
                ></Button>
              ))}
            </>
          )}
        </div>
      )}
      {/* Navigation */}
      {!fromMaquette && (
        <div
          id={`exterieursNavigation`}
          className={
            programmeDatas.pagesDetails.exterieurs.variantes.length === 1 ||
            noHeader
              ? ""
              : "novars"
          }
        >
          <Toogle
            list={[...extList].filter((v) => v.displayInUi)}
            current={
              programmeDatas.pagesDetails.exterieurs.views[currentExt]
                ?.titleLangs[
                programmeDatas.languages.findIndex((x) => x === lang)
              ]
            }
            setCurrent={(e) => {
              if (!initialView) {
                navigate(
                  `/${programmeDatas.urlName}/exterieurs/${e}/${currentVar}${search}`
                );
              }
              setCurrentExt(e);
            }}
            from="direction"
          />
        </div>
      )}

      {/* Affichage */}
      {currentExt && currentVar && (
        <>
          <Pano
            viewName={currentExt}
            view={programmeDatas.pagesDetails.exterieurs.views[currentExt].id}
            variante={currentVar}
            count={
              Object.keys(
                programmeDatas.pagesDetails.exterieurs.views[currentExt]
              ).length
            }
            pois={programmeDatas.pagesDetails.exterieurs.views[currentExt].pois}
            setDirection={setDirection}
            setInitialDir={setInitialDir}
          />

          {exterieursDatas.useMinimap && (
            <div id="panoMiniMap" className={miniMapStyle}>
              <img
                src={`/public/programmes/${programmeName}/exterieurs/minimap${currentVar}.png`}
              ></img>
              {exterieursDatas.miniMapPois.map((piece) => (
                <div
                  className={`minimapButton ${
                    exterieursDatas.views[currentExt].title === piece.direction
                      ? "activRoom"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!initialView) {
                      navigate(
                        `/${programmeDatas.urlName}/exterieurs/${piece.direction}/${currentVar}${search}`
                      );
                    }
                    setCurrentExt(piece.direction);
                    setMiniMapStyle("normal");
                  }}
                  style={{
                    left: `${(miniMapSize * piece.x) / 100}px`,
                    top: `${(miniMapSize * piece.y) / 100}px`,
                  }}
                >
                  {exterieursDatas.views[currentExt].title ===
                    piece.direction && (
                    <div
                      className="visioCone"
                      style={{
                        rotate: `${piece.offset - initialDir + direction}deg`,
                      }}
                    >
                      <svg
                        width="82"
                        height="82"
                        style={{
                          position: "absolute",
                          left: "-16px",
                          top: "-16px",
                          pointerEvents: "none",
                        }}
                      >
                        <path
                          stroke={programmeDatas.color}
                          stroke-width="1"
                          stroke-opacity="0.45"
                          fill={programmeDatas.color}
                          fill-opacity="0.45"
                          d="M 40.5,40.5 L 26.894271311943584,77.04974893028677 A 39,39 0 0 1 15.649853268456681,10.442218854001098 Z"
                          style={{
                            cursor: "pointer",
                            transform: "scale(1, 1)",
                            pointerEvents: "none",
                          }}
                        ></path>
                      </svg>
                    </div>
                  )}
                  <FontAwesomeIcon icon={faEye} />
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {annotation && (
        <Annotation text={annotation} leftoffset={50}></Annotation>
      )}
    </div>
  );
};

export default Exterieurs;
