// ///////////////////////////////////////////////////////
// Composant pour la gestion des cartes OSM
// ///////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// ///////////////////////////////////////////////////////

// Imports system
import React, { useState, useEffect, useContext } from 'react';
import Map from "react-map-gl";
import { programmeContext} from '../../../contexts';

// Hooks - Custom
import usePOIs from '../hooks/usePOIs';

// Components
const Carto = ({}) => {

    // Properties
    const programmeData = useContext(programmeContext)
    const [viewState, setViewState] = React.useState({
        latitude: programmeData.pagesDetails.localisation.map.project.x,
        longitude: programmeData.pagesDetails.localisation.map.project.y,
        zoom: programmeData.pagesDetails.localisation.map.project.zoom,
        maxZoom : programmeData.pagesDetails.localisation.map.project.zoom+1,
        minZoom : programmeData.pagesDetails.localisation.map.project.zoom-2,
        maxBounds : [
            programmeData.pagesDetails.localisation.map.project.y - 1.2, 
            programmeData.pagesDetails.localisation.map.project.x - 0.5, 
            programmeData.pagesDetails.localisation.map.project.y + 1.2, 
            programmeData.pagesDetails.localisation.map.project.x + 0.5, 
        ],
        width: "100%",
        height: "100%"
    });

    //DESC: Infos des POI
    const [poiProject, setPoiProject] = usePOIs("project");
    const [sharedDatasPOIs, setSharedDatasPOIs] = usePOIs("sharedDatas");
    const [customPOIs, setCustomPOIs] = usePOIs("custom");
    
    // Effects
    useEffect(() => {
        if(programmeData){
            setPoiProject(programmeData.pagesDetails.localisation.map.pois.projectPOIs);
            setSharedDatasPOIs(programmeData.pagesDetails.localisation.map.pois.sharedDatasPOIs, programmeData.pagesDetails.localisation.map.project);
            setCustomPOIs(programmeData.pagesDetails.localisation.map.pois.customPOIs);
        }
    }, [programmeData]);

    // Render
    return (
        <div id = "carto">
            <Map 
                mapboxAccessToken='pk.eyJ1IjoidmlydHVhbGJ1aWxkaW5nIiwiYSI6ImNsMTB3bGRjYzAwdmYza3NwcjlqajN5d3oifQ.cC4t5l6fuXL7JJPaJkYzQg'
                mapStyle={'mapbox://styles/virtualbuilding/cl88kme2500bj14o6ot0r739m'}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
            >
                {sharedDatasPOIs}
                {customPOIs}
                {poiProject}
            </Map>
        </div>
    )
}


export default Carto;