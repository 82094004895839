import React, { useState } from 'react'
import SvgIcon from '../svg/svg'
import useMobile from '../../globalHooks/useMobile'

function VerticalSwitch({ initialState, onChange, top, bottom, isAbsolute = false }) {
  
  // Properties
  const isMobile = useMobile()
  const [isSwitch, setIsSwitch] = useState(initialState)


  // Functions
  function handleSwitchChange(event) {
    setIsSwitch(event.target.checked)
    onChange(event.target.checked)
  }


  // Render
  return (

    
    <div className = 'switch-container' style = {{ 'position': isAbsolute ? 'absolute' : "relative" }}>

      <input onChange = {handleSwitchChange} defaultChecked = {isSwitch} className = 'switch-vertical' type = 'checkbox' />
      
      <div className = 'switch-top-bottom'>
        <SvgIcon icon = {isSwitch ? bottom.icon : top.icon} onChange = {onChange} />
        <p>{isSwitch ? bottom.text : top.text}</p>
      </div>
      
      <div className = 'switch-bottom-top' style = {isSwitch ? { top: '0' } : { bottom: '0' }}>
        {!isMobile ?
          <SvgIcon icon = {isSwitch ? top.icon : bottom.icon} onChange = {onChange} />
            :
          <p style={ isMobile ? {
            transform: isSwitch ? 'translateY(-13px) ' : 'translateY(13px)',
          } : null }>{isSwitch ? top.text : bottom.text}</p>
        }
      </div>
    </div>
  )
}


export default VerticalSwitch;