// /////////////////////////////////////////////////////////////
// Sous Fichier pour la gestion des routes de la maquette 3D
// /////////////////////////////////////////////////////////////
// Creation : 09/09/2022    -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

// Imports src
import Maquette from '../../../pages/maquette/maquette';
import Page404 from '../../../pages/page404/page404';




///////////////////////////////////
// Component

const MaquetteRouter = ({preloader, loadHdForFrame}) => { // Routes pour le moteur de recherche

    // Render
    return (
        <Routes>
            <Route path= '' element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
            <Route path= ':urlPage'>
                <Route path= {`:urlBlock/:urlFloor/frame=:urlFrame/lot=:urlLot`} element = {<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
                <Route path= {`:urlBlock/:urlFloor/frame=:urlFrame/lot=:urlLot/*`} element = {<Page404 />}></Route>

                <Route path= {`:urlBlock/:urlFloor/lot=:urlLot/frame=:urlFrame`} element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
                <Route path= {`:urlBlock/:urlFloor/lot=:urlLot`} element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
                <Route path= {`:urlBlock/:urlFloor/lot=:urlLot/*`} element = {<Page404 />}></Route>
                
                <Route path= {`:urlBlock/:urlFloor/frame=:urlFrame`} element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
                <Route path= {`:urlBlock/:urlFloor/frame=:urlFrame/*`} element = {<Page404 />}></Route>

                <Route path= {`:urlBlock/:urlFloor`} element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
                <Route path= '' element={<Maquette preloaded = {preloader} loadHdForFrame = {loadHdForFrame} />}></Route>
            </Route>
        </Routes>
    )
}


// ///////////////////////////////////
// Export
export default MaquetteRouter;