// ///////////////////////////////////////////////////////////////////////////////
// Composant pour la gestion de la navigation de la page programme --> toogleMenu
// ///////////////////////////////////////////////////////////////////////////////
// Creation 29/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////

// Imports system
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

// Import context
import {
  languageContext,
  noHeaderContext,
  programmeContext,
} from "../../../contexts";

// Imports src
import Button from "../../../components/button/button";

const MaquetteNavigation = ({
  current,
  setCurrent,
  floor,
  setFLoor,
  specialView,
  setSpecialView,
  displaySearch = true,
  useNight = false,
  useWinter = false,
  useSummer = false,
}) => {
  const noHeader = useContext(noHeaderContext);
  const loc = useLocation();
  const lang = useContext(languageContext);

  return (
    <programmeContext.Consumer>
      {(value) =>
        value != null && (
          <div id="programmeToggle">
            <Button
              icon="etages"
              text={value.languagesDatas[lang].maquette.header.etages}
              state={current === "palettes"}
              action={() => {
                setSpecialView("");
                setCurrent("palettes");
              }}
              displayText={value.displayText}
            />

            {displaySearch && (
              <>
                <Button
                  icon="recherche"
                  text={value.languagesDatas[lang].maquette.header.rechercher}
                  state={current === "rechercher"}
                  action={() => {
                    setSpecialView("");
                    setCurrent("rechercher");
                  }}
                  displayText={value.displayText}
                />
              </>
            )}

            {useNight && (
              <Button
                icon="jourNuit"
                text={value.languagesDatas[lang].maquette.header.nuit}
                state={current === "nuit"}
                action={() => {
                  if (floor !== "Aerien" && floor !== "Pieton") {
                    setFLoor("Aerien");
                  }
                  setCurrent("nuit");
                  setSpecialView("_nuit");
                }}
                displayText={value.displayText}
              />
            )}

            {useWinter && (
              <Button
                icon="hiver"
                text="vue Hiver"
                state={current === "hiver"}
                action={() => {
                  if (floor !== "Aerien" && floor !== "Pieton") {
                    setFLoor("Aerien");
                  }
                  setCurrent("hiver");
                  setSpecialView("_hiver");
                }}
                displayText={value.displayText}
              />
            )}

            {useSummer && (
              <Button
                icon="mode-ete"
                text="vue Été"
                state={current === "ete"}
                action={() => {
                  if (floor !== "Aerien" && floor !== "Pieton") {
                    setFLoor("Aerien");
                  }
                  setCurrent("ete");
                  setSpecialView("_ete");
                }}
                displayText={value.displayText}
              />
            )}

            {noHeader && value.pages.includes("comparer") && (
              <Button
                icon="favoris"
                text="comparer"
                state={current === "comparer"}
                action={() => {
                  setCurrent("comparer");
                }}
                displayText={value.displayText}
              />
            )}
          </div>
        )
      }
    </programmeContext.Consumer>
  );
};

export default MaquetteNavigation;
