// /////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page detail du lot (plan)
// /////////////////////////////////////////////////////////////
// Creation :       -> CGR
// /////////////////////////////////////////////////////////////

// //////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Import contexts
import {
  domain,
  languageContext,
  lotContext,
  noHeaderContext,
  pageContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
} from "../../contexts";
// Imports components
import Annotation from "../../components/annotation/annotation";
import Palette from "./../../components/palette/palette";
import Player from "./../../components/player/player";
import SvgIcon from "./../../components/svg/svg";

// //////////////////////////
// Components
const DetailLot = ({ loadLot, loadHdForFrame }) => {
  // Properties
  const { lotParam } = useParams();
  const { lotDatas, setLotDatas } = useContext(lotContext);
  const lotsInfos = useContext(programmeLotsContext);
  const programmeName = useContext(programmeNameContext);
  const noHeader = useContext(noHeaderContext);
  const lang = useContext(languageContext);
  const programmeDatas = useContext(programmeContext);
  const { setCurrent, setHeaderLevel } = useContext(pageContext);
  const [sup, setSup] = useState("");
  const [currentDisplay, setCurrentDisplay] = useState("3D");
  const [displayPrices, setDisplayPrices] = useState(false); // utiliser les filtres de prix
  const [source2D, setSource2D] = useState(""); // image 2D du lot
  const [floorList, setFloorList] = useState([]);
  const [currentFloor, setCurrentFloor] = useState("Niveau 0"); // gestion de l'etage
  const [annotationText, setAnnotationText] = useState("");

  // Effects
  useEffect(() => {
    if (programmeName && lotsInfos) {
      setCurrent(`detail-lot/${lotParam}/plan`); // maj du menu
      setHeaderLevel(2);

      fetch(`${domain}/${programmeName}/detail-lot`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setAnnotationText(data.annotationText);
        });

      // recuperation du lot
      const currentLot = lotsInfos.lots.find((x) => x.name === lotParam);

      if (currentLot) {
        setLotDatas(currentLot);
        setDisplayPrices(lotsInfos.infos.displayPrices);

        // Gestion du defaut pour le 2D/3D suivant le cas -> on force le 2D s'il n'y a pas de 3D
        if (currentLot.havePdf === true && currentLot.haveImages === false) {
          setCurrentDisplay("2D");
        }
        // Gestion de la source 2D
        if (currentLot.havePdf) {
          setSource2D(
            <embed
              src={`/public/programmes/${programmeName}/lots/${lotParam}/2D/${lotParam}.pdf#view=FitV&toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
            />
          );
        } else {
          setSource2D(
            <img id="lot-placeholder" src={`/public/images/soon.jpg`} />
          );
        }
        // Lancement du loader
        loadLot(
          `/public/programmes/${programmeName}/lots/${lotParam}/3D/`,
          lotParam
        );
      }
    }
  }, [programmeName, lotParam, lotsInfos]);

  useEffect(() => {
    // gestion du texte pour l'etage
    if (lotDatas != null) {
      if (lotDatas.etage) {
        setSup("");
      } else if (lotDatas.etage == 1) {
        setSup("er");
      } else {
        setSup("ème");
      }

      if (lotDatas.sysType.includes("Triplex")) {
        setFloorList(["Niveau 0", "Niveau 1", "Niveau 2"]);
      } else if (lotDatas.sysType.includes("Duplex")) {
        setFloorList(["Niveau 0", "Niveau 1"]);
      } else {
        setFloorList(["Niveau 0"]);
      }
    }
  }, [lotDatas]);

  useEffect(() => {
    const mainNavigation = document.getElementById("mainNavigation");
    if (mainNavigation && noHeader) {
      mainNavigation.style.display = "block";
    }
  }, []);

  const getLotDescription = (lotDatas, lang) => {
    // Utilisation de Number.isFinite pour une vérification plus robuste qu'une variable est un type numérique
    const isTypeNumer = Number.isFinite(lotDatas.type);

    if (lang === "fr" || !isTypeNumer) {
      return lotDatas.type;
    } else {
      return lotDatas.numPieces - 1;
    }
  };

  // Rendu
  return (
    <programmeNameContext.Consumer>
      {(programmeName) =>
        programmeName != null && (
          <lotContext.Consumer>
            {(lot) =>
              lot.lotDatas != null &&
              lotsInfos && (
                <div id="detail-lot">
                  <div id="detail-lot-infos">
                    {lotsInfos && lotsInfos.infos.ficheLot.displayType && (
                      <div id="infosLot-type" className="infosLot">
                        <p>
                          {
                            programmeDatas.languagesDatas[lang]["detail-lot"]
                              .page.type
                          }
                        </p>
                        <h3>{getLotDescription(lotDatas, lang)}</h3>
                      </div>
                    )}

                    {lotsInfos &&
                      lotsInfos.infos.ficheLot.displayFloor &&
                      lotDatas.etage != null && (
                        <div id="infosLot-etage" className="infosLot">
                          <p>
                            {
                              programmeDatas.languagesDatas[lang]["detail-lot"]
                                .page.etage
                            }
                          </p>
                          <h3>
                            {lotDatas.etage.replace(
                              "Etage",
                              programmeDatas.languagesDatas[lang].maquette
                                .palette.etages
                            )}
                            <sup>{sup}</sup>
                          </h3>
                        </div>
                      )}

                    {lotsInfos &&
                      lotsInfos.infos.ficheLot.displayTotalSurface && (
                        <div id="infosLot-surface" className="infosLot">
                          <p>
                            {
                              programmeDatas.languagesDatas[lang]["detail-lot"]
                                .page.surface
                            }
                          </p>
                          <h3>{lotDatas.surface}</h3>
                        </div>
                      )}

                    {lotDatas.pieces
                      .filter(
                        (p) =>
                          lotsInfos &&
                          lotsInfos.infos.ficheLot.surfacesInfos[p.name] &&
                          lotsInfos.infos.ficheLot.surfacesInfos[p.name].display
                      )
                      .map((p) => (
                        <div id="infosLot-surface" className="infosLot">
                          <p>{p.name} (m²)</p>
                          <h3>{p.surface}</h3>
                        </div>
                      ))}

                    {displayPrices == true &&
                      lotDatas.prix != undefined &&
                      lotDatas.prix != 0 && (
                        <div id="infosLot-prix" className="infosLot">
                          <p>Prix (€)</p>
                          <h3>
                            {lotDatas.prix != undefined &&
                              lotDatas.prix.toLocaleString()}
                          </h3>
                        </div>
                      )}

                    {/* Switch 2D/3D -> visible seulement si le lot a des images 2D et 3D*/}
                    {lotDatas.haveImages && lotDatas.havePdf && (
                      <div id="infosLot-switch">
                        <p
                          onClick={() => {
                            setCurrentDisplay("2D");
                          }}
                        >
                          2D
                        </p>
                        <p
                          onClick={() => {
                            setCurrentDisplay("3D");
                          }}
                        >
                          3D
                        </p>
                        <div
                          id="current-display"
                          className={`mainBackground lot-${currentDisplay}`}
                        >
                          <p>{currentDisplay}</p>
                        </div>
                      </div>
                    )}

                    {currentDisplay == "3D" && floorList.length > 1 && (
                      <div id="infosLot-palettes">
                        <Palette
                          list={[...floorList].reverse()}
                          current={currentFloor}
                          setCurrent={setCurrentFloor}
                        />
                      </div>
                    )}

                    {lotDatas.havePdf &&
                      lotsInfos.infos.ficheLot.displayDownload && (
                        <a
                          id="infosLot-download"
                          className="btnToggle"
                          href={`/public/programmes/${programmeName}/lots/${lotDatas.name}/2D/${lotDatas.name}.pdf`}
                          target="blank"
                          download
                        >
                          <SvgIcon icon="telecharger"></SvgIcon>
                          <p>
                            {
                              programmeDatas.languagesDatas[lang]["detail-lot"]
                                .page.telecharger
                            }
                          </p>

                          <div className="roomGo">
                            <SvgIcon icon="fleche-r"></SvgIcon>
                          </div>
                        </a>
                      )}
                  </div>

                  {currentDisplay == "2D" ? (
                    <div id="detail-lot-img">{source2D}</div>
                  ) : !lotDatas.isFixe ? (
                    <Player
                      programme={programmeName}
                      navUrl={`${lotDatas.name}/3D/Etage${floorList.indexOf(
                        currentFloor
                      )}`}
                      type="lots"
                      numFrames={30}
                      start={0}
                      maxResolution={1080}
                      loadHdForFrame={loadHdForFrame}
                    />
                  ) : (
                    <img
                      id="lot-img"
                      src={`/public/programmes/${programmeName}/lots/${
                        lotDatas.name
                      }/3D/Etage${floorList.indexOf(
                        currentFloor
                      )}/jpg-1080/000.jpg`}
                    />
                  )}

                  {
                    // 200 = marge des détails (280) - dépassement du player (80)
                    annotationText != "" && currentDisplay == "3D" && (
                      <Annotation
                        text={annotationText}
                        leftoffset={200}
                      ></Annotation>
                    )
                  }
                </div>
              )
            }
          </lotContext.Consumer>
        )
      }
    </programmeNameContext.Consumer>
  );
};

export default DetailLot;
