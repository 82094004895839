// ///////////////////////////////////////////////////////
// Contexts
// ///////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ///////////////////////////////////////////////////////

import React from "react";

export const state = "prod"; // prod || preprod
export const domain =
  state === "prod"
    ? "https://api.virtualbuilding.fr"
    : "http://moteur-v6-backend"; // Domaine a utiliser pour le front

export const programmeNameContext = React.createContext(null); // Nom du programme a visiter

export const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const languageContext = React.createContext("fr");

export const programmeContext = React.createContext(null); // Contexte pour donnees projet

export const pagesContext = React.createContext(null); // Memoire des contenus de page avec medias

export const maquetteContext = React.createContext(null); // Conexte pour maquette 3D

export const jsonContext = React.createContext(null); // Contexte pour media json

export const programmeLotsContext = React.createContext(null); // Contexte pour la recuperation des lots du programme

export const pageContext = React.createContext({
  headerLevel: 0,
  setHeaderLevel: (headerLevel) => {},
  current: false,
  setCurrent: (current) => {},
}); // Contexte pour la page active

export const returnToContext = React.createContext({
  returnTo: "programme",
  setReturnTo: (to) => {},
}); // Contexte pour le retour depuis detailLot

export const lotContext = React.createContext({
  lotDatas: null,
  setLotDatas: (lot) => {},
}); // Contexte pour le lot en cours de visite

export const favorisContext = React.createContext({
  favs: null,
  setFavs: (favs) => {},
}); // Contexte pour la gestions des lots en favoris

export const comparerContext = React.createContext({
  currentLotA: null,
  setCurrentLotA: null,
  currentLotB: null,
  setCurrentLotB: null,
  draggingOne: null,
  setDraggingOne: null,
}); // Contexte pour la selection des lots a comparer

export const noHeaderContext = React.createContext(false); // Utilisation du noHeader

export const miniMoteurContext = React.createContext({
  filter: "Résidence",
  setFilter: (filter) => {},
  pieces: [],
  setPieces: (pieces) => {},
  options: null,
  setOptions: (options) => {},
  surface: 0,
  setSurface: (surface) => {},
  label: "Résidence",
  setLabel: (label) => {},
}); // Memoire de la recherche maquette

export const contactContext = React.createContext({
  contactFormDisplay: false,
  setContactFormDisplay: () => {},
});

export const maquetteLoaderContext = React.createContext({
  loadingDatas: {
    maquette: {},
    lots: {},
  },
  setLoadingDatas: (d) => {},
});
