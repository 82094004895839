// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour l'affichage de la fenetre de comparaison entre 2 lots
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 08/09/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useState, useContext, useEffect } from 'react';
import { comparerContext, programmeNameContext, programmeLotsContext, programmeContext, languageContext } from '../../../../contexts';
// import useMobile from '../../../../globalHooks/useMobile';


// ////////////////////////////////////////////////////////////////////////////////
// Components

const DragZone = ({
    side,
    display,
    setter,
    isMobile
}) => {


    // Properties
    const programmeName = useContext(programmeNameContext)
    const programmeDatas = useContext(programmeContext)
    const lang = useContext(languageContext)
    const lotsDatas = useContext(programmeLotsContext)
    const [currentDisplay, setCurrentDisplay] = useState("2D");

    useEffect(() => {
        if (display && display.haveVignette) {
          setCurrentDisplay("3D");
        } else {
          setCurrentDisplay("2D");
        }
      }, [display]);

    // Functions
    const handleDrop = (e, draggingOne, setDraggingOne) => { // Fonction au lache d'un favoris sur la zone de drop
        e.preventDefault();

        if (draggingOne) {
            // recuperation des donnees du lot
            if (lotsDatas) {
                setter(lotsDatas.lots.find(x => x.name === draggingOne));
                setDraggingOne(null);
            }
        }
    }

    const displayImg3D = () => { // Gestion de l'affichage de la vignette
        if (display.haveVignette) {
            return `/public/programmes/${programmeName}/lots/${display.name}/vignette/vignette.png`;
        } else {
            return `/public/images/T${display.numPieces}.png`;
        }
    }

    const displayImg2D = () => { // Gestion de l'affichage du plan
        if (display.havePdf) {
            return <embed
                className="compImage"
                src={`/public/programmes/${programmeName}/lots/${display.name}/2D/${display.name}.pdf#view=FitV&toolbar=0&navpanes=0&scrollbar=0`}
                type='application/pdf'
            />
        } else {
            return <img className="compImage" src={`/public/images/T${display.numPieces}.png`} />
        }
    }

    // Render
    return (
        <programmeNameContext.Consumer>{programmeName => programmeName !== null &&
            <comparerContext.Consumer>
                {comparerLots =>
                    <div
                        className='compColumn'
                        id={`comp-${side}`}
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrop={(e) => handleDrop(
                            e,
                            comparerLots.draggingOne,
                            comparerLots.setDraggingOne
                        )}
                    >

                        {display === null ?
                            <p className='dropZone'>{programmeDatas.languagesDatas[lang].comparer.glisser.glisser}</p>
                            :
                            <div>
                                {!isMobile ?
                                    <div>
                                        <h3 className='compName'>{display.name}</h3>
                                        {/* Affichage du visuel du lot */}
                                        <div className='compImage-div'>
                                            {currentDisplay === "3D" ?
                                                <img alt='typologie du lot' className="compImage" src={displayImg3D()}></img>
                                                :
                                                displayImg2D()
                                            }
                                        </div>

                                        {/* Toggle 2D/3D */}
                                        {(display.haveVignette && display.havePdf) &&
                                            <div id='infosLot-switch'>
                                                <p onClick={() => { setCurrentDisplay('2D') }}>2D</p>
                                                <p onClick={() => { setCurrentDisplay('3D') }}>3D</p>
                                                <div id='current-display' className={`mainBackground lot-${currentDisplay}`}>
                                                    <p>{currentDisplay}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <h3 className='compName'>{display.name}</h3>
                                    </div>}

                            </div>
                        }
                    </div>
                }</comparerContext.Consumer>
        }</programmeNameContext.Consumer>
    )
}

export default DragZone;