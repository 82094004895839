// ///////////////////////////////////////////////////////
// Fichier pour la gestion de la partie basse du menu
// ///////////////////////////////////////////////////////
// Creation : 12/08/2022    -> CGR
// ///////////////////////////////////////////////////////

// Imports system
import React, {useContext} from 'react';
import {programmeContext} from './../../../contexts';
// Imports Contexts


// Import Components
import FullScreen from '../../fullScreen/fullScreen';


// Component
const MainBottomNavigation = ({logoPromoteur}) => {

    const contextDatas = useContext(programmeContext);
    // rendu
    return (
        <div id = 'mainBottom'>
            <FullScreen />
            {logoPromoteur !== null && contextDatas.displayClientLogo &&
                <img  alt='Logo client' id = 'logoPromoteur' src = {`/public/clients/${logoPromoteur}`} />
            }
        </div>
    )
}

// Export
export default MainBottomNavigation;
