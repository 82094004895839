// /////////////////////////////////////////////////////////////////
// Composant pour la gestion d'une image du carrousel de la galerie
// /////////////////////////////////////////////////////////////////
// Creation : 30/04/2022    -> CGR
// Maj : 08/08/2022 -> separation du fichier
// /////////////////////////////////////////////////////////////////


// Imports system
import React, {useState, useEffect, useRef} from 'react';
import SvgIcon from './../../../components/svg/svg'


// Component
const CarrouselMedia = ({src, position, index, setIndex, type}) => { // Composant pour un element du carrousel

    // Properties
    const [isActiv, setIsActiv] = useState('');
    const olreadyChecked = useRef(false);

    // Effects
    useEffect(() => { // recuperation du ratio de l'image pour connaitre l'ajustement css a effectuer

        if (olreadyChecked.current) {return;}
        olreadyChecked.current = true;
        
        const imgRef = document.querySelector("#galerie-medias-container").children[position].children.length === 1 ? 
        document.querySelector("#galerie-medias-container").children[position].children[0] :
        document.querySelector("#galerie-medias-container").children[position].children[1];

        if (imgRef) {
            function getMeta(url, callback) {
                const img = new Image();
                img.src = url;
                img.onload = function() { callback(this.width, this.height); }
            }
            getMeta(src.props.src, (width, height) => {
                if (height/width > 0.6) {imgRef.className = 'fromHeight'}
                else {imgRef.className = 'fromWidth'}
            })
        }


    }, [src])

    useEffect(() => { // Etat du media (actif ou non) pour gestion du border
        setIsActiv(index === position ? 'mediaActive' : '')
    }, [index])

    // Rendu
    return (
        <div className = {`carrousel-media ${isActiv}`} onClick = {() => setIndex(position)}>
            {type == "video" && <div className = "mediaIcon" ><SvgIcon icon="movie"/></div>}
            {/*{type == "pdf" && <div className = "mediaIcon" ><SvgIcon icon="pdf"/></div>} */}
            {src}
            {/* <img className = {style} src = {src}/> */}
        </div>
    )
}

// Export
export default CarrouselMedia;
