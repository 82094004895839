// /////////////////////////////////////////////////////////////
// Sous Fichier pour la gestion des routes de la recherche
// /////////////////////////////////////////////////////////////
// Creation : 09/09/2022    -> CGR
// /////////////////////////////////////////////////////////////
// Modification : 18/04/2022    -> CGR   
//      - Add floor param for routes
// /////////////////////////////////////////////////////////////


// /////////////////////////////////////////////////////////////
// Note : 
//      - /!\ Important upgrade needed : optionnal params...
// ///////////////////////////////////////////////////////////// 


// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

// Imports src
import Rechercher from '../../../pages/rechercher/rechercher';
import Page404 from '../../../pages/page404/page404';




///////////////////////////////////
// Component

const RechercherRouter = () => { // Routes pour le moteur de recherche

    // Render
    return (
        <Routes>
            {/* Recherche sans criteres */}
            <Route path = '' element = {<Rechercher />}></Route>


            {/* Recherches a un critere */}
            <Route path = 'pmax=:prix' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax' element = {<Rechercher />}></Route>
            <Route path = 'tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'flrs=:floorSel' element = {<Rechercher />}></Route>


            {/* Recherches a deux critères */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/pmax=:prix' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'pmax=:prix/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'tp=:types/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'dir=:directions/flrs=:floorSel' element = {<Rechercher />}></Route>

            {/* Recherches a trois criteres */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smin=:surfaceMin/pmax=:prix/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smin=:surfaceMin/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/tp=:types/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smin=:surfaceMin/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/pmax=:prix/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/pmax=:prix/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/pmax=:prix/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/pmax=:prix/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/tp=:types/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'pmax=:prix/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/tp=:types/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'pmax=:prix/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>


            {/* Recherches a quatres criteres */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/pmax=:prix/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/tp=:types/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/tp=:types/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/pmax=:prix/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/tp=:types/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/tp=:types/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'pmax=:prix/tp=:types/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'pmax=:prix/tp=:types/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>


            {/* Recherches a cinq criteres */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = 'smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            {/* Recherches a six criteres */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts/dir=:directions' element = {<Rechercher />}></Route>
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts/flrs=:floorSel' element = {<Rechercher />}></Route>

            {/* Recherches tous criteres */}
            <Route path = 'smin=:surfaceMin/smax=:surfaceMax/pmax=:prix/tp=:types/op=:opts/dir=:directions/flrs=:floorSel' element = {<Rechercher />}></Route>

            <Route path = '*' element = {<Page404 />}></Route>
        </Routes>
    )
}


// ///////////////////////////////////
// Export
export default RechercherRouter;