// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du bouton de fullScreen
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 30/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React, { useState, useEffect, useContext } from 'react';

// Import components
import Button from '../../components/button/button';
import { noHeaderContext, programmeContext, iOS, languageContext } from '../../contexts';

// //////////////////////////////////
// Component
const FullScreen = () => {

    // Properties
    const [isFullScreen, setIsFullScreen] = useState(false);
    const noHeader = useContext(noHeaderContext);
    const lang = useContext(languageContext)
 
    useEffect(() => {

        const checkFullScreen = () => {
            const domElement = document.getElementById("moteur-v6");

            const wx = domElement.clientWidth;
            const wy = domElement.clientHeight;

            if (window.screen.width === wx && window.screen.height === wy) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        }
        
        window.addEventListener("resize", checkFullScreen)

        return () => {
            window.removeEventListener("resize", checkFullScreen)
        }

    }, [])

    // Fonctions
    const toogleFullScreen = () => { // Gestion du toogle de fullScreen
        
        const domElement = document.getElementById("moteur-v6");

        if (!isFullScreen) {
            if (domElement.requestFullscreen) {
                domElement.requestFullscreen();
            } else if (domElement.mozRequestFullScreen) {
                domElement.mozRequestFullScreen();
            } else if (domElement.webkitRequestFullscreen) {
                domElement.webkitRequestFullscreen();
            } else if (domElement.msRequestFullscreen) {
                domElement.msRequestFullscreen();
            } 
            setIsFullScreen(true);

        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } 
            setIsFullScreen(false);
        }
    }

    // Render
    return (
        <programmeContext.Consumer>
            {datas => (datas != null && !iOS()) &&
                
                    <div id = 'fullScreen' className = {noHeader ? 'noHeader' : ''}>
                        <Button 
                        icon = {'fullscreen'} 
                        text = {datas.languagesDatas[lang].header.fullscreen.fullscreen}
                        state = {isFullScreen} 
                        action = {() => toogleFullScreen()}
                        displayText = {datas.displayText}
                        />
                        {/* <p style={{'backgroundColor' : 'red'}}>std : {document.fullscreenEnabled ? "oui" : "non"}</p>
                        <p style={{'backgroundColor' : 'red'}}>moz :{document.mozFullScreenEnabled ? "oui" : "non"}</p>
                        <p style={{'backgroundColor' : 'red'}}>ms : {document.msFullscreenEnabled ? "oui" : "non"}</p>
                        <p style={{'backgroundColor' : 'red'}}>webKit : {document.webkitFullscreenEnabled ? "oui" : "non"}</p> */}
                    </div>
        }</programmeContext.Consumer>
    )
}

export default FullScreen;
