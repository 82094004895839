import React from "react";

const Loading = ({ text, subLine = "Veuillez patienter" }) => {
  return (
    <div id="loadingOverlay">
      <div id="programmeLoad">
        <div id="loader-circle"></div>
        <img src={`/public/images/logo-vb.jpg`} />
        <p>{text}</p>
        <p>{subLine}</p>
      </div>
    </div>
  );
};

export default Loading;
