// ///////////////////////////////////////////////////////
// Composant pour la gestion des altimetries
// ///////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// ///////////////////////////////////////////////////////

// Imports system
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';


// Imports src
import useKrpano from 'react-krpano-hooks'
import Palette from './../../components/palette/palette';
import {pageContext, programmeNameContext, programmeContext, noHeaderContext} from './../../contexts';
import Annotation from '../../components/annotation/annotation';
import SvgIcon from "./../../components/svg/svg";


const Altimetrie = () => { // Partie découvrir

    // /////////////////////////////
    // Properties
    const noHeader = useContext(noHeaderContext)
    const programmeName = useContext(programmeNameContext);
    const contextDatas = useContext(programmeContext);
    const {setCurrent, setHeaderLevel} = useContext(pageContext);
    const [paletteList, setPaletteList] = useState([]);
    const [currentVim, setCurrentVim] = useState(null) // kr actif
    const [positionMemory, setPositionMemory] = useState(null); // pour garder l'angle de vue au changement d'etage
    const [annotation, setAnnotation] = useState(null);
    const [canUseGyro, setCanUseGyro] = useState(false);
    const [gyro, setGyro] = useState(false);
    const [cardBoard, setCardBoard] = useState(false);

    const loc = useLocation();
    const { containerRef, krpanoState, callKrpano } = useKrpano({ // Ref krPano
        scriptPath : `/public/programmes/${programmeName}/altimetrie/tour.js`,
        embeddingParams : {swf:`/public/programmes/${programmeName}/altimetrie/tour.swf`, xml: `/public/programmes/${programmeName}/altimetrie/tour.xml`, target: 'react-krpano', html: 'prefer'},
        width : noHeader ? "100%" : "calc(100% - 100px)",
    })


    // /////////////////////////////
    // Fuctions 

    const setMemory = () => {
        const kr = containerRef.current.children[0];
        if (kr) {
            setPositionMemory([kr.get("view.hlookat"), kr.get("view.vlookat")]);
        }
    }

    // /////////////////////////////
    // Effects
    useEffect(() => {

        // variable global pour effet krPano
        window.endVr = () => {
            setCardBoard(false)
            setGyro(false)
        }
        
        setCanUseGyro(navigator.platform !== "Win32")
    }, [])


    useEffect(() => { // Page courante pour le menu
        if (contextDatas && !currentVim) {
            setCurrent("vues");
            setHeaderLevel(1);
            setPaletteList((contextDatas.pagesDetails.altimetrie.pano).map(o => o.name));
            setCurrentVim((contextDatas.pagesDetails.altimetrie.pano).map(o => o.name)[0]);
        }
        setAnnotation(contextDatas.pagesDetails.altimetrie?.annotationText)

    }, [contextDatas, currentVim])

    useEffect(() => { // switch kr actif

        if (currentVim) {

            const kr = containerRef.current.children[0];
            const correctVim = contextDatas.pagesDetails.altimetrie.pano.filter(o => o.name === currentVim)[0];

            if (kr != undefined) {  
                kr.call(`loadscene('${paletteList.length > 1 ? "scene_" : ""}${correctVim.scene}', null, MERGE, BLEND(0.5));`);

                if (positionMemory) {
                    callKrpano(`set(view.fov, 130)`);
                    callKrpano(`set(view.limitview, range)`);
                    callKrpano(`set(view.hlookat, ${correctVim.xStart})`);
                    callKrpano(`set(view.vlookat, ${correctVim.yStart})`);
                    callKrpano(`set(view.hlookatmin, ${correctVim.ranges.xMin})`);
                    callKrpano(`set(view.hlookatmax, ${correctVim.ranges.xMax})`);
                    callKrpano(`set(view.vlookatmin, ${correctVim.ranges.yMin})`);
                    callKrpano(`set(view.vlookatmax, ${correctVim.ranges.yMax})`);
                } else {
                    callKrpano(`set(view.fov, 130)`);
                    callKrpano(`set(view.limitview, range)`);
                    callKrpano(`set(view.hlookat, ${correctVim.xStart})`);
                    callKrpano(`set(view.vlookat, ${correctVim.yStart})`);
                    callKrpano(`set(view.hlookatmin, ${correctVim.ranges.xMin})`);
                    callKrpano(`set(view.hlookatmax, ${correctVim.ranges.xMax})`);
                    callKrpano(`set(view.vlookatmin, ${correctVim.ranges.yMin})`);
                    callKrpano(`set(view.vlookatmax, ${correctVim.ranges.yMax})`);
                }
                
            }
        }
    }, [krpanoState.isEmbedded, currentVim])


    useEffect(() => {
        const kr = containerRef.current.children[0]
    
        if (kr) {
            if (cardBoard) { kr.call("webvr.enterVR();") } 
            else  { 
                kr.call("webvr.exitVR();") 
            }
        }
    }, [cardBoard])
    
    useEffect(() => {
        const kr = containerRef.current.children[0]
    
        if (kr) {
            if (gyro) { kr.set("plugin[skin_gyro].enabled", 'true') } 
            else  { kr.set("plugin[skin_gyro].enabled", 'false') }
        }
    }, [gyro])

    // /////////////////////////////
    // Render
    return (
        <div id = 'altimetrie'>
            <div id ='altimetrieControllers' className={`${noHeader && paletteList > 1 ? "no-header-palette" : ""} ${noHeader && paletteList <= 1 ? "no-header" : ""}`}
>
                <Palette list = {paletteList} current = {currentVim} setCurrent = {setCurrentVim} extraEvent = {setMemory}></Palette>
            </div>
            <div ref={containerRef} style={{ position: 'absolute', width: '100%', height: '100%' }} />


            {(contextDatas.useVRforKr && canUseGyro) &&
                <div id = "gyro">

                    <button className = {`no-hover ${gyro ? "mainBackground" : "inactive"}`} onClick = {() => setGyro(g => {
                        if (g) {setCardBoard(false); return !g}
                        else {return !g}
                    })}>
                        <SvgIcon icon = "gyroscope" />
                    </button>     

                    <button className = {`no-hover ${cardBoard ? "mainBackground" : "inactive"}`} onClick = {() => setCardBoard(c => {
                        if (c) {return !c}
                        // else {setGyro(true); return !c}
                    })}>
                        <SvgIcon icon = "cardboard" />
                    </button>
                </div>
            }



            {annotation && <Annotation text = {annotation}></Annotation>}
        </div>
    )
}

export default Altimetrie;