// /////////////////////////////////////////////////////////////
// Sous Fichier pour la gestion des routes de localisation
// /////////////////////////////////////////////////////////////
// Creation : 16/09/2022    -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';
import Page404 from '../../../pages/page404/page404';

import { programmeContext } from '../../../contexts';

// Imports src
import Localisation from './../../../pages/localisation/localisation'


///////////////////////////////////
// Component

const LocalisationRouter = () => { // Routes pour le moteur de recherche

    // Render
    return (
        <programmeContext.Consumer>
            {datas => datas &&
                <Routes>
                    <Route path='' element={<Localisation page = {datas.pagesDetails.localisation.default === "Map" ? "Carte" : "Géolocalisation"}/>} />
                    <Route path='plan' element={<Localisation page = "Carte"/>} />
                    <Route path='photo' element={<Localisation page = "Géolocalisation"/>} />
                    <Route path='*' element={<Page404 />} />
                </Routes>
            }
        </programmeContext.Consumer>
    )
}


// ///////////////////////////////////
// Export
export default LocalisationRouter;