// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion de la page de comparaison
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 09/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


// Imports contexts
import {
    domain, 
    programmeNameContext, 
    programmeContext, 
    pageContext, 
    comparerContext,
    programmeLotsContext
} from './../../contexts';

// Imports components
import FavList from './favList/favList.js';
import FavComp from './favComp/favComp';



// ////////////////////////////////////////////////////////////////////////////////
// Components

const Comparer = () => {

    // Contexts
    const programmeName = useContext(programmeNameContext);
    const programmeDatas = useContext(programmeContext);
    const { setCurrent } = useContext(pageContext); 
    const lotsInfos = useContext(programmeLotsContext);
    let navigate = useNavigate();

    // Properties
    const [currentSort, setCurrentSort] = useState("Alphabetique");
    const [sortDirection, setSortDirection] = useState("");

    // Donnees de lot
    const [lotsDatas, setLotDatas] = useState(null); // Donnees de la BDD
    const [draggingOne, setDraggingOne] = useState(null); // Lot en cours de mouvement

    // Lots en actifs
    const [currentLotA, setCurrentLotA] = useState(null); // Lot en pos A
    const [currentLotB, setCurrentLotB] = useState(null); // Lot en pos B



    // Effect
    useEffect(() => { // recuperation de la liste des lots et de leurs donnees

        if (programmeName && programmeDatas && lotsInfos) {

            setCurrent("comparer"); // maj du menu
            const lots = lotsInfos.lots
            if (currentSort === "Alphabetique") {
                if (sortDirection === "") {
                    setLotDatas(lots.sort((a, b) => {if (a.name > b.name) {return 1} else if (a.name < b.name) {return -1}; return 0}))
                } else {
                    setLotDatas(lots.sort((a, b) => {if (a.name > b.name) {return -1} else if (a.name < b.name) {return 1}; return 0}))
                }
            } else if (currentSort === "Surfaces") {
                if (sortDirection === "") {
                    setLotDatas(lots.sort((a, b) => {if (a.surface > b.surface) {return 1} else if (a.surface < b.surface) {return -1}; return 0}))
                } else {
                    setLotDatas(lots.sort((a, b) => {if (a.surface > b.surface) {return -1} else if (a.surface < b.surface) {return 1}; return 0}))
                }
            } else if (currentSort === "Etages") {

                if (programmeDatas.maquette) {
                    const floors = programmeDatas.maquette.floors;

                    if (sortDirection === "") {
                        setLotDatas(lots.sort((a, b) => {if (floors.indexOf(a.etage) > floors.indexOf(b.etage)) {return 1} else if (floors.indexOf(a.etage) < floors.indexOf(b.etage)) {return -1}; return 0}))
                    } else {
                        setLotDatas(lots.sort((a, b) => {if (floors.indexOf(a.etage) > floors.indexOf(b.etage)) {return -1} else if (floors.indexOf(a.etage) < floors.indexOf(b.etage)) {return 1}; return 0}))
                    }

                } else {
                    setLotDatas(lots)
                }
                
            }  

        } 
    }, [programmeName, programmeDatas, sortDirection, currentSort, lotsInfos])




    // Render
    return (
        <comparerContext.Provider value={{currentLotA, setCurrentLotA, currentLotB, setCurrentLotB, draggingOne, setDraggingOne}}>
            <programmeNameContext.Consumer>
                {name => name != null && 
                    <programmeContext.Consumer>
                        {datas => datas != null && 
                            <div id = 'comparer' className=''>

                                {/* Liste des lots en favoris pour selection des comparaisons */}
                                <FavList 
                                    lots = {lotsDatas}
                                    sortDirection = {sortDirection} 
                                    setSortDirection = {setSortDirection}
                                    currentSort = {currentSort}
                                    setCurrentSort = {setCurrentSort}
                                />

                                {/* Zone de comparaison */}
                                <FavComp />
                            </div>
                }</programmeContext.Consumer>
            }</programmeNameContext.Consumer>
        </comparerContext.Provider>
    )
}

export default Comparer;