// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du volet de contact
// Creation : 18/08/2023 FTR
// ////////////////////////////////////////////////////////////////////////////////

// //////////////// Imports ////////////////// 

// Imports system
import React, { useState, useEffect, useRef, useContext } from 'react';

// Imports contexts
import { domain, programmeContext, contactContext } from './../../contexts';

// Components
import SvgIcon from './../../components/svg/svg';

//DESC: Component ContactField
//TODO: Voir pour fichier séparé
const ContactField = (props) => {

    //OLD: let field_datas = data.data
    let field_datas = props.data

    const [fieldType, setfieldType] = useState(field_datas.field_type);
    const [fieldState, setfieldState] = useState(field_datas.state);
    const [fieldText, setfieldText] = useState(field_datas.label);

    //TODO : Éventuellement ajuster côté back pour obtenir "firstName" plutôt que "Prénom", etc., mais attention à l'impact sur views, serializer etc.
    const setHTMLFieldType = (fieldType) => {
        switch (fieldType) {
            case "Prénom":
            case "Nom":
            case "Téléphone": //INFO: type text pour accepter "+" (ex: +33)
            case "Ville":
                return "text"
                break;
            case "Code Postal":
                return "number"
                break;
            case "Email":
                return "email"
                break;
            default:
                break;
        }
    }

    return (
        <>
            {/*TODO: Messages d'erreur !*/}
            {fieldState !== "off" && fieldType !== "Message" &&
                <div className="contact-form-fields">
                    <input className="contact-form-fields-inputs" type={setHTMLFieldType(fieldType)} placeholder={fieldState === "required" ? `${fieldText}*` : fieldText} ref={props.innerRef} /*ref = {fieldTypeR}*/></input>
                    {props.errorMessage &&
                        <p className="contact-form-fields-errors">{props.errorMessage}</p>
                    }
                </div>
            }
            {fieldState !== "off" && fieldType == "Message" &&
                <div className="contact-form-fields">
                    <textarea className="contact-form-fields-inputs contact-form-fields-textareas" placeholder={fieldState === "required" ? `${fieldText}*` : fieldText} ref={props.innerRef} rows="3" cols="50"></textarea>
                    {props.errorMessage &&
                        <p className="contact-form-fields-errors">{props.errorMessage}</p>
                    }
                </div>
            }
        </>
    )
}

// Bouton
const Contact = () => {

    // Contexts
    const programme = useContext(programmeContext);
    const { contactFormDisplay, setContactFormDisplay } = useContext(contactContext)

    // States
    const [error, setError] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [addionalTitle, setAddionalTitle] = useState("");
    const [addionalSubTitle, setAddionalSubTitle] = useState("");
    const [checkboxText, setCheckboxText] = useState("");
    const [contactFields, setContactFields] = useState();
    const [errorMessages, setErrorMessages] = useState({});
    const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false);

    const refs = {
        firstName: useRef(),
        lastName: useRef(),
        phoneNumber: useRef(),
        emailAddress: useRef(),
        postalCode: useRef(),
        city: useRef(),
        message: useRef(),
        checkbox: useRef()
    };

    useEffect(() => {
        // DESC: ReCaptcha v3
        // Chargez le script reCAPTCHA v3
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6Lf4ndsZAAAAADVEa1Fd5x7JAz5LpMIxUb2XA6HX';   //TODO : à changer avec celle de prod ! 
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        //LOG console.log("--- Message d'erreur" + errorMessages)

        fetch(`${domain}/${programme.name}/contact`, { cors: 'cors', method: 'GET' })
            .then((response) => {
                response.json().then((data) => {
                    //DESC: Titres additionnels
                    setAddionalTitle(data.contact.config.additional_title)
                    setAddionalSubTitle(data.contact.config.additional_subtitle)
                    //DESC: Texte pour la case à cocher (promotions, offres, etc.) 
                    setCheckboxText(data.contact.config.checkbox_text)
                    //DESC: Ajout et configuration dynamique des champs
                    const field_types = Object.values(data.contact.config.fields);
                    setContactFields(field_types.map((field, index) => (
                        <ContactField key={index} data={field} innerRef={refs[field.field_type_ref]} errorMessage={errorMessages[field.field_type_ref]} />
                    )));
                });
            })

    }, [errorMessages])


    const handleSubmit = (event) => {
        event.preventDefault() //TEMP ??

        // DESC: ReCaptcha
        // INFO: execute() appelé lors du submit au lieu du chargement de la page, car délai de validité de 2min,
        // INFO: https://developers.google.com/recaptcha/docs/v3?hl=fr
        window.grecaptcha.execute('6Lf4ndsZAAAAADVEa1Fd5x7JAz5LpMIxUb2XA6HX', { action: 'example' })
            .then(token => {
                // LOG : Calcul du temps d'execution, va de paire avec timeEnd plus bas
                //console.time("Elapsed time :");
                setRecaptchaToken(token);
            });
    }

    // DESC: On s'assure que l'envoi à frontValidation() se fasse lorqu'il y a un token
    // INFO: En effet, il y avait un problème, au premier submit il n'y avait pas de token
    // INFO: Car en étant dans handleSubmit, la suite du code s'executait avant que recaptchaToken soit assigné
    // INFO: Nous passons donc à la suite du code que lorsque recaptchaToken != null
    // INFO: pour éviter notre problème mais aussi car le useEffect est appelé au chargement de la page, même si nous lui donnons un state à surveiller
    useEffect(() => {
        if (recaptchaToken != null) {
            frontValidation()
        }
        // LOG : calcul du temps d'execution, va de paire avec time plus haut - 1ms normalement
        // console.timeEnd("Elapsed time :");
    }, [recaptchaToken])

    const frontValidation = () => {
        //TODO: State ?
        let isValid = true

        //DESC: Reset des erreurs
        setErrorMessages('')

        //DESC: Validation des données
        //DESC: Vérification de la saisie des champs obligatoires
        //DESC: Regex pour validité des données
        fetch(`${domain}/${programme.name}/contact`, { cors: 'cors', method: 'GET' })
            .then((response) => {
                response.json().then((data) => {
                    const field_types = Object.values(data.contact.config.fields);
                    field_types.forEach((field, index) => {
                        let currentRef = refs[field.field_type_ref];
                        //DESC: On ignore les champs désactivés
                        if(field.state != "off"){
                            //DESC: Si champ obligatoire et vide -> Message d'erreur
                            if (currentRef.current.value == '' && field.state == "required") {
                                isValid = false
                                setErrorMessages(prevErrors => ({
                                    ...prevErrors,
                                    [field.field_type_ref]: "Ce champ est obligatoire",
                                }));
                            //DESC: Regex
                            } else if ((field.field_type_ref == "firstName" ||
                                field.field_type_ref == "lastName" ||
                                field.field_type_ref == "city")
                                && new RegExp('[0-9]').test(currentRef.current.value)) {
                                    isValid = false
                                    setErrorMessages(prevErrors => ({
                                        ...prevErrors,
                                        [field.field_type_ref]: "La valeur saisie est invalide",
                                    }));
                            //DESC: Regex
                            } else if (field.field_type_ref == "phoneNumber" 
                                        && new RegExp('^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$').test(currentRef.current.value) == false
                                        && currentRef.current.value !== "") {
                                    isValid = false
                                    setErrorMessages(prevErrors => ({   
                                        ...prevErrors,
                                        [field.field_type_ref]: "Numéro de téléphone invalide",
                                    }));
                            //DESC: Regex
                            } else if (field.field_type_ref == "postalCode" && new RegExp('[a-zA-Z]').test(currentRef.current.value)) {
                                isValid = false
                                setErrorMessages(prevErrors => ({
                                    ...prevErrors,
                                    [field.field_type_ref]: "Code postal invalide",
                                }));
                            }
                        }
                    });

                    //DESC: Ajout des champs dans formData
                    //INFO: Inspiré du back-office
                    //INFO: formData correspond au body de la requête, au format attendu par le back
                    const formData = new FormData()
                    if (refs.firstName.current !== '' && refs.firstName.current !== undefined) { formData.append('firstName', refs.firstName.current.value); }
                    if (refs.lastName.current !== '' && refs.lastName.current !== undefined) { formData.append('lastName', refs.lastName.current.value); }
                    if (refs.phoneNumber.current !== '' && refs.phoneNumber.current !== undefined) { formData.append('phoneNumber', refs.phoneNumber.current.value); }
                    if (refs.emailAddress.current !== '' && refs.emailAddress.current !== undefined) { formData.append('emailAddress', refs.emailAddress.current.value); }
                    if (refs.postalCode.current !== '' && refs.postalCode.current !== undefined) { formData.append('postalCode', refs.postalCode.current.value); }
                    if (refs.city.current !== '' && refs.city.current !== undefined) { formData.append('city', refs.city.current.value); }
                    if (refs.message.current !== '' && refs.message.current !== undefined) { formData.append('message', refs.message.current.value); }
                    if (refs.checkbox.current !== '' && refs.checkbox.current !== undefined) { formData.append('checkbox', refs.checkbox.current.checked); }

                    if (recaptchaToken !== '') { formData.append('captcha', recaptchaToken); }

                    //LOG console.log(formData);
                    if (isValid) {
                        //LOG console.log("VALID")
                        sendForm(formData)
                    }
                });
            })
    }

    async function sendForm(formData) {

        //INFO: En cas d'erreur, passe dans le catch, donc pas pratique pour traiter par "code d'erreur"
        //INFO: Pour nous servir de response.status : Plutôt utiliser .then.catch()
        try {
            const response = await fetch(`${domain}/${programme.name}/contact/send`, {
                method: 'POST',
                body: formData
            });

            //LOG console.log(Object.fromEntries(formData))

            if (response.ok) {
                //DESC: Message de confirmation d'envoi
                //INFO: setDisplayConfirmationMessage(true) afficher le message, et cache le formulaire
                //INFO: setContactFormDisplay(false), au bout de 3sec. la modale complète (#contact) se ferme
                //INFO: J'ai fais comme choix de laisse le message de confirmation afficher lorsque nous ouvrons de nouveau la modale de contact
                //INFO: Normalement il ne devrait pas y avoir plusieurs envoie de mail par utilisateur. Mais si besoin, recharger la page suffit à afficher de nouveau le formulaire
                //INFO: Pour changer le comportement et afficher de nouveau le form., il suffit d'ajouter setDisplayConfirmationMessage(false), qui cache le message et affiche de nouveau le formulaire
                //TODO: Si affichage de nouveau du formulaire, les champs ne sont pas vidés, car ce n'est pas un re-render (comportement volontaire pour sauvegarder les données en cas de fermeture)
                setDisplayConfirmationMessage(true)
                setTimeout(() => {
                    setContactFormDisplay(false)
                    //setDisplayConfirmationMessage(false)  // <-- À décommenter pour afficher de nouveau le formulaire
                }, "3000");
            }

            //INFO: Au cas où les erreurs 4xx, 5xx, passent dans ce try
            //INFO: Mais normalement ce n'est pas le cas, d'où la gestion dans le catch
            /*if (response.ok){
                //LOG console.log("200, OK !")
                //LOG console.log(response)
            } else */
            if (response.status === 400) {
                throw new Error('Formulaire invalide');
            } else if (response.status === 500) {
                throw new Error('Erreur serveur');
            } else if (!response.ok) {
                throw new Error(`Erreur : ${response.status}`);
            }

            const result = await response.json();
            //console.log(result);
        } catch (error) {
            if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                //DESC: Gestion des erreurs de requête Fetch
                console.error('Erreur lors de la requête Fetch :', error.message);
                setError("Formulaire invalide");
            } else {
                //DESC: Gestion d'autres erreurs
                console.error('Erreur :', error.message);
            }
        }

    }

    // rendu
    return (
        <>
            <div id='contact' className={contactFormDisplay ? 'visible' : ''}>
                {/* OLD <div id = 'contact-close'>X</div>*/}
                {/* OLD (prends aussi en compte la touche Entrée, ce que nous ne souhaions pas): <form id = 'contact-form' onSubmit={handleSubmit}>*/}
                <form id='contact-form' className={displayConfirmationMessage ? 'hidden' : ''}>
                    <p id='contact-form-title'>Contactez-nous</p>
                    {(addionalTitle || addionalSubTitle) &&
                        <div id='contact-form-additionalTitles'>
                            {addionalTitle &&
                                <p id='contact-form-additionalTitles-title'>{addionalTitle}</p>
                            }
                            {addionalSubTitle &&
                                <p id='contact-form-additionalTitles-subtitle'>{addionalSubTitle}</p>
                            }
                        </div>
                    }
   
                    {contactFields}
                    {checkboxText &&
                        <div id='contact-form-promotion'>
                            <input id='contact-form-promotion-checkbox' type='checkbox' ref={refs.checkbox} name='promotion' />
                            <label id='contact-form-promotion-label' for='promotion'>{checkboxText}</label>
                        </div>
                    }
                    <button id='contact-form-button' type='button' onClick={handleSubmit}>
                        <p>Envoyer</p>
                        <SvgIcon icon='fleche-r' />
                    </button>
                </form>
                {displayConfirmationMessage &&
                    <div id='contact-confirmation'>
                        <p>Votre demande de contact a bien été envoyée</p>
                    </div>
                }
            </div>
        </>
    )
}




export default Contact;
