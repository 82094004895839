// ///////////////////////////////////////////////////////
// Composant pour la gestion des geolocalisation krPano
// ///////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// ///////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Import context
import { programmeContext, pageContext, domain, programmeNameContext, noHeaderContext } from './../../contexts';

// Import components
import Button from './../../components/button/button';
import Carto from './carto/carto';
import Geolocalisation from './geolocalisation/geolocalisation';



// ///////////////////////////////////
// Component
const Localisation = ({page}) => {

    // Properties
    const [currentLocalisation, setCurrentLocalisation] = useState(page);
    const [carte, setCarte] = useState(false);
    const [geoloc, setGeoloc] = useState(false);
    const { setCurrent, setHeaderLevel } = useContext(pageContext);
    const programmeSettings = useContext(programmeContext);
    const programmeName = useContext(programmeNameContext);
    const noHeader = useContext(noHeaderContext);
    const loc = useLocation();


    // Effects
    useEffect(() => {

        if (programmeSettings) {

            setCurrent("localisation");
            setHeaderLevel(1); // mise a jour du decalage du header

            setCarte(programmeSettings.pagesDetails.localisation.carte);
            setGeoloc(programmeSettings.pagesDetails.localisation.carte);
        }
    }, [programmeSettings])


    // Render
    return (
        <programmeContext.Consumer>
            {value =>
                value && 
                <div id = "localisation">
                    
                    {noHeader ?
                        <div id = "localisationToggle" className = {`${noHeader ? "no-header" : ""}`}>
                            {value.pagesDetails.localisation.geolocalisation &&
                                <Link to={noHeader ? `/${value.urlName}/localisation/photo?noheader=true` : `/${value.urlName}/localisation/photo`}>
                                    <Button 
                                        text="Photo" 
                                        state={currentLocalisation == "Géolocalisation"} 
                                        icon={"panorama"} 
                                        action={()=>{ setCurrentLocalisation("Géolocalisation")}}
                                        displayText = {value.displayText}
                                    />
                                </Link>
                            }

                            {value.pagesDetails.localisation.map &&
                                <Link to={noHeader ? `/${value.urlName}/localisation/plan?noheader=true` : `/${value.urlName}/localisation/plan`}>
                                    <Button 
                                        text="Plan"
                                        icon={"localiser"} 
                                        state={currentLocalisation == "Carte"} 
                                        action={()=>{setCurrentLocalisation("Carte")}} 
                                        displayText = {value.displayText}
                                    />
                                </Link>
                            }
                        </div>

                        :
                        
                        (value.pagesDetails.localisation.map && value.pagesDetails.localisation.geolocalisation) &&
                            <div id = "localisationToggle" className = {`${noHeader ? "no-header" : ""}`}>
    
                                <Link to={noHeader ? `/${value.urlName}/localisation/photo?noheader=true` : `/${value.urlName}/localisation/photo`}>
                                    <Button 
                                        text="Photo" 
                                        state={currentLocalisation == "Géolocalisation"} 
                                        icon={"panorama"} 
                                        action={()=>{ setCurrentLocalisation("Géolocalisation")}}
                                        displayText = {value.displayText}
                                    />
                                </Link>
    
                                <Link to={noHeader ? `/${value.urlName}/localisation/plan?noheader=true` : `/${value.urlName}/localisation/plan`}>
                                    <Button 
                                        text="Plan"
                                        icon={"localiser"} 
                                        state={currentLocalisation == "Carte"} 
                                        action={()=>{setCurrentLocalisation("Carte")}} 
                                        displayText = {value.displayText}
                                    />
                                </Link>
                            </div>
                    }

                    {currentLocalisation == "Carte" ?
                        <Carto></Carto>
                        :
                        <Geolocalisation></Geolocalisation>
                    }
                </div>
            }
        </programmeContext.Consumer>   
    )
} 



// ///////////////////////////////////
// Export
export default Localisation;