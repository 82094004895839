// /////////////////////////////////////////////////////////////
// Fichier pour la gestion des trois niveaux de menu
// /////////////////////////////////////////////////////////////
// Creation :    -> CGR     08/09/2022
// /////////////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports 

// Imports system
import React, {useState, useEffect, useContext, useRef } from 'react';
import {useLocation} from 'react-router-dom';

// Import context
import { 
    programmeContext, 
    pageContext, 
    lotContext, 
    favorisContext,
    languageContext,
    noHeaderContext, 
} from './../../../contexts';

// Imports src
import Button from '../../../components/button/button';
import ToggleLevel from './toggleLevel/toggleLevel';


// /////////////////////////////////////////////////////////////
// Component

const ToggleNavigation = ({lvl}) => {


    // ////////////////
    // Properties
    
    // Refs
    const mainToggle = useRef();

    // Path
    const loc = useLocation();

    // Contexts
    const contextDatas = useContext(programmeContext);
    const noHeader = useContext(noHeaderContext);
    const lang = useContext(languageContext);
    const { favs, setFavs } = useContext(favorisContext);
    const contextPage = useContext(pageContext);
    const {lotDatas, setLotDatas} = useContext(lotContext);

    // States
    const [toggles, setToggles] = useState(null);
    const [mainToggleHeight, setmainToggleHeight] = useState(0);
    const [maxVisibleButtons, setMaxVisibleButtons] = useState(0);

    // ////////////////
    // Functions
    const setToggle1 = (toggle, noheader=false) => { // Mise en place du toggle home

        const tt = [...toggle];

        if (!noheader) {
            if (contextDatas.pages.includes("comparer") && contextDatas.nbLots > 1) {
                tt.push({
                    id : "comp",
                    text: contextDatas.languagesDatas[lang].header.toggle1.comparer,
                    icon: "favoris",
                    route: `comparer`
                });
            } 
        }
       

        return <ToggleLevel buttons = {tt} position={0} maxVisibleButtons={maxVisibleButtons}></ToggleLevel>
    }
    const setToggle2 = (toggle) => { // Mise en place du toggle de decouvrir
        return <ToggleLevel buttons = {toggle} position={1} maxVisibleButtons={maxVisibleButtons}></ToggleLevel>
    }

    const setToggle3 = (toggle, lot) => { // Mise en place du toggle de detail du lot

        let lotName = "";
        if (lot !== null) {lotName = lot.name}

        const toggleWithLink = [];

        toggleWithLink.push({
            id : "plan",
            text: contextDatas.languagesDatas[lang]['detail-lot'].header.plan,
            icon: "plan",
            route: `detail-lot/${lotName}/plan`
        })

        if (contextDatas.pages.includes("maquette 3D")) {
            toggleWithLink.push({
                id : "situer",
                text: contextDatas.languagesDatas[lang]['detail-lot'].header.situation,
                icon: "situer",
                route: `detail-lot/${lotName}/situation`
            })
        }

        if (lotDatas && Object.keys(lotDatas.altimetries).length > 0) {
            toggleWithLink.push({
                id : "vue",
                text: "vue",
                icon: "alti1",
                route: `detail-lot/${lotName}/vue`
            })
        }


        if (lot !== null && lot.haveVim) {
            toggleWithLink.push({
                id : "visiter",
                text: "visiter",
                icon: "visites",
                route: `detail-lot/${lotName}/vim`
            })
        }

        // Gestion du bouton d'ajout/retrait aux favoris
        let favLink = null;
        let isInFav = favs.includes(lotName);
        if (contextDatas.pages.includes("comparer") && contextDatas.nbLots > 1) {
            favLink = <Button 
                icon = {isInFav ? 'favorisPlein' : 'favoris'} 
                text = {contextDatas.languagesDatas[lang]['detail-lot'].header.favoris}
                action = {() => setFavs(lotName)}
                displayText = {contextDatas.displayText}
            />
        }

        return <ToggleLevel buttons = {toggleWithLink} extraBtn = {favLink} position={2} maxVisibleButtons={maxVisibleButtons}></ToggleLevel>
    }

    //TODO:
    //DESC: Rendre la tailles des boutons (70) dynamique
    const heightCalc = () => {
        //TEMP
        //INFO: Ne récupère que la taille d'un bouton
        //TODO: Compter hauteur des flèches
        let btnHeight = document.getElementById("togglePos1").children[0];
        //return `Math.round(${mainToggleHeight} / ${btnHeight}) * ${btnHeight}px`;
        return (mainToggleHeight / btnHeight) * btnHeight;
    }

    // ////////////////
    // Effects
    useEffect(() => { // Mise en place du header suivant les niveaux

        setToggles([]);
        if (contextDatas && contextPage) { 

            const togglesContent = [];
            
            if (noHeader) {
                // Construction du niveau 1
                togglesContent.push(setToggle1([
                    {'id' : "recherche", 'icon' : 'recherche', 'text' : contextDatas.languagesDatas[lang].header.toggle1.rechercher, 'route' : 'rechercher?noheader=true'},
                    {'id' : "favoris", 'icon' : 'favoris', 'text' : contextDatas.languagesDatas[lang].header.toggle1.comparer, 'route' : 'comparer?noheader=true'},
                ], true));
                // Mise en memoire
                setToggles(togglesContent);
            } else {
                // Construction du niveau 1
                const toggle1 = [];
                contextDatas.header.toggleMenu[0].forEach(x => {
                    if (x.text === "comparer" && contextDatas.nbLots > 1) {
                        toggle1.push({
                            id : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            icon : x.icon,
                            text : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            route : x.route
                        });
                    } else if (x.text == "rechercher" && contextDatas.nbLots > 1) {
                        toggle1.push({
                            id : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            icon : x.icon,
                            text : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            route : x.route
                        });
                    } else if (x.text === "visiter") {
                        toggle1.push({
                            id : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            icon : x.icon,
                            text : contextDatas.languagesDatas[lang].header.toggle1[x.text],
                            route : x.route
                        });  
                    }
                });
                togglesContent.push(setToggle1(toggle1));
                // Construction du niveau 2
                togglesContent.push(setToggle2(contextDatas.header.toggleMenu[1]));
                // Construction du niveau 3
                togglesContent.push(setToggle3(contextDatas.header.toggleMenu[2], lotDatas));
                // Mise en memoire
                setToggles(togglesContent);
            }

        }

    },[contextPage, contextDatas, lotDatas, favs, maxVisibleButtons]);

    useEffect(() => { // Ajout des event de detection de changement de taille d'ecran

        const detectRatio = () => {
            const top = document.getElementById("mainTop");
            const bottom = document.getElementById("mainBottom");

            let tempHeight = 0;
            let topHeight = 0;
            let bottomHeight = 0;

            if (top) {
                for(let child of top.children){
                    topHeight = topHeight + child.offsetHeight;
                };
            }

            if (bottom) {
                for(let child of bottom.children){
                    bottomHeight = bottomHeight + child.offsetHeight;
                };
            }

            if(topHeight > bottomHeight){
                tempHeight = topHeight * 2;
            } else {
                tempHeight = bottomHeight * 2;
            }

            setmainToggleHeight(document.body.offsetHeight - tempHeight);
        }

        const handleSize = () => {
            detectRatio();
        }

        handleSize();
        setTimeout(() => { // Securite -> second detectRatio + tard si le premier n'est pas passe
            detectRatio();
        }, 100); 

        window.addEventListener("resize", handleSize);
        setTimeout(() => {
            detectRatio();
        }, 1); 

        return(() => {
            window.removeEventListener('resize', handleSize)
        })        
    },[lvl]);

    //TEMP:
    useEffect(() => {
        setMaxVisibleButtons(Math.trunc((mainToggleHeight - 28) / 70));
    },[mainToggleHeight]);


    // Render
    return (
        <pageContext.Consumer>
            {page => page != null && 
                <div id = 'mainToggle' ref={mainToggle} style = {{minHeight : `${maxVisibleButtons * 70 + 28}px`}}>
                    <div id = 'mainToggleSlider' className = {`mainSliderPos${page.headerLevel}`}>
                        {toggles}
                    </div>
                </div>
        }</pageContext.Consumer>
    )
}


// /////////////////////////////////////////////////////////////
// Export
export default ToggleNavigation;
