// ////////////////////////////////////////////////////////////
// Fichier pour la gestion des pieces d'une VIM
// ////////////////////////////////////////////////////////////
// Creation : 08/08/2022    -> CGR
// ////////////////////////////////////////////////////////////


// //////////////////////////
// Imports

// Imports system
import React from 'react';
// Imports components
import ButtonLong from '../../../components/button/buttonLong';
import { useSearchParams  } from 'react-router-dom'



// //////////////////////////
// Components
const SubNavigation = ({list, current, onUpdate}) => { // Toggle pour le changement de piece

    return (
        <div className = {`vim-pieces open`}>
            {
                list.map((elem) => {
                    return (
                        <ButtonLong 
                            key = {elem.name ? elem.name : elem} 
                            icon= {elem.icon ? elem.icon : null}
                            text = {elem.title ? elem.title : elem} 
                            state = {current === elem.title ? elem.title : ""} 
                            action = {() => onUpdate(elem)} 
                        />
                    )
                })
            }
        </div>
    )
}

// //////////////////////////
// Export
export default SubNavigation;