// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion de la liste des lots en favoris
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 09/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useContext } from 'react';

// Imports contexts
import {favorisContext, languageContext} from './../../../contexts';

// Imports components
import LotFavoris from './lotFavoris/lotFavoris';
import FavListActions from './actions/favListActions';



// ////////////////////////////////////////////////////////////////////////////////
// Components

const FavList = ({
        lots,
        currentSort, 
        setCurrentSort,
        sortDirection,
        setSortDirection
    }) => {

    const {favs} = useContext(favorisContext);
    const lang = useContext(languageContext)
    const [favsElements, setFavsElements] = useState();

    const buildFavsElements = () => {

        const lotsFavsElements = [];

        lots.map(lot => {
            if (favs.includes(lot.name)) {
                lotsFavsElements.push(
                    <LotFavoris 
                        key = {lot.name}
                        name = {lot.name}
                        type = {lang === 'fr' ? lot.type : lot.numPieces-1}
                        surface = {lot.surface}
                        etage = {lot.etage}
                        current = {[]}
                    ></LotFavoris>
                )
            }
        });

        return lotsFavsElements;
    }

    useEffect(() => {
        if (lots != null) {
            setFavsElements(buildFavsElements());
        }
    }, [lots, favs, sortDirection, currentSort])

    return (
        <div id = 'comparer-list'>

            <FavListActions
                sortDirection = {sortDirection}
                setSortDirection = {setSortDirection}
                currentSort = {currentSort}
                setCurrentSort = {setCurrentSort}
            ></FavListActions>

            <div id = 'comparer-results'>
                {favsElements}
            </div>

        </div>
    )
}

export default FavList;