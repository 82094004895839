import React, { useEffect, useState, useContext, useRef } from 'react';
import useKrpano from 'react-krpano-hooks';
import { useSearchParams } from "react-router-dom"
import SvgIcon from "./../../../components/svg/svg";

import { programmeNameContext, programmeContext, languageContext } from './../../../contexts';

const Pano = ({ currentInt, view, pois, decoration, prestation, setDirection, setInitialDir }) => { // Partie krPano

    // /////////////////////////////
    // Properties
    const programmeName = useContext(programmeNameContext)

    const { containerRef, krpanoState, callKrpano } = useKrpano({ // KRPano
        scriptPath: `/public/sources/krPanoBuilder/viewer/krpano.js`,
        embeddingParams: {
            swf: `/public/programmes/${programmeName}/interieurs/${currentInt}/tour.swf`,
            xml: `/public/programmes/${programmeName}/interieurs/${currentInt}/tour.xml`,
            target: 'react-krpano',
            html: 'prefer'
        },
        width: "calc(100% - 100px)"
    })

    const [canUseGyro, setCanUseGyro] = useState(false)
    const programmeDatas = useContext(programmeContext)
    const lang = useContext(languageContext)
    const [hotspots, setHotspots] = useState([])
    const [forceUpdate, setForceUpdate] = useState(0)
    const updateType = useRef('room')
    const [positionMemory, setPositionMemory] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [cardBoard, setCardBoard] = useState(false)
    const [gyro, setGyro] = useState(false)



    // /////////////////////////////
    // Function

    const setKrDatas = () => {

        callKrpano(`set(view.fov, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].fov});`);
        callKrpano(`set(view.hlookat, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].x});`);
        callKrpano(`set(view.vlookat, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].y});`);
        callKrpano(`set(view.limitview, range);`);
        callKrpano(`set(view.hlookatmin, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].ranges.xMin});`);
        callKrpano(`set(view.hlookatmax, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].ranges.xMax});`);
        callKrpano(`set(view.vlookatmin, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].ranges.yMin});`);
        callKrpano(`set(view.vlookatmax, ${programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[view].ranges.yMax});`);

        setKrPoi()

    }

    const setKrPoi = () => {

        for (let spot of hotspots) {
            callKrpano(`removehotspot(${spot});`);
        }
        // On vide la memoire
        setHotspots([]);

        // Gestion des poi
        setTimeout(() => {

            for (const poi of pois) {

                let goto = poi.link
                goto = Object.keys(programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces).filter(x => programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[x].titleLangs[0] === goto)
                goto = programmeDatas.pagesDetails.interieurs.pano[currentInt].pieces[goto].titleLangs[programmeDatas.languages.findIndex(x => x === lang)]

                callKrpano(`addhotspot(poi_${poi.id}_ctn);`);
                callKrpano(`hotspot[poi_${poi.id}_ctn].loadstyle(skin-direction);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].ath, ${poi.x});`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].atv, ${poi.y});`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].zoom,false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].distorted, false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].scale, 0.8);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].width, 60);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].height, 60);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].x, 0);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].y, 8);`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].onclick, js(window.somVar(${goto.replaceAll("'", "%")})));`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].onhover, tween(scale, 1.2, 0.15); tween(hotspot[poi_${poi.id}_svg].scale, 0.7, 0.15); tween(hotspot[poi_${poi.id}_svg].y, -35, 0.15); tween(hotspot[poi_${poi.id}_txt].y, 62, 0.15);)`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].onout, tween(scale, 0.8, 0.15); tween(hotspot[poi_${poi.id}_svg].scale, 0.4, 0.15); tween(hotspot[poi_${poi.id}_svg].y, -13, 0.15); tween(hotspot[poi_${poi.id}_txt].y, 50, 0.15);)`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].bgcolor, 0x${poi.color.slice(1)});`);
                callKrpano(`set(hotspot[poi_${poi.id}_ctn].bgroundedge, 30;`);
    
                callKrpano(`addhotspot(poi_${poi.id}_txt);`);
                callKrpano(`hotspot[poi_${poi.id}_txt].loadstyle(skin-direction-text);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].ath, ${poi.x});`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].atv, ${poi.y});`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].zoom,false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].height, 120);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].textalign, centerbottom);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].bg, false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].html, ${poi.text[programmeDatas.languages.findIndex(x => x === lang)]});`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].distorted, false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].onclick, js(window.somVar(${goto.replaceAll("'", "%")})));`);
                callKrpano(`set(hotspot[poi_${poi.id}_txt].css, "overflow : visible; color: black; font-size: 20px; font-family: BarlowCondensed-SemiBold; width:auto; border-radius:4px; pointer-events:none!important; ";`);
    
                callKrpano(`addhotspot(poi_${poi.id}_svg);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].zoom,false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].ath, ${poi.x});`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].atv, ${poi.y});`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].x, 80;`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].y, 13;`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].distorted,false);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].zorder, 10);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].scale, 0.4);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].edge, center);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].url, /public/icons/VIM/${poi.icon}.svg);`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].onhover, tween(hotspot[poi_${poi.id}_ctn].scale, 1.2, 0.15); tween(scale, 0.7, 0.15); tween(y, -35, 0.15); tween(hotspot[poi_${poi.id}_txt].height, 140, 0.15);)`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].onout, tween(hotspot[poi_${poi.id}_ctn].scale, 0.8, 0.15); tween(scale, 0.4, 0.15); tween(y, -13, 0.15); tween(hotspot[poi_${poi.id}_txt].height, 120, 0.15);)`);
                callKrpano(`set(hotspot[poi_${poi.id}_svg].onclick, js(window.somVar(${goto.replaceAll("'", "%")})));`);
    
                // Ajout du hotspot en memoire
                setHotspots(old => [...old, `poi_${poi.id}`])
            }

        }, 2)
    }


    // /////////////////////////////
    // Effects

    useEffect(() => {
        window.somVar = (name) => {
            searchParams.set("piece", name.replaceAll('%', "'"))
            setSearchParams(searchParams)
        };
    }, [currentInt, decoration, prestation])


    useEffect(() => {
        if (decoration || prestation) {
            // au changement de décoration / prestation
            const kr = containerRef.current.children[0]
            if (kr) {

                for (let spot of hotspots) {
                    callKrpano(`removehotspot(${spot});`);
                }

                const posMem = [kr.get("view.hlookat"), kr.get("view.vlookat"), kr.get("view.camroll")]
                updateType.current = "deco"
                setForceUpdate(l => l + 1)
                setPositionMemory(posMem)
                setKrPoi()
            }
        }
    }, [decoration, prestation])
    
    useEffect(() => {
        if (krpanoState.isEmbedded && view && view !== '') {
            let name = `${view}${decoration !== null && decoration !== undefined ? `-${decoration.replaceAll(" ", "_")}` : ""}${prestation !== null && prestation !== undefined ? `-${prestation.replaceAll(" ", "_")}` : ""}`.replaceAll("é", "pp").replaceAll("É", "pj").replaceAll("è", "po").replaceAll("'", "")

            callKrpano(
                `loadscene('${name}', null, MERGE, BLEND(0.4));`
            );

            for (let spot of hotspots) {
                callKrpano(`removehotspot(${spot});`);
            }

            if (updateType.current === "room") {
                setTimeout(() => {
                    const kr = containerRef.current.children[0];
                    if (kr) {
                        setKrDatas()
                        const hlookat = kr.get("view.hlookat")
                        const camroll = kr.get("view.camroll")
                        setInitialDir(camroll + hlookat)
                    }
                }, 50)
            }
        }
    }, [krpanoState.isEmbedded, forceUpdate]);


    useEffect(() => {
        if (krpanoState.isEmbedded && view && view !== '') {
            updateType.current = "room"
            setForceUpdate(l => l + 1);
        }
    }, [krpanoState.isEmbedded, currentInt, view]);


    useEffect(() => { // Gestion de la direction de la puce minimap en live

        let int;
        if (krpanoState.isEmbedded) {
            int = setInterval(() => {
                const kr = containerRef.current.children[0];
                if (kr) {
                    const hlookat = kr.get("view.hlookat")
                    const camroll = kr.get("view.camroll")
                    setDirection(camroll + hlookat)
                }
    
            }, 100)
        }
        return () => clearInterval(int)
    }, [krpanoState.isEmbedded])


    useEffect(() => {

        if (containerRef.current !== undefined) {
            if (containerRef.current.children.length === 2) {
                containerRef.current.removeChild(containerRef.current.children[0])

                // TO CHANGE : Not a really good option but ReactKrapano need update...
                setTimeout(() => {
                    setForceUpdate(l => l + 1);
                }, 10)
                setTimeout(() => {
                    setForceUpdate(l => l + 1);
                }, 200)
            }
        }
    }, [containerRef, currentInt])

    useEffect(() => {
        if (positionMemory) {
            const kr = containerRef.current.children[0];

            kr.set("view.hlookat", positionMemory[0]);
            kr.set("view.vlookat", positionMemory[1]);
            kr.set("view.camroll", positionMemory[2]);
        }
    }, [positionMemory])

    useEffect(() => {

        // variable global pour effet krPano
        window.endVr = () => {
            setCardBoard(false)
            setGyro(false)
        }
        
        setCanUseGyro(navigator.platform !== "Win32")
    }, [])

    useEffect(() => {
        const kr = containerRef.current.children[0]

        if (kr) {
            if (cardBoard) { kr.call("webvr.enterVR();") } 
            else  { 
                kr.call("webvr.exitVR();") 
            }
        }
    }, [cardBoard])

    useEffect(() => {
        const kr = containerRef.current.children[0]

        if (kr) {
            if (gyro) { kr.set("plugin[skin_gyro].enabled", 'true') } 
            else  { kr.set("plugin[skin_gyro].enabled", 'false') }
        }
    }, [gyro])

    // /////////////////////////////
    // Render
    return (
        <div id='pano'>
            <div ref={containerRef} style={{ position: 'relative', width: '100%!important', height: '100%' }} />

            {(programmeDatas.useVRforKr && canUseGyro) &&
                <div id = "gyro">

                    <button className = {`no-hover ${gyro ? "mainBackground" : "inactive"}`} onClick = {() => setGyro(g => {
                        if (g) {setCardBoard(false); return !g}
                        else {return !g}
                    })}>
                        <SvgIcon icon = "gyroscope" />
                    </button>     

                    <button className = {`no-hover ${cardBoard ? "mainBackground" : "inactive"}`} onClick = {() => setCardBoard(c => {
                        if (c) {return !c}
                        else {/*setGyro(true); */return !c}
                    })}>
                        <SvgIcon icon = "cardboard" />
                    </button>
                </div>
            }
        </div>
    )
}

export default Pano;