// /////////////////////////////////////////////////////////////
// Composant pour la recuperation du programme actif
// et la recuperation des informations
// Routes secondaires pour la repartition des pages
// /////////////////////////////////////////////////////////////
// Creation : 06/09/2022    -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    useSearchParams
} from 'react-router-dom';
import appLoader from '../../components/appLoader/appLoader';



// Import context
import {
    state,
    programmeNameContext,
    pageContext,
    returnToContext,
    lotContext,
    favorisContext,
    noHeaderContext,
    miniMoteurContext,
    programmeContext,
    maquetteContext,
    languageContext,
    contactContext,
    maquetteLoaderContext
} from '../../contexts';

// Imports src
import RechercherRouter from './pages/rechercherRouter';
import MaquetteRouter from './pages/maquetteRouter';
import DetailLotRouter from './pages/detailLotRouter';
import LocalisationRouter from './pages/localisationRouter';
import ExterieursRouter from './pages/exterieursRouteur';
import Loading from '../../components/loading/loading';

import MainNavigation from '../mainNavigation/mainNavigation';
import Home from '../../pages/home/home';
import Description from '../../pages/projet/projet';
import Galerie from '../../pages/galerie/galerie';
import Rechercher from '../../pages/rechercher/rechercher';
import Comparer from '../../pages/comparer/comparer';
import usePreloader from '../../components/player/hooks/usePreloader';
import Altimetrie from '../../pages/altimetrie/altimetrie';
import useSessionStorage from '../../components/session/useSession';
import Credits from '../credits/credits';
import ContactButton from '../contact/contactButton';
import Contact from '../contact/contact';
import Page404 from '../../pages/page404/page404';
import GlobalStyle from '../../style/globalStyle';
import ExternalContent from '../../pages/externalContent/ExternalContent';
import Interieurs from '../../pages/interieurs/interieurs';
import LotNoUI from '../../pages/lotNoUi/lotNoUi';


///////////////////////////////////
// Component

const ProgrammeRouter = () => { // Composant pour la recuperation du programme et de ses infos.

    // Properties
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    // const {urlProgramme} = useParams(); // Programme, issue de l'url
    const programmeDatas = useContext(programmeContext)
    const maquetteDatas = useContext(maquetteContext);
    const contactDatas = useContext(contactContext);
    const [programmeName, setProgrammeName] = useState(null)
    const [needHome, setNeedHome] = useState(false); // Le programme utilise-t-il une page d'accueil
    const [current, setCurrent] = useState(null); // Page en cours de visionnage
    const [favs, setFavs] = useSessionStorage('favoris'); // Liste des favoris en cours
    const [headerLevel, setHeaderLevel] = useState(0); // Niveau actif du menu
    const [loadHdForFrame, loadLot] = usePreloader(programmeName); // Preloader pour les maquettes
    const [preloaded, setPreloaded] = useState(null);
    const [lotDatas, setLotDatas] = useState(null); // gestion du lot actif pour pages detail du lot
    const [returnTo, setReturnTo] = useState("programme"); // etat du retour au niveau precedent du detail du lot
    const imgMemory = useRef()
    const [loadingDatas, setLoadingDatas] = useState({
        maquette : {},
        lots : {}
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [contactFormDisplay, setContactFormDisplay] = useState(false);


    // Properties pour le mini moteur
    const [pieces, setPieces] = useState([]); // Filtre de pieces
    const [options, setOptions] = useState(null); // Filtre d'options
    const [surface, setSurface] = useState(0); // Filtre de surface
    const [filter, setFilter] = useState(''); // Filtres de recherche
    const [label, setLabel] = useState('');
    const loadOnlyOneTime = useRef(false);

    // Style

    // Effects

    useEffect(() => { // Recuperation des donnees pour le programme
        if (programmeDatas) {

            // Recuperations infos programme
            setProgrammeName(programmeDatas.name);
            // Initialisation du current
            if (programmeDatas.pages.includes("home")) { setCurrent("home"); }
            else { setCurrent(""); }
            // Faut-il une page Accueil
            setNeedHome(programmeDatas.pages.includes("home"));
            // Passage sur page par defaut si pas de home
            if (!programmeDatas.pages.includes("home")) {

                // On commence par regarder si l'url ne comporte pas deja la page demande
                if (pathname === `/${programmeDatas.urlName}`) {
                    if (programmeDatas.header.defaultPage === "interieurs") {

                        let toDeco = ""
                        if (Object.values(programmeDatas.pagesDetails.interieurs.pano)[0].decorations.length) {
                            toDeco = `&decoration=${Object.values(programmeDatas.pagesDetails.interieurs.pano)[0].decorations[0]}`
                        } else if (Object.values(programmeDatas.pagesDetails.interieurs.pano)[0].prestations.length) {
                            toDeco = `&prestation=${Object.values(programmeDatas.pagesDetails.interieurs.pano)[0].prestations[0]}`
                        }

                        navigate(`interieurs/${Object.keys(programmeDatas.pagesDetails.interieurs.pano)?.length > 0 ?
                            `pano/${Object.keys(programmeDatas.pagesDetails.interieurs.pano)[0]}?piece=${Object.values(Object.values(programmeDatas.pagesDetails.interieurs.pano)[0].pieces)[0].title}${toDeco}` :
                            "temoin"}`)
                    } else {
                        navigate(`${programmeDatas.header.defaultPage}`)
                    }
                }



            }

            // Onglet - Titre
            //  Le tag <title> a été laissé dans index.html car il vaut mieux avoir dans l'onglet
            //  un titre par défaut plutôt que l'URL

            if (!programmeDatas.title) {
                document.title = programmeDatas.client.name;
            }

            if (programmeDatas.client.name && programmeDatas.title) {
                document.title = programmeDatas.title;
            }

            if (!programmeDatas.favicon && programmeDatas.client.favicon) {
                let link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
                link.href = `/public/clients/${programmeDatas.client.name}/${programmeDatas.client.favicon}`;
            }

            if (programmeDatas.favicon && programmeDatas.client.favicon) {
                let link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
                link.href = `/public/programmes/${programmeDatas.name}/${programmeDatas.favicon}`;
            }

            if (!programmeDatas.favicon && !programmeDatas.client.favicon) {
                let link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
                link.href = '/public/images/favicon.ico/';
            }

            // Onglet - Favicon
            //  Création de tag link dans le DOM.
            //  Le tag <link> a été retirée d'index.html pour éviter l'affichage du favicon par défaut avant création

            // mise en place du manifest dynamique pour offline
            fetch(`/public/programmes/${programmeDatas.name}/manifest.json`, { mode: 'cors' })
                .then(() => {
                    // S'il est prevu une app
                    const link = document.createElement("link");
                    link.rel = "manifest";
                    link.setAttribute('href', `/public/programmes/${programmeDatas.name}/manifest.json`)
                    document.head.appendChild(link);
                }).catch(() => {
                    // Sinon
                })

        }
    }, [programmeDatas])

    useEffect(() => {
        if (maquetteDatas) {
            if (!loadOnlyOneTime.current) {
                loadOnlyOneTime.current = true

                if (window.location.href.includes("/lot/")) {
                } else {
                    appLoader(programmeDatas.name, maquetteDatas, lotDatas, loadingDatas, setLoadingDatas)
                }
            }
        }
    }, [programmeDatas, maquetteDatas, preloaded])

    // filtre pour le programme "online"
    if (state === "prod" && !programmeDatas.isOnline) { return <Page404 />}

    // Render
    return (
        <maquetteLoaderContext.Provider value={{loadingDatas, setLoadingDatas}}>
            <languageContext.Provider value={searchParams.get("lang") ? searchParams.get("lang") : (programmeDatas.languages && programmeDatas.languages.length !== 0) ? programmeDatas.languages[0] : "fr"}>
                <noHeaderContext.Provider value={search.includes("noheader") || search.includes("isolate")}>
                    <programmeNameContext.Provider value={programmeName}>
                        <pageContext.Provider value={{ headerLevel, setHeaderLevel, current, setCurrent }}>
                            <lotContext.Provider value={{ lotDatas, setLotDatas }}>
                                <returnToContext.Provider value={{ returnTo, setReturnTo }}>
                                    <miniMoteurContext.Provider value={{ filter, setFilter, pieces, setPieces, options, setOptions, surface, setSurface, label, setLabel }}>
                                        <favorisContext.Provider value={{ favs, setFavs }}>
                                            <contactContext.Provider value={{ contactFormDisplay, setContactFormDisplay }}>
                                                {
                                                    !programmeDatas ?
                                                        // Afficher le loader avant la fin du fetch
                                                        <Loading text="Chargement du programme" />
                                                        :
                                                        // Sinon afficher la page
                                                        (state === "prod" && (programmeDatas.url !== "" ? window.location.host.split('.')[0] != programmeDatas.url : false)) ?
                                                            <Page404 />
                                                            :
                                                            <>
                                                                <GlobalStyle />

                                                                {/* Gestion du menu */}
                                                                <MainNavigation />

                                                                {/* Gestion des pages */}
                                                                <Routes>
                                                                    <Route
                                                                        path=''
                                                                        element={<Home />}
                                                                    />

                                                                    <Route
                                                                        path='comparer'
                                                                        element={<Comparer />}
                                                                    />

                                                                    <Route
                                                                        path='detail-lot/:lotParam/*'
                                                                        element={<DetailLotRouter loadLot={loadLot} loadHdForFrame={imgMemory} />}
                                                                    />

                                                                    <Route path='exterieurs/*' element={<ExterieursRouter />} />

                                                                    <Route path='interieurs/*' element={<Interieurs loadHdForFrame={imgMemory} />} />

                                                                    <Route path='galerie' element={<Galerie />} />

                                                                    <Route path='localisation/*' element={<LocalisationRouter />} />

                                                                    <Route path='lot/:lotname' element={<LotNoUI loadHdForFrame = {imgMemory}/>} />

                                                                    <Route
                                                                        path='programme/*'
                                                                        element={
                                                                            <MaquetteRouter
                                                                                preloader={preloaded}
                                                                                loadHdForFrame={imgMemory}
                                                                            />}
                                                                    />

                                                                    <Route path='projet' element={<Description />}
                                                                    />

                                                                    <Route path='recherche' element={<Rechercher />}
                                                                    />

                                                                    <Route path='rechercher/*' element={<RechercherRouter />}
                                                                    />

                                                                    <Route path='vues' element={<Altimetrie />}
                                                                    />

                                                                    <Route
                                                                        path='externalcontent/:btnId'
                                                                        element={<ExternalContent />}
                                                                    />

                                                                    <Route path='*' element={<Page404 />} />

                                                                </Routes>

                                                                {/* Bandeau vb + cookies */}
                                                                <Credits />

                                                                {/* Contact */}
                                                                <ContactButton />

                                                                {programmeDatas.pages.includes("contact") && <Contact />}

                                                                <div id="mobile-warning">
                                                                    <div id="mobile-preview"></div>
                                                                    <p>{programmeDatas.languagesDatas[searchParams.get("lang") ? searchParams.get("lang") : (programmeDatas.languages && programmeDatas.languages.length !== 0) ? programmeDatas.languages[0] : "fr"].main.mobileWarning.mobileWarning}</p>
                                                                </div>
                                                            </>

                                                }
                                            </contactContext.Provider>
                                        </favorisContext.Provider>
                                    </miniMoteurContext.Provider>
                                </returnToContext.Provider>
                            </lotContext.Provider>
                        </pageContext.Provider>
                    </programmeNameContext.Provider>
                </noHeaderContext.Provider>
            </languageContext.Provider>
        </maquetteLoaderContext.Provider>
    )
}


// ///////////////////////////////////
// Export
export default ProgrammeRouter;