// ///////////////////////////////////////////////////////
// Composant pour la gestion du carrousel de la galerie
// ///////////////////////////////////////////////////////
// Creation : 30/04/2022    -> CGR
// Maj : 08/08/2022 -> separation du fichier -> CGR
// Maj : 13/09/2022 -> add keyBoard actions  -> CGR
// ///////////////////////////////////////////////////////


// Imports system
import React, {useState, useEffect, useContext} from 'react';

// Import contexts
import {languageContext, programmeContext} from './../../../contexts'

// Imports src
import Button from '../../../components/button/button';
import CarrouselMedia from './carrouselMedia';


// Component
const Carrousel = ({medias, index, setIndex, isCollapsed, setIsCollapsed}) => { // Composant pour la gestion du carrousel

    // Properties
    const [mediaDOM, setMediaDOM] = useState(null)
    const [xPos, setXPos] = useState(0)
    const [dragValue, setDragValue] = useState(0) // valeur du mouvement au drag sur le carrousel
    const lang = useContext(languageContext)
    const programmeDatas = useContext(programmeContext)

    // Effects
    useEffect(() => {

        const updateIndex = (event) => {
            if (event.key == "ArrowRight" || event.key == "ArrowDown") {
                setIndex(index + 1);
            } else if (event.key == "ArrowLeft" || event.key == "ArrowUp") {
                setIndex(index - 1);
            }
        }

        document.addEventListener('keydown', updateIndex)
        return () => {document.removeEventListener('keydown', updateIndex)}


    }, [medias, index])

    useEffect(() => { // mise a jour de la liste des images
        setMediaDOM(
            medias.map((media, i) => 
                <CarrouselMedia 
                    key = {`pos-${i}`}
                    src = {media.type === "video" ? <img src = {`/public/programmes/${programmeDatas.name}/galerie/${media.preview}`}/> : media.fullName}
                    position = {i} 
                    index = {index} 
                    setIndex = {setIndex}
                    type = {media.type}
                />
            )
        )

    }, [medias, index])

    useEffect(() => { // gestion du decalage du carrousel

        const container = document.getElementById("galerie-medias-container");

        if (container != null && medias != null) {
            if (medias.length < 5) {return;} // Pas besoin de decalage si moins de 5 images
                
            if (index > medias.length-3) {
                animateCarrousel(container, 224 * (medias.length-5));
            } else if (index > 2 && index < medias.length-2) {
                animateCarrousel(container, 224 * (index-2));
            } else {
                animateCarrousel(container, 0);
            }
        }
    }, [index])

    // Event
    const handleWheel = (e) => { // Gestion du scroll sur le carrousel

        if (e.deltaY < 0) {
            setIndex(index-1);
        } else {
            setIndex(index+1);
        }
    }

    const handleDragStart = (e) => {
        e.preventDefault();
        setXPos(e.clientX);
    }

    const handleDrag = (e) => { // Recuperation de la valeur du mouvement au drag sur le carrousel

        e.preventDefault();

        const container = document.getElementById("galerie-medias-container");
        let startPos = parseInt(container.style.transform.slice(11,-3)); //TO DO : trouver une meilleure facon
        if (isNaN(startPos)) { startPos = 0; } // On securise le startPos si aucun transform n'a encore ete fait

        const currentPos = e.clientX;
        if (Math.abs(currentPos - xPos) < 200) {
            setXPos(e.clientX);
            setDragValue(e.nativeEvent.offsetX);
            if (startPos - (currentPos - xPos) >= 0 || startPos - (currentPos - xPos) <= -(224 * (medias.length-5))) {return;}
            container.style.transform = `translateX(${startPos - (currentPos - xPos)}px)`;
        }
    }

    const handleDragStop = (e) => {
        setDragValue(0);
        setIndex(index + Math.floor(dragValue / 224));
    }

    // Animations
    const animateCarrousel = (who, to) => {

        let startPos = Math.abs(parseInt(who.style.transform.slice(11,-3))); //TO DO : trouver une meilleure facon

        if (isNaN(startPos)) { startPos = 0; } // On securise le startPos si aucun transform n'a encore ete fait

        let current = 0;
        let operation = 5
        
        if (to < startPos) {operation = -5}

        if (to != startPos) {
            const animation = setInterval(() => {
                current = current + operation;
                who.style.transform = `translateX(-${startPos + current}px)`;
    
                if (operation === 5 && startPos + current >= to) {
                    who.style.transform = `translateX(-${to}px)`;
                    clearInterval(animation)
                }
                if (operation === -5 && startPos + current <= to) {
                    who.style.transform = `translateX(-${to}px)`;
                    clearInterval(animation)
                }
            }, 2)
        }
    }

    // Rendu
    return (
        <div id = 'galerie-carrousel'>

            <div id = 'galerie-carrousel-collapse' className = {`collapser ${isCollapsed && "fullScreen"}`} onClick = {() => {setIsCollapsed(c => !c)}}>
            </div>

            <div id = "galerie-prev" className = {`galerie-btn ${(index == 0) ? 'disabled' : ''} ${isCollapsed && "fullScreen"}`} onClick = {() => setIndex(index - 1)}>
                <Button text = {programmeDatas.languagesDatas[lang].galerie.carroussel.precedent} icon = 'fleche-l'/>
            </div>

            <div className = {`${isCollapsed ? "collapsed" : "displayed"}`} id = 'galerie-medias' onWheel = {handleWheel} onDragEnter = {handleDragStart} onDrag = {handleDrag} onDragEnd = {handleDragStop}>
                <div id = 'galerie-medias-container'>
                    {mediaDOM}
                </div>
            </div>

            <div id = "galerie-next" className = {`galerie-btn ${(index == medias.length-1) ? 'disabled' : ''} ${isCollapsed && "fullScreen"}`} onClick = {() => setIndex(index + 1)}>
                <Button text = {programmeDatas.languagesDatas[lang].galerie.carroussel.suivant} icon = 'fleche-r'/>
            </div>

        </div>
    )
}


export default Carrousel;
