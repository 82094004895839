// /////////////////////////////////////////////////////////////
// Fichier pour la gestion du menu principal
// /////////////////////////////////////////////////////////////
// Creation :    -> CGR
// /////////////////////////////////////////////////////////////



// /////////////////////////////////////////////////////////////
// Imports 

// Imports system
import React, {useState, useEffect, useContext} from 'react';

// Import context
import { programmeContext, pageContext, noHeaderContext } from './../../contexts';

// Imports src
import MainTopNavigation from './mainTopNavigation/mainTopNavigation'
import ToggleNavigation from './mainToggleNavigation/mainToggleNavigation';
import MainBottomNavigation from './mainBottomNavigation/mainBottomNavigation';


// /////////////////////////////////////////////////////////////
// Component

const MainNavigation = () => {

    // Properties
    const [logoPromoteur, setLogoPromoteur] = useState(""); // Chemin du logo de bas de menu
    const contextDatas = useContext(programmeContext); // Context pour donnees programme
    const noHeader = useContext(noHeaderContext);


    
    // Effects
    useEffect(() => { // Gestion du logo promoteur au chargement du component
        if (contextDatas != null) {
            if (contextDatas.client.name !== undefined && contextDatas.client.icone !== undefined) {
                setLogoPromoteur(`${contextDatas.client.name}/logo/${contextDatas.client.icone}`)
            } else {
                setLogoPromoteur(null)
            }
        }
    }, [contextDatas])


    if (window.location.href.includes("/lot/")) return null

    // Rendu
    return (
        <pageContext.Consumer>
            {page => page != null && 
                <div id = 'mainNavigation' className = {`pos-${page.headerLevel} ${noHeader ? 'noHeader' : ''}`}>
                    
                    {/* Partie haute du menu -> logos, retours, informations lots*/}
                    <MainTopNavigation />

                    {/* Partie centrale du menu -> navigation entres les pages */}
                    <ToggleNavigation lvl = {page.headerLevel}></ToggleNavigation>

                    {/* Partie basse du menu -> logos, retours, informations lots*/}
                    <MainBottomNavigation logoPromoteur={logoPromoteur} />

                </div>
            }
        </pageContext.Consumer>
    )
}


// /////////////////////////////////////////////////////////////
// Export
export default MainNavigation;
