// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion de la liste des lots en favoris
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 09/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSortAlphaAsc, 
    faSortNumericAsc, 
    faSortAlphaDesc, 
    faSortNumericDesc,
    faArrowDown, 
} from '@fortawesome/free-solid-svg-icons'

// Imports contexts
import {programmeContext, languageContext} from './../../../../contexts';




// ////////////////////////////////////////////////////////////////////////////////
// Components

const FavListActions = ({
    sortDirection, 
    setSortDirection,
    currentSort, 
    setCurrentSort
}) => {

    const [displaySorter, setDisplaySorter] = useState(false);
    const lang = useContext(languageContext)
    const programmeDatas = useContext(programmeContext)

    return (
        <programmeContext.Consumer>
            {datas => datas != null && 
                <div id = 'comparer-form'>
                    {/* <div className = 'comparerBtn' id = 'comparer-send'>
                        <p>Envoyer</p>
                        <SvgIcon icon='envoyer'></SvgIcon>
                    </div> */}

                    <div className = {`comparerBtn ${displaySorter ? 'open' : ""}`} id = 'comparer-sort' onClick={() => {setDisplaySorter(d => !d)}}>
                        <p>{datas.languagesDatas[lang].comparer.trier.titre}</p>
                        <FontAwesomeIcon icon={faArrowDown} color={datas.color}/>

                        <div id='comparer-sort-list' className={`${displaySorter ? 'displayedRollout' : "hidden"}`}>
                            <div 
                                className={`sort-option ${currentSort == "Alphabetique" && "active"}`} 
                                onClick={() => {
                                    if (currentSort == "Alphabetique") {
                                        setSortDirection(d => d == "antiAlpha" ? "" : "antiAlpha");
                                    } else {
                                        setSortDirection("");
                                        setCurrentSort("Alphabetique");
                                    }
                                }}
                            >
                                {datas.languagesDatas[lang].comparer.trier.alpha}
                                {sortDirection != "antiAlpha" ? 
                                    <FontAwesomeIcon icon={faSortAlphaAsc} color={"white"}/> : 
                                    <FontAwesomeIcon icon={faSortAlphaDesc} color={"white"}/>
                                }
                            </div>
                            <div 
                                className={`sort-option ${currentSort == "Surfaces" && "active"}`} 
                                onClick={() => {
                                    if (currentSort == "Surfaces") {
                                        setSortDirection(d => d == "antiSurface" ? "" : "antiSurface");
                                    } else {
                                        setSortDirection("");
                                        setCurrentSort("Surfaces");
                                    }
                                }}
                            >
                                {datas.languagesDatas[lang].comparer.trier.surfaces}
                                {sortDirection != "antiSurface" ? 
                                    <FontAwesomeIcon icon={faSortNumericAsc} color={"white"}/> : 
                                    <FontAwesomeIcon icon={faSortNumericDesc} color={"white"}/>
                                }
                            </div>
                            <div 
                                className={`sort-option ${currentSort == "Etages" && "active"}`} 
                                onClick={() => {
                                    if (currentSort == "Etages") {
                                        setSortDirection(d => d == "antiEtage" ? "" : "antiEtage");
                                    } else {
                                        setSortDirection("");
                                        setCurrentSort("Etages");
                                    }
                                }}
                            >
                                {datas.languagesDatas[lang].comparer.trier.etages}
                                {sortDirection != "antiEtage" ? 
                                    <FontAwesomeIcon icon={faSortNumericAsc} color={"white"}/> : 
                                    <FontAwesomeIcon icon={faSortNumericDesc} color={"white"}/>
                                }
                            </div>
                        </div>

                    </div>
                </div>
        }</programmeContext.Consumer>
    )
}

export default FavListActions;