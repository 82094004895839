// /////////////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion d'une vignette de la liste des lots favoris pour la page comparer
// /////////////////////////////////////////////////////////////////////////////////////////
// Creation : 23/06/2022        -> CGR
// /////////////////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useContext, useEffect, useState } from "react";

// Import Context
import {
  comparerContext,
  favorisContext,
  languageContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
} from "./../../../../contexts";

// Import Component
import SvgIcon from "./../../../../components/svg/svg";

const LotFavoris = ({ name, type, surface, etage, current = [] }) => {
  const programmeName = useContext(programmeNameContext);
  const programmeDatas = useContext(programmeContext);
  const lang = useContext(languageContext);
  const favoris = useContext(favorisContext);
  const lotsDatas = useContext(programmeLotsContext);
  const [source2D, setSource2D] = useState(""); // source de la vignette

  const removeFromFavs = () => {
    favoris.setFavs(name);
  };

  useEffect(() => {
    if (programmeName && lotsDatas) {
      // Gestion de la source 2D
      if (lotsDatas.lots.find((x) => x.name === name).haveVignette) {
        setSource2D(
          `/public/programmes/${programmeName}/lots/${name}/vignette/vignette.png`
        );
      } else if (
        lotsDatas.lots.find((x) => x.type === "Plateau" || x.type === "Bureau")
      ) {
        setSource2D(`/public/images/Bureau.png`);
      } else {
        setSource2D(
          `/public/images/T${
            lotsDatas.lots.find((x) => x.name === name).numPieces
          }.png`
        );
      }
    }
  }, [programmeName, lotsDatas]);

  return (
    <programmeLotsContext.Consumer>
      {(lotsContext) =>
        lotsContext && (
          <comparerContext.Consumer>
            {(comparerDatas) => (
              <div
                className={`comparer-result ${
                  current.includes(name) == true && "mainBackground"
                }`}
                draggable={true}
                onDragStart={() => {
                  comparerDatas.setDraggingOne(name);
                }}
                onTouchStart={() => {
                  comparerDatas.setDraggingOne(name);
                }}
                onTouchEnd={(e) => {
                  const targ = document.elementFromPoint(
                    e.changedTouches[0].pageX,
                    e.changedTouches[0].pageY
                  );
                  if (targ.className == "compColumn") {
                    if (targ.id.includes("-A")) {
                      comparerDatas.setCurrentLotA(
                        lotsContext.lots[comparerDatas.draggingOne]
                      );
                      comparerDatas.setDraggingOne(null);
                    } else {
                      comparerDatas.setCurrentLotB(
                        lotsContext.lots[comparerDatas.draggingOne]
                      );
                      comparerDatas.setDraggingOne(null);
                    }
                  }
                }}
              >
                <img className="lot-image" src={source2D}></img>
                <div className="lot-infos">
                  <div className="ligne-info lot-detail">
                    <h4 className="lot-name">{name}</h4>
                    {programmeDatas.displayStatus && (
                      <div
                        className={`lot-status ${
                          "Disponible" === "Disponible"
                            ? "mainBackground"
                            : "unable"
                        }`}
                      >
                        {
                          programmeDatas.languagesDatas[lang].lots.statut[
                            "Disponible"
                          ]
                        }
                      </div>
                    )}
                  </div>

                  <div className="ligne-info lot-type">
                    <p className="lot-label">
                      {programmeDatas.languagesDatas[lang].rechercher.lot.type}
                    </p>
                    <p className="lot-value">{type}</p>
                  </div>

                  <div className="ligne-info lot-etage">
                    <p className="lot-label">
                      {programmeDatas.languagesDatas[lang].rechercher.lot.etage}
                    </p>
                    <p className="lot-value">
                      {etage?.replace(
                        "Etage",
                        programmeDatas.languagesDatas[lang].maquette.palette
                          .etages
                      )}
                    </p>
                  </div>

                  <div className="ligne-info lot-surface">
                    <p className="lot-label">
                      {
                        programmeDatas.languagesDatas[lang].rechercher.lot
                          .surface
                      }
                    </p>
                    <p className="lot-value">{surface}</p>
                  </div>
                </div>
                <div className="del-fav" onClick={() => removeFromFavs()}>
                  <SvgIcon icon="bin"></SvgIcon>
                </div>
              </div>
            )}
          </comparerContext.Consumer>
        )
      }
    </programmeLotsContext.Consumer>
  );
};

export default LotFavoris;
