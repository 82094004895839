import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { programmeContext } from "./../../../contexts";
import TemoinNavigationContent from "./TemoinNavigationContent";

const Redirect = ({ where }) => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate(where);
  }, []);
};

const TemoinNavigation = () => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const programmeDatas = useContext(programmeContext);
  const temoinsDatas = Object.fromEntries(
    Object.entries(programmeDatas.pagesDetails.interieurs.temoins).sort(
      (a, b) => a[1].order - b[1].order
    )
  );

  return (
    <div>
      <Routes>
        <Route
          path=""
          element={
            <Redirect
              where={`/${programmeDatas.urlName}/interieurs/temoins/${
                Object.values(temoinsDatas)[0].realName
              }${search}`}
            />
          }
        />
        {!searchParams.get("isolate") &&
          <Route path=":currentInt" element={<TemoinNavigationContent />} />
        }
      </Routes>
    </div>
  );
};

export default TemoinNavigation;
