// ///////////////////////////////////////////////////////
// Composant pour la gestion de la page projet
// ///////////////////////////////////////////////////////
// Creation : 25/05/2022    -> CGR
// Maj : 08/08/2022 -> remplacement du fetch par context
// ///////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, {useState, useEffect, useContext, useRef} from 'react';

// Import context
import { pageContext, pagesContext, languageContext } from '../../contexts';



// ///////////////////////////////////
// Component
const Projet = () => { 

    // Properties
    const [textClass, setTextClass] = useState('descriptionTextCollapsed') // Etat d'affichage du bandeau de texte
    const { setCurrent, setHeaderLevel } = useContext(pageContext)
    const lang = useContext(languageContext)
    const pageDatas = useContext(pagesContext)
    const olreadyChecked = useRef(false)


    useEffect(() => {
        if (olreadyChecked.current) {return;}
        olreadyChecked.current = true;

        setHeaderLevel(1);
        setCurrent("projet");
        setTimeout(() => { // Pour les chargement un peu lent, test un peu plus tardif
            setCurrent("projet");
        }, (200));

        setTextClass('descriptionTextExtended');

        const imgBg = document.querySelector("#descriptionImg");
        if (imgBg) {

            const handleSize = () => {
                detectRatio(imgBg);
            }
            window.addEventListener('resize', handleSize);
            detectRatio(imgBg);

            return(() => {
                window.removeEventListener('resize', handleSize);
                setTextClass('descriptionTextCollapsed');
            })
        }

    }, [pageDatas])

    // Fonctions 
    const detectRatio = (image) => { // Fonction de detection des ratios

        const wx = document.getElementById('description').clientWidth;
        const wy = document.getElementById('description').clientHeight;
        const windowRatio = wx / wy;
        const getMeta = (url, callback) => {

            const img = new Image();
            img.src = url;
            img.onload = function() { 
                callback(this.width, this.height); 
            }
        }
        getMeta(
            image.src, 
            (width, height) => {
                if (windowRatio < width/height) {
                    image.className = 'fromHeight';
                    image.style.transform = `translate(${-((width * (wy/height)) - wx)/2}px, ${0}px)`;
                } else {
                    image.className = 'fromWidth';
                    image.style.transform = `translate(${0}px, ${-((height * (wx/width)) - wy)/2}px)`;
                }
            }
        )
    }
    
    return (
        (pageDatas && pageDatas.projet) ?
            <div id = 'description'>
                <div id = 'descriptionTextZone' className = {textClass}>
                <div id = 'descriptionContent'>
                        <h1 id = 'descriptionTitle'>{pageDatas.projet.textsContent[lang].title}</h1>
                        <div id = 'descriptionTextScroll' dangerouslySetInnerHTML={{__html:pageDatas.projet.textsContent[lang].text}}>
                        </div>
                    </div>  
                </div>
            
                <div id = 'descriptionImgZone'>
                    {pageDatas.projet.src}
                </div>
            </div>
        :
            <>
            </>
    )
}


// ///////////////////////////////////
// Export
export default Projet;
