// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion des boutons standards de la V6
// ////////////////////////////////////////////////////////////////////////////////
// Creation :   29/05/2022 CGR
// update :     retrait du fetch, passage en parametres
// ////////////////////////////////////////////////////////////////////////////////

// //////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState } from "react";

// Import components
import SvgIcon from "./../svg/svg";

// Bouton
const Button = ({
  icon,
  text,
  state = false,
  action = () => {},
  displayText = "with-hover",
}) => {
  // propreties
  const [activ, setActive] = useState(false);

  // effects
  useEffect(() => {
    // verification de l'etat du bouton
    setActive(() => (state === true ? "activ" : ""));
  }, [state]);

  // rendu
  return (
    <button className={`bouton ${displayText} ${activ}`} onClick={action}>
      <SvgIcon icon={icon} />
      <p>{text}</p>
    </button>
  );
};

export default Button;
