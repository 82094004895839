// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du bandeau VB + cookies
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 30/05/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////



// //////////////////////////////////
// Imports

// Imports system
import React from 'react';

// Imports contexts
import { domain } from './../../contexts';

// Bouton
const Credits = () => {

    // rendu
    return (
        <div id = 'credits'>
            <p>powered by</p>
            <img src={`/public/images/logo-vb.jpg`}></img>
        </div>
    )
}

export default Credits;
