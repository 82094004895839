// ///////////////////////////////////////////////////////////////////////////////
// Hook pour la gestion de la resolution dynamique suivant la vitesse de rotation
// ///////////////////////////////////////////////////////////////////////////////
// Creation 26/04/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////


// Imports system
import React, {useState} from 'react';


const useResolution = (maxResolution, finalResolution, resolutions) => {

    // Properties
    const [resolution, setResolution] = useState(maxResolution ? maxResolution : resolutions[resolutions.length-1]);

    // functions
    const optimizedResolution = (power) => { 

        setResolution(() => {
            if (!maxResolution) {
                return resolutions[resolutions.length-1];
            }
            if (power == 0) {
                return finalResolution;
            } else if (power <= 3) {
                if (resolutions[power-1] > maxResolution) {
                    return maxResolution != 0 ? maxResolution : resolution;
                }
                return resolutions[power-1];
            } else {
                return resolutions[resolutions.length-1];
            }
        })}

    const callLoadHd = () => {
        setResolution(finalResolution);
    }

    // Render
    return [resolution, optimizedResolution, callLoadHd]
}

export default useResolution;