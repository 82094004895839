// /////////////////////////////////////////////////////////////////////////////////
// Composant pour la gestion d'un lot de resultat de rechercher
// /////////////////////////////////////////////////////////////////////////////////
// Creation : 08/08/2022  CGR
// /////////////////////////////////////////////////////////////////////////////////

// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Contexts
import {
  domain,
  favorisContext,
  languageContext,
  noHeaderContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
  returnToContext,
} from "../../contexts";

// Components
import SvgIcon from "./../../components/svg/svg";

// ////////////////////////////////////////////////////////////////////////
// Composants React pour la gestion du moteur de recherche

const InfoLine = ({ text, value, classComp }) => {
  // Ligne pour info -> TODO: Passer en global
  return (
    <div className={`ligne-info ${classComp}`}>
      <p className="lot-label">{text}</p>
      <p className="lot-value">{value}</p>
    </div>
  );
};

const Lot = ({
  // Vignette de resultat
  name,
  statut,
  surface,
  prix,
  type,
  etage,
  display,
  useFavs,
  rooms = 0,
  useSmallName = false,
}) => {
  // Infos ////////////////////////////////
  // name : nom du lot -> str
  // statut : statut du lot -> str
  // surface : surface du lot -> float
  // type : type du lot -> str
  // etage : etage du lot -> str
  // //////////////////////////////////////

  // Properties
  const [source2D, setSource2D] = useState(""); // source de la vignette
  const { setReturnTo } = useContext(returnToContext);
  const programmeName = useContext(programmeNameContext);
  const lang = useContext(languageContext);
  const noHeader = useContext(noHeaderContext);
  const lotsDatas = useContext(programmeLotsContext);
  let navigate = useNavigate(); // pour la gestion des routes
  let { pathname, search } = useLocation();

  const isTypeNumber = (value) => {
    return Number.isFinite(value);
  };

  // Effects
  useEffect(() => {
    // Initialisation du component

    if (programmeName && lotsDatas) {
      // Gestion de la vignette
      fetch(`${domain}/${programmeName}/lot/name=${name}`, {
        mode: "cors",
      })
        .then((response) => response.json())
        .then((data) => {
          // Gestion de la source 2D
          if (lotsDatas.lots.find((x) => x.name === name).haveVignette) {
            setSource2D(
              `/public/programmes/${programmeName}/lots/${name}/vignette/vignette.png`
            );
          } else if (
            lotsDatas.lots.find(
              (x) => x.type === "Plateau" || x.type === "Bureau"
            )
          ) {
            setSource2D(`/public/images/Bureau.png`);
          } else {
            setSource2D(
              `/public/images/T${
                lotsDatas.lots.find((x) => x.name === name).numPieces
              }.png`
            );
          }
        });
    }
  }, [programmeName, name, lotsDatas]);

  // Render
  return (
    <programmeContext.Consumer>
      {(programmeDatas) =>
        programmeDatas && (
          <programmeNameContext.Consumer>
            {(programmeName) =>
              programmeName && (
                <returnToContext.Consumer>
                  {(returnTo) => (
                    <favorisContext.Consumer>
                      {(favsDatas) => (
                        <div className="result-lot">
                          <img className="lot-image" src={source2D}></img>
                          <div className="lot-infos">
                            <div className="ligne-info lot-detail">
                              <h4
                                className={`lot-name ${
                                  useSmallName && "small"
                                }`}
                              >
                                {name}
                              </h4>
                              {programmeDatas.displayStatus && (
                                <div
                                  className={`lot-status ${
                                    statut === "Disponible"
                                      ? "mainBackground"
                                      : "unable"
                                  }`}
                                >
                                  {
                                    programmeDatas.languagesDatas[lang].lots
                                      .statut[statut]
                                  }
                                </div>
                              )}
                            </div>

                            <InfoLine
                              text={
                                programmeDatas.languagesDatas[lang].rechercher
                                  .lot.type
                              }
                              value={
                                lang === "fr" ||
                                type === "Studio" ||
                                !isTypeNumber(type)
                                  ? type
                                  : rooms - 1
                              }
                              classComp={"lot-type"}
                            />

                            {etage != null && (
                              <InfoLine
                                text={
                                  programmeDatas.languagesDatas[lang].rechercher
                                    .lot.etage
                                }
                                value={etage.replace(
                                  "Etage",
                                  programmeDatas.languagesDatas[lang].maquette
                                    .palette.etages
                                )}
                                classComp={"lot-etage"}
                              />
                            )}

                            <InfoLine
                              text={
                                programmeDatas.languagesDatas[lang].rechercher
                                  .lot.surface
                              }
                              value={`${surface} m²`}
                              classComp={"lot-surface"}
                            />

                            {/* Prix */}
                            {prix != 0 && (
                              <InfoLine
                                text={"Prix"}
                                value={`${prix.toLocaleString("fr")} €`}
                                classComp={"lot-prix"}
                              />
                            )}

                            {/* Gestion des favoris */}
                            <div
                              className="ligne-action"
                              style={{
                                justifyContent: useFavs
                                  ? "space-between"
                                  : "flex-end",
                              }}
                            >
                              {useFavs && (
                                <div
                                  className={`action-toggleFav ${
                                    favsDatas.favs.includes(name) ? "isIn" : ""
                                  }`}
                                  onClick={() => {
                                    favsDatas.setFavs(name);
                                  }}
                                >
                                  {favsDatas.favs.includes(name) ? (
                                    <SvgIcon icon="favorisPlein" />
                                  ) : (
                                    <SvgIcon icon="favoris" />
                                  )}
                                </div>
                              )}

                              {((display !== "None" && display === "Grisés") ||
                                display === "Masqués" ||
                                (display === "Grisés sans accès au détail" &&
                                  statut === "Disponible")) && (
                                <div
                                  className="action-goToLot"
                                  onClick={() => {
                                    navigate(
                                      `/${
                                        programmeDatas.urlName
                                      }/detail-lot/${name}/plan${search}${
                                        noHeader ? "?noheader" : ""
                                      }`
                                    );
                                    returnTo.setReturnTo(
                                      pathname.replace(
                                        `/${programmeDatas.urlName}/`,
                                        ""
                                      )
                                    );
                                  }}
                                >
                                  <SvgIcon icon="fleche-r" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </favorisContext.Consumer>
                  )}
                </returnToContext.Consumer>
              )
            }
          </programmeNameContext.Consumer>
        )
      }
    </programmeContext.Consumer>
  );
};

// ////////////////////////////////////////////////////////////////////////
// Export
export default Lot;
