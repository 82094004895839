// ///////////////////////////////////////////////////////////////////////////////
// Component pour la gestion de la page programme (maquette 3D)
// ///////////////////////////////////////////////////////////////////////////////
// Creation 17/05/2022          CGR
// ///////////////////////////////////////////////////////////////////////////////




// ///////////////////////////////////
// Imports

// Imports system
import React, {useState, useEffect, useContext} from 'react';
import {
    useParams, useNavigate, useLocation
} from 'react-router-dom';

// Import context
import { 
    domain, 
    pageContext, 
    programmeContext, 
    programmeNameContext,
    maquetteContext,
    languageContext,
    noHeaderContext,
    maquetteLoaderContext
} from '../../contexts';

import Palette from '../../components/palette/palette';
import SearchNavigations from './maquetteSearch/maquetteSearch'
import MaquetteNavigation from './maquetteNavigation/maquetteNavigation';
import Player from '../../components/player/player';
import Loading from '../../components/loading/loading';
import Annotation from '../../components/annotation/annotation';
import Comparer from '../comparer/comparer';
import FullScreen from './../../layout/fullScreen/fullScreen'

// ///////////////////////////////////
// Components

const Maquette = ({preloaded,  loadHdForFrame}) => {

    // ////////////////////
    // Url parameters
    const {urlPage, urlBlock, urlFloor, urlFrame, urlLot} = useParams();
    const navigate = useNavigate();
    const loc = useLocation();

    // ////////////////////
    // Properties

    // Context
    const noHeader = useContext(noHeaderContext);
    const page = useContext(pageContext);
    const programmeName = useContext(programmeNameContext);
    const programmeSettings = useContext(programmeContext);
    const lang = useContext(languageContext)
    const maquetteLoader = useContext(maquetteLoaderContext)

    // Page
    const [current, setCurrent] = useState(urlPage != undefined ? urlPage : 'palettes');
    const [controler, setControler] = useState(null);

    // maquette
    const [startFrame, setStartFrame] = useState(null);
    const [numFrames, setNumFrames] = useState(90);
    const [focale, setFocale] = useState(40);
    const [autoPlayDelay, setAutoPlayDelay] = useState(10);
    const [autoPlaySpeed, setAutoPlaySpeed] = useState(0);
    const [resolutions, setResolutions] = useState(null);

    const [programmeDatas, setProgrammeDatas] = useState(null);
    const maquetteDatas = useContext(maquetteContext);
    const [blocks, setBlocks] = useState(null);
    const [block, setBlock] = useState(urlBlock);
    const [floors, setFloors] = useState(null);
    const [floor, setFloor] = useState(urlFloor);
    //const [specialView, setSpecialView] = useState(urlPage == "nuit" ? "_nuit" : "");
    //const [specialView, setSpecialView] = useState(urlPage == "hiver" ? "_hiver" : "");
    const [specialView, setSpecialView] = useState(urlPage == "ete" ? "_ete" : "");

    const [imgPath, setImgPath] = useState('');
    const [forcedLotHover, setForcedLotHover] = useState(urlLot);
    const [displaySearch, setDisplaySearch] = useState(false);

    const [useNight, setUseNight] = useState(false);
    const [useWinter, setUseWinter] = useState(false); 
    const [useSummer, setUseSummer] = useState(false); 

    const [annotationText, setAnnotationText] = useState(""); 

    const [maquetteExt, setMaquetteExt] = useState(null);

    const displayMaquetteNavigation = [displaySearch, useNight, useWinter, useSummer].some(e => e === true);

    // ////////////////////
    // Fonctions

    const buildProgrammeDatas = (data) => { // Fonction de lecture de l'api et de traduction pour coller avec le besoin du moteur

        const obj = {};
        for (let block of Object.keys(data.blocks)) {
            obj[block] = {};
            obj[block]['view'] = [];
            obj[block]['top'] = 'Aerien';
            obj[block]['view']['Aerien'] = {};
            obj[block]['view']['Aerien'].frames = data.blocks[block].views['Aerien'].frames;
            obj[block]['view']['Aerien'].focale = data.blocks[block].views['Aerien'].focale;

            if (data.blocks[block].views['Pieton']) {
                obj[block]['bottom'] = 'Pieton';
                obj[block]['view']['Pieton'] = {};
                obj[block]['view']['Pieton'].frames = data.blocks[block].views['Pieton'].frames;
                obj[block]['view']['Pieton'].focale = data.blocks[block].views['Pieton'].focale;
            }

            if (data.blocks[block].views['Decoupe']) {
                obj[block]['etages'] = [];
                obj[block]['view']['Decoupe'] = {};
                obj[block]['view']['Decoupe'].frames = data.blocks[block].views['Decoupe'].frames;
                obj[block]['view']['Decoupe'].focale = data.blocks[block].views['Decoupe'].focale;
                for (let etage of data.blocks[block].floors) {
                    if (etage.display) {
                        obj[block]['etages'].push(etage.name)
                    }
                }
            } else {
                obj[block]['etages'] = [];
            }

            //INFO: Commenté car nous n'aurons pas de découpe pour étages spéciaux, mais laissé au cas où
            //DESC: Indique la présence de découpe pour les étages spéciaux
            //for (let v in data.blocks[block].views){
            //    if (v.includes('Decoupe_')){
            //        obj[block]['view'][v] = {};
            //        obj[block]['view'][v].frames = data.blocks[block].views[v].frames;
            //        obj[block]['view'][v].focale = data.blocks[block].views[v].focale;
            //    }
            //}
        }
        if (Object.keys(data).includes("complexe")) {
            obj[data.complexe.name] = {};
            obj[data.complexe.name]['view'] = [];
            obj[data.complexe.name]['top'] = 'Aerien';
            obj[data.complexe.name]['view']['Aerien'] = {};
            obj[data.complexe.name]['view']['Aerien'].frames = data.complexe.views['Aerien'].frames;
            obj[data.complexe.name]['view']['Aerien'].focale = data.complexe.views['Aerien'].focale;

            if (data.complexe.views['Pieton']) {
                obj[data.complexe.name]['bottom'] = 'Pieton';
                obj[data.complexe.name]['view']['Pieton'] = {};
                obj[data.complexe.name]['view']['Pieton'].frames = data.complexe.views['Pieton'].frames;
                obj[data.complexe.name]['view']['Pieton'].focale = data.complexe.views['Pieton'].focale;
            }
            obj[data.complexe.name]['etages'] = [];
        }
        return obj;
    }

    const validateOptions = () => { // Fonction pour assurer que les choix faits sont correct avant d'afficher l'image

        // Virification initiale de la presence de donnees
        if (blocks === null || floors === null || floors === undefined || block === undefined || block === "undefined") {return;}

        // On s'assure que l'etage en cours et bien dans la liste des etages du block
        if (floor == undefined || (!floors.etages.includes(floor) && (floors.top != (floor)) && (floors.bottom != (floor)))) {
            setFloor(floors.top) // On force le passage en aerien si l'etage n'est pas valide
        } else { // Dans le cas ou l'etage est correct on met a jour le player

            // Gestion des infos player
            if (floor == floors.top) {
                setNumFrames(programmeDatas[block].view[floors.top].frames);
                setFocale(programmeDatas[block].view[floors.top].focale);
            } else if (floor == floors.bottom) {
                setNumFrames(programmeDatas[block].view[floors.bottom].frames);
                setFocale(programmeDatas[block].view[floors.bottom].focale);
            } else {
                setNumFrames(programmeDatas[block].view['Decoupe'].frames);
                setFocale(programmeDatas[block].view['Decoupe'].focale);
            }
            buildPath();
        }
    
        setUrl();
    }

    const setUrl = (frame = null) => { // Mise en place de la route
        
        let lot = '';
        if (forcedLotHover != undefined) {
            lot = `/lot=${forcedLotHover}`
        }

        if (block == null || block == undefined || block == 'undefined') {return;}
        if (floor == null || floor == undefined || floor == 'undefined') {return;}

        navigate(`/${programmeSettings.urlName}/programme/${current}/${block}/${floor}${lot}${loc.search}`);

    }

    const buildPath = () => { // Mise en place du lien img
        setImgPath(`${block}/${floor}${specialView}`)
    }



    // ////////////////////
    // Effects
    useEffect(() => { // Effect d'ouverture de la page -> appel de l'api
        if (programmeName && programmeSettings && maquetteDatas) {
            page.setCurrent("programme"); // maj du menu
            page.setHeaderLevel(1); // mise a jour du decalage du header

            setAutoPlayDelay(maquetteDatas.global.autoPlayDelay);
            setAutoPlaySpeed(maquetteDatas.global.useAutoPlay ? maquetteDatas.global.autoPlaySpeed : 0);
            if (urlFrame == undefined) {setStartFrame(maquetteDatas.global.firstFrame)}

            setBlocks({
                'top' : Object.keys(maquetteDatas).includes("complexe") && maquetteDatas.complexe.visible ? maquetteDatas.complexe.name : null, 
                'std' : Object.keys(maquetteDatas.blocks).filter(x => maquetteDatas.blocks[x].visible)
            });

            setResolutions(maquetteDatas.global.resolutions);
            setProgrammeDatas(buildProgrammeDatas(maquetteDatas));   
            setDisplaySearch(maquetteDatas.global.haveLots && maquetteDatas.global.nbLots > 1);
            setUseNight(maquetteDatas.global.useNightVue);
            setUseWinter(maquetteDatas.global.useWinterVue);
            setUseSummer(maquetteDatas.global.useSummerVue);

            setAnnotationText(maquetteDatas.global.annotationText);
        }

    }, [programmeName, programmeSettings, maquetteDatas])

    useEffect(() => {
        if (blocks === null || floors === null || floors === undefined) {return;} // securite chargement
        switch (current) {
            case 'palettes':
                setControler(
                    <div id = 'playerNavigation'>
                        {(blocks.std.length > 1 || blocks.top != undefined ) &&
                            <div id = "blockSelector">
                                <Palette 
                                    list = {blocks.std} 
                                    current = {block} 
                                    setCurrent = {setBlock} 
                                    fixedTop = {blocks.top ? {value : blocks.top, label : programmeSettings.languagesDatas[lang].maquette.palette.generale} : blocks.top}
                                />
                            </div>     
                        }

                        {(!blocks.top || block !== blocks.top) &&

                            <div id = "floorSelector">
                                <Palette 
                                    list = {floors.etages.map(x => {return {label : x.replace("Etage", programmeSettings.languagesDatas[lang].maquette.palette.etages), value : x}})} 
                                    current = {floor} 
                                    setCurrent = {setFloor} 
                                    fixedTop = {floors.top ? {value : floors.top, label : programmeSettings.languagesDatas[lang].maquette.palette.aerien} : floors.top}
                                    fixedBottom = {floors.bottom ? {value : floors.bottom, label : programmeSettings.languagesDatas[lang].maquette.palette.pieton} : floors.bottom}
                                />
                            </div> 
                        }
                    </div>
                );
                break;
        
            case 'rechercher':
                setControler(
                    <SearchNavigations
                        manyBlocks={blocks.std.length > 1}
                        block={block} 
                        setBlock={setBlock} 
                        floor={floor} 
                        setFloor={setFloor} 
                        setForcedLotHover = {setForcedLotHover}
                    />
                );
                break;
            case 'nuit':
            case 'hiver':
            case 'ete':
                setControler(
                    <div id = 'playerNavigation'>
                        {(blocks.std.length > 1 || blocks.top != undefined ) &&
                            <div id = "blockSelector">
                                <Palette 
                                    list = {blocks.std} 
                                    current = {block} 
                                    setCurrent = {setBlock} 
                                    fixedTop = {blocks.top}
                                />
                            </div>     
                        }
                        <div id = "floorSelector">
                            <Palette 
                                //list = {floors.view.hasOwnProperty("Decoupe_"+current) ? floors.etages : []}  // Ajusté pour gérer les étages
                                list = {[]} 
                                current = {floor} 
                                setCurrent = {setFloor} 
                                fixedTop = {floors.top ? {value : floors.top, label : programmeSettings.languagesDatas[lang].maquette.palette.aerien} : floors.top}
                                fixedBottom = {floors.bottom ? {value : floors.bottom, label : programmeSettings.languagesDatas[lang].maquette.palette.pieton} : floors.bottom}
                            />
                        </div> 
                    </div>
                );
                break
            default:
                break;
        }
    }, [blocks, block, floors, floor, current, loadHdForFrame.current])

    useEffect(() => { // Mise a jour de l'url au changement de page
        validateOptions();
    }, [current])

    useEffect(() => { // Mise a jour de l'url au changement de page
        if (floor === undefined || floor === "undefined") {return}
        validateOptions();
    }, [floor])
    
    useEffect(() => {
        if (blocks !== null && (block === undefined || block === 'undefined')) {
            setBlock(blocks.top != null ? blocks.top : blocks.std[0])
        }
    }, [blocks])

    useEffect(() => { // Au changement de batiment, reset de la liste des etages
        if (block === 'undefined') {return;}
        setFloors(null)
    }, [block])

    useEffect(() => { // Au changement de vue special
        validateOptions();
    }, [specialView])

    useEffect(() => { // Mise a jour de la liste d'etages
        if (programmeDatas === null) {return;} // securite chargement

        if (floors === null || floors === undefined) { // si la liste d'etages est nulle, on la met a jour
            setFloors(programmeDatas[block])
        } else { // 
            validateOptions();
        }
    }, [programmeDatas, floors])

    useEffect(() => {
        setUrl();
    }, [forcedLotHover])

    // ////////////////////
    // Render
    return (
        <div id = "programme">    
            {(startFrame != null && maquetteLoader.loadingDatas.maquette[block] && maquetteLoader.loadingDatas.maquette[block][floor] && maquetteLoader.loadingDatas.maquette[block][floor].frames['156']) &&
                <Player 
                    programme = {programmeName} 
                    navUrl = {imgPath}
                    block = {block} 
                    setBlock = {setBlock} 
                    floor = {floor} 
                    numFrames = {maquetteLoader.loadingDatas.maquette[block][floor].state === "fixe" ? 1 :maquetteLoader.loadingDatas.maquette[block][floor].frames['156'].length}
                    focale = {focale}
                    alreadyOveredLot = {forcedLotHover}
                    setAlreadyOveredLot = {setForcedLotHover}
                    start = {startFrame}
                    preloaded = {isNaN(parseInt(maquetteLoader.loadingDatas.maquette[block][floor].state)) ? 156 : parseInt(maquetteLoader.loadingDatas.maquette[block][floor].state)}
                    resolutions = {resolutions}
                    loadHdForFrame = {loadHdForFrame}
                    autoPlayDuration = {autoPlayDelay}
                    autoPlaySpeed = {autoPlaySpeed}
                    setMaquetteExt = {setMaquetteExt}
                />
            } 
            

            <div id = 'programmeNavigation' className = {`${noHeader ? "no-header" : ""}`}>
                {displayMaquetteNavigation &&
                    <MaquetteNavigation 
                        current = {current} 
                        setCurrent = {setCurrent} 
                        floor = {floor}
                        setFLoor = {setFloor}
                        specialView = {specialView}
                        setSpecialView = {setSpecialView}
                        displaySearch = {displaySearch}
                        useNight = {useNight}
                        useWinter = {useWinter}
                        useSummer = {useSummer}
                    />
                }
                
                   {noHeader &&
                    <div id='noHeaderFullScreen'>
                        <FullScreen />
                    </div>}

                <div id = 'programmeControls' className={`${!displayMaquetteNavigation && "small"}`}>
                    {controler}
                </div>
            </div>

            {(!maquetteLoader.loadingDatas.maquette[block] || !maquetteLoader.loadingDatas.maquette[block][floor] || maquetteLoader.loadingDatas.maquette[block][floor].state === "empty") &&
                <Loading 
                    text = {programmeSettings.languagesDatas[lang].maquette.chargement.messageLine1} 
                    subLine = {programmeSettings.languagesDatas[lang].maquette.chargement.messageLine2} 
                />
            }

            {(maquetteLoader.loadingDatas.maquette[block] && maquetteLoader.loadingDatas.maquette[block][floor] && maquetteLoader.loadingDatas.maquette[block][floor].state === "fixe") &&
                <p style = {{
                    position : "absolute",
                    right : "20px",
                    bottom : "30px",
                    zIndex : 1000,
                    color : "white",
                    fontFamily : "BarlowCondensed-SemiBold"
                }}>Mise en place de la rotation. Patientez...</p>
            }


            {annotationText !== "" &&
                <Annotation text={annotationText}/>
            }

            {maquetteExt}

            {current === "comparer" &&
                <div id = "playerComp">
                    <Comparer/>
                </div>
            }
            
        </div>
    )
}



// ///////////////////////////////////
// Exports
export default Maquette;