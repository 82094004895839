// ///////////////////////////////////////////////////////////////////////////////
// Composant principal pour le moteur de maquette/pdv 360
// /!\ Se compose de multiples sous composant et hook pour fonctionner
// /!\ Le player ne s'occupe pas de naviger d'un etage a un autre, il ne fait que la rotation
// et les interactions 3D pour une suite d'image
// ///////////////////////////////////////////////////////////////////////////////
// Creation 29/05/2022          CGR
// Maj 13/12/2022               CGR -> Modification du fonctionnement pour meilleur gestion tactile
// ///////////////////////////////////////////////////////////////////////////////

import { Canvas } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import Button from "../button/button";
import SvgIcon from "../svg/svg";
import {
  jsonContext,
  maquetteContext,
  maquetteLoaderContext,
  programmeLotsContext,
  programmeContext,
} from "./../../contexts";
import Exterieurs from "./../../pages/exterieurs/exterieurs";
import useResolution from "./hooks/useResolution";
import ThreeJsPlayer from "./player-3D";

// ////////////////////////////////////////////
// Custom Function -->
const getNavigator = () => {
  const nav = window.navigator.userAgent;

  if (nav.includes("Firefox")) {
    return "firefox";
  } else {
    return "chrome";
  }
};


const PlayerBoussole = ({type, numFrames, frame}) => {

  const programmeDatas = useContext(programmeContext)
  const maquetteDatas = useContext(maquetteContext)

  if ((["maquette", "localiser"].includes(type) && maquetteDatas.global.useCompass) || (type === "lots" && programmeDatas.lots.global.useCompass)) {
    return <div
      id="player-boussole"
      style={{
        rotate: `${
          maquetteDatas.global.compassAngle + (360.0 / numFrames) * -frame
        }deg`,
      }}
    >
      <SvgIcon icon={"boussole"} />
    </div>
  }
}

const Player = ({
  programme,
  navUrl,
  type = "maquette",
  block = null,
  floor = null,
  alreadyOveredLot = null,
  setAlreadyOveredLot = null,
  filterOne = undefined,
  setBlock = () => {},
  start = 0,
  numFrames = 90,
  focale = 40,
  preloaded = 2160,
  resolutions = [1080, 450, 156],
  autoPlayDuration = 10,
  autoPlaySpeed = 0,
  loadHdForFrame = null,
  setMaquetteExt = () => {},
}) => {
  // //////////////////////////////
  // Properties

  const playerRef = useRef(null) // Ref vers player
  const canvasRef = useRef(null) // Ref vers canvas 2D
  const canvas3DRef = useRef(null)

  const url = useLocation()

  const location = url.pathname.split("/")[4]

  const programmeLots = useContext(programmeLotsContext) // lots du programme
  const { loadingDatas } = useContext(maquetteLoaderContext)

  const maquetteDatas = useContext(maquetteContext)
  const jsonDatas = useContext(jsonContext)
  const standardRatio = 1920 / 1080; // ratio standard

  const [frame, setFrame] = useState(start);
  const [zoom, setZoom] = useState(1); // Zoom sur le player
  const [resolution, setResolution, callLoadHd] = useResolution(
    preloaded,
    resolutions[0],
    resolutions.slice(1, resolutions.length - 1)
  );
  const [imgStyle, setImgStyle] = useState("fromHeight"); // alignement du media
  const [puces, setPuces] = useState([]); // liste des puces
  const [playerToolTip, setPlayerToolTip] = useState(null); // reference au toolTip de lot
  const [player3D, setPlayer3D] = useState(null); // Reference au component Player3D
  const [render, setRender] = useState(null); // image a rendre
  const [offsetWidth, setOffsetWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [sizeWidth, setSizeWidth] = useState(0);
  const [sizeHeight, setSizeHeight] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false); // utilisation ou non de la rotation automatique
  const [inactivTime, setInactivTime] = useState(0); // temps sans action utilisateur

  const [currentSpeed, setCurrentSpeed] = useState(0); // vitesse de la rotation
  const [startSpeed, setStartSpeed] = useState(0); // vitesse au lancement d'une rotation
  const [rotation, setRotation] = useState((start / numFrames) * 360);

  const isDraggingOnMouse = useRef(false);
  let clientX = 0;
  let accStartTime = null;
  let accStartX = null;
  let targetRotation = null;
  let accStartDir = false;
  let direction = true;
  const [directionSt, setDirectionSt] = useState("droite");

  const mouseX = useRef(0);
  const mouseXOnMouseDown = useRef(0);
  const targetRotationOnMouseDown = useRef(frame);
  const navigator = getNavigator();

  // //////////////////////////////
  // Fonctions

  const handleWheel = (e) => {
    // Gestion du zoom
    e.preventDefault();
    setPlayerToolTip(null);
    if (e.deltaY > 0) {
      setZoom((z) => (z - 0.05 > 0.8 ? z - 0.05 : 0.8));
    } else {
      setZoom((z) => (z + 0.05 < 2.2 ? z + 0.05 : 2.2));
    }
  };

  const addExtPageFromPoi = (initialView) => {
    setMaquetteExt(
      <div id="playerExt">
        <Exterieurs fromMaquette={true} initialView={initialView} />
        <div id="closeVueExt" onClick={() => setMaquetteExt(null)}>
          <Button
            icon="fleche-l"
            text="retour"
            state={true}
            displayText="no-hover"
          />
        </div>
      </div>
    );
  };

  const update3D = () => {
    // Fonction pour la mise a jour du player3D
    // Mise en place du three
    if (
      (type === "maquette" || type === "localiser") &&
      programmeLots &&
      jsonDatas
    ) {
      setPlayer3D(
        <Canvas
          id="player-3D"
          ref={canvas3DRef}
          camera={{ fov: 75, position: [0, 0, 70] }}
        >
          <ThreeJsPlayer
            programme={programme}
            frame={frame}
            block={block}
            setBlock={setBlock}
            floor={floor}
            focale={focale}
            setSurvol={setPlayerToolTip}
            puces={puces}
            setPuces={setPuces}
            alreadyOveredLot={alreadyOveredLot}
            filterOne={filterOne}
            lots={programmeLots}
            zoom={zoom}
            setExtActiv={addExtPageFromPoi}
            maquetteDatas={maquetteDatas}
            jsonDatas={jsonDatas}
          />
        </Canvas>
      );
    }
  };

  const getPlayerSizes = (canvas) => {
    // Fonction pour obtenir les dimensions ecrans du player

    const player = document.getElementById("player");
    const windowRatio = player.clientWidth / player.clientHeight;

    if (windowRatio < standardRatio) {
      setImgStyle("fromHeight");
      canvas.height = player.offsetHeight;
      canvas.width = canvas.height * standardRatio;
    } else {
      setImgStyle("fromWidth");
      canvas.width = player.offsetWidth;
      canvas.height = canvas.width / standardRatio;
    }

    const offsetX = -(canvas.width * zoom - player.offsetWidth) / 2;
    const offsetY = -(canvas.height * zoom - player.offsetHeight) / 2;

    return [offsetX, offsetY, canvas.width, canvas.height];
  };

  const setPlayer3DSizes = (w, h, oW, oH) => {
    const playerZone = document.getElementById("playerZone");
    playerZone.style.transform = `translate(${oW}px, ${oH}px)`;
    const canvas3D = document.getElementById("player-3D");
    if (canvas3D) {
      canvas3D.childNodes[0].style.width = `${w}px`;
      canvas3D.childNodes[0].style.height = `${h}px`;
    }
  };

  const convertFrame = (frame) => {
    // Fonction pour obtenir le numero de frame en str
    return frame.toLocaleString("en-US", {
      minimumIntegerDigits: 3,
      useGrouping: false,
    });
  };

  // //////////////////////////////
  // Effects

  useEffect(() => {
    // gestion du resize pour garder la taille du player correct

    const updatePlayerSize = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        setImgStyle("");
        getPlayerSizes(canvas);
      }
    };

    window.addEventListener("resize", () => {
      updatePlayerSize();
    });

    if (autoPlaySpeed != 0) {
      setInterval(() => {
        setInactivTime((i) => i + 1);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    document.getElementById("player").addEventListener("wheel", handleWheel);
  }, []);

  useEffect(() => {
    if (programme) {
      const boussoleSvgs = document.querySelectorAll(".st0");
      for (const elem of boussoleSvgs) {
        elem.style.fill = maquetteDatas.global.compassColor;
      }

      const onTouchmove = (ev) => {
        if (ev.target.className.includes("navigationChoice")) {
          return;
        }

        const x = ev.touches[0].screenX;
        const y = ev.touches[0].screenY;

        clientX = x;

        var mouseXTmp = x - window.innerWidth / 2;

        direction = "gauche";
        setDirectionSt("gauche");
        if (mouseX.current - mouseXTmp < 0) {
          direction = "droite";
          setDirectionSt("droite");
        }
        if (direction != accStartDir) {
          accStartDir = direction;
          accStartTime = new Date().getTime();
          accStartX = clientX;
        }
        mouseX.current = mouseXTmp;

        // calcule la rotation en fonction de la distance parcourue
        var largeurCanvas = canvasRef.current.width;
        var distance = (mouseX.current - mouseXOnMouseDown.current) / 0.3; // correction de la vitesse
        var angle = (distance * 180) / largeurCanvas;
        targetRotation = (targetRotationOnMouseDown.current + angle) % 360;
        targetRotation = Math.floor(targetRotation);
        if (targetRotation > 360) {
          targetRotation = targetRotation - 360;
        }
        if (targetRotation < 0) {
          targetRotation = targetRotation + 360;
        }
        setRotation(targetRotation);

        var time = new Date().getTime(); // temps en ms
        var tempsEcoule = time - accStartTime; // temps écoulé en ms
        var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
        var rayonRotation = canvasRef.current.width;
        var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
        var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
        // fixe un maximum
        if (vitesseAngulaire > 8) {
          vitesseAngulaire = 8;
        }
        setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
      };

      const onTouchEnd = (ev) => {
        // calcule le temps et la distance parcourue depuis le dernier changement de direction
        var time = new Date().getTime(); // temps en ms
        var tempsEcoule = time - accStartTime; // temps écoulé en ms
        var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels

        var rayonRotation = canvasRef.current.width;
        var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
        var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s

        // fixe un maximum
        if (vitesseAngulaire > 8) {
          vitesseAngulaire = 8;
        }

        setCurrentSpeed(vitesseAngulaire);
        setStartSpeed(vitesseAngulaire);
      };

      const onTouchStart = (ev) => {
        // securites
        if (
          typeof window.getSelection === "function" &&
          typeof window.getSelection().empty === "function"
        ) {
          window.getSelection().empty();
        }

        // Pour les tactiles on tourne avec un doigt, on déplace avec deux
        if (ev.touches) {
          if (ev.touches.length > 1) {
            return; // Pas le cas sur D3d
          }
        }

        const x = ev.touches[0].screenX;
        const y = ev.touches[0].screenY;

        setStartSpeed(0);
        setCurrentSpeed(0);
        clientX = x;
        accStartTime = new Date().getTime();
        accStartX = x;

        targetRotation = rotation;
        mouseXOnMouseDown.current = x - window.innerWidth / 2;
      };

      const onMouseMove = (ev) => {
        if (isDraggingOnMouse.current) {
          const x = ev.screenX;
          const y = ev.screenY;

          clientX = x;

          var mouseXTmp = x - window.innerWidth / 2;

          direction = "gauche";
          setDirectionSt("gauche");
          if (mouseX.current - mouseXTmp < 0) {
            direction = "droite";
            setDirectionSt("droite");
          }

          if (direction != accStartDir) {
            accStartDir = direction;
            accStartTime = new Date().getTime();
            accStartX = clientX;
          }
          mouseX.current = mouseXTmp;

          // calcule la rotation en fonction de la distance parcourue
          var largeurCanvas = canvasRef.current.width;
          var distance = (mouseX.current - mouseXOnMouseDown.current) / 0.3; // correction de la vitesse
          var angle = (distance * 180) / largeurCanvas;
          targetRotation = (targetRotationOnMouseDown.current + angle) % 360;
          targetRotation = Math.floor(targetRotation);
          if (targetRotation > 360) {
            targetRotation = targetRotation - 360;
          }
          if (targetRotation < 0) {
            targetRotation = targetRotation + 360;
          }
          setRotation(targetRotation);

          var time = new Date().getTime(); // temps en ms
          var tempsEcoule = time - accStartTime; // temps écoulé en ms
          var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
          var rayonRotation = canvasRef.current.width;
          var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
          var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
          // fixe un maximum
          if (vitesseAngulaire > 8) {
            vitesseAngulaire = 8;
          }
          setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
        }
      };

      const onMouseUp = (ev) => {
        // calcule le temps et la distance parcourue depuis le dernier changement de direction

        var time = new Date().getTime(); // temps en ms
        var tempsEcoule = time - accStartTime; // temps écoulé en ms
        var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels

        if (canvasRef.current) {
          var rayonRotation = canvasRef.current.width;
          var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
          var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
        }

        // fixe un maximum
        if (vitesseAngulaire > 8) {
          vitesseAngulaire = 8;
        }

        setCurrentSpeed(vitesseAngulaire);
        setStartSpeed(vitesseAngulaire);
        isDraggingOnMouse.current = false;
      };

      const onMouseDown = (ev) => {
        // securites
        if (
          typeof window.getSelection === "function" &&
          typeof window.getSelection().empty === "function"
        ) {
          window.getSelection().empty();
        }

        // Pour les tactiles on tourne avec un doigt, on déplace avec deux
        const x = ev.screenX;
        const y = ev.screenY;

        setStartSpeed(0);
        setCurrentSpeed(0);
        clientX = x;
        accStartTime = new Date().getTime();
        accStartX = x;

        targetRotation = rotation;
        mouseXOnMouseDown.current = x - window.innerWidth / 2;
        isDraggingOnMouse.current = true;
      };

      if (playerRef.current && canvasRef.current) {
        /// Lets - go
        playerRef.current.addEventListener("touchmove", onTouchmove);
        playerRef.current.addEventListener("touchend", onTouchEnd);
        playerRef.current.addEventListener("touchstart", onTouchStart);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
        playerRef.current.addEventListener("mousedown", onMouseDown);

        return () => {
          if (playerRef.current && canvasRef.current) {
            playerRef.current.removeEventListener("touchmove", onTouchmove);
            playerRef.current.removeEventListener("touchend", onTouchEnd);
            playerRef.current.removeEventListener("touchstart", onTouchStart);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            playerRef.current.removeEventListener("mousedown", onMouseDown);
          }
        };
      }
    }
  }, [programme]);

  useEffect(() => {
    const easeOut = (s) => {
      return s * 0.9;
      // return c * ((t = t / d - 1) * t * ((factor + 1) * t + factor) + 1) + b;
    };

    if (startSpeed > 0 && currentSpeed > 0) {
      const angle = (25 / 20) * currentSpeed;
      const value = easeOut(currentSpeed);

      setRotation((r) => {
        let rSave = r;

        if (directionSt == "droite") {
          rSave += angle;
        } else {
          rSave -= angle;
        }
        rSave = Math.floor(rSave);
        if (rSave > 360) {
          rSave = rSave - 360;
        }
        if (rSave < 0) {
          rSave = rSave + 360;
        }
        return rSave;
      });

      setTimeout(() => {
        if (value < 0.5) {
          setCurrentSpeed(0);
        } else {
          setCurrentSpeed(value);
        }
      }, 5);
    }
  }, [currentSpeed, startSpeed]);

  useEffect(() => {
    var step = Math.floor((rotation * numFrames) / 360);
    setFrame(step);
  }, [rotation]);

  // Effects pour la gestion de la rotation et de la resolution
  useEffect(() => {
    // Rotation et baisse de resolution suivant la vitesse
    if (currentSpeed !== 0) {
      if (preloaded === null) {
        setResolution(10);
        return;
      }

      if (preloaded) {
        let sp = Math.trunc(currentSpeed) != NaN ? Math.trunc(currentSpeed) : 1;
        sp = sp === 0 ? 1 : sp;
        setResolution(sp);
      } else {
        setResolution(10);
      }
    }
  }, [rotation, preloaded]);

  useEffect(() => {
    // Appel de la HD si arret de la rotation + retrait vignette lot a la rotation
    if (currentSpeed === 0) {
      setInactivTime(0);
      targetRotationOnMouseDown.current = rotation;
      setResolution(0);
    } else {
      setAutoPlay(false);
      setPlayerToolTip(null);
    }
  }, [currentSpeed]);

  useEffect(() => {
    // retrait de la vue ext si utilisation de la palette
    setMaquetteExt(null);
  }, [block, floor]);

  useEffect(() => {
    // gestion de la resolution si -> arret rotation ou changement de batiment / etage
    if (block && floor && currentSpeed === 0) {
      setResolution(10);
      setTimeout(() => {
        setResolution(0);
      }, 100);
    }
  }, [block, floor]);

  useEffect(() => {
    // appel de l'image a afficher

    // Chargement de l'image du player 2D
    // if (block && floor) {
    const currentImg = new Image();
    const realFloor = navUrl.split("/")[1]?.split("_")[1] ? navUrl.split("/")[1] : floor

    if (
      type === "maquette" &&
      loadingDatas.maquette[block][realFloor].frames[resolution][frame]
    ) {
      currentImg.src =
        loadingDatas.maquette[block][realFloor].frames[resolution][frame].src;
    } else {
      if (navigator !== "firefox" && resolution === 2160) {
        currentImg.src = `/public/programmes/${programme}/${
          type === "localiser" ? "maquette" : type
        }/${navUrl}/jpg-${450}/${convertFrame(frame)}.jpg`;
      }
      currentImg.src = `/public/programmes/${programme}/${
        type === "localiser" ? "maquette" : type
      }/${navUrl}/jpg-${resolution}/${convertFrame(frame)}.jpg`;
    }

    const onLoad = () => {
      // Fonction appelee une fois l'image chargee
      setRender(currentImg);
    };

    // Gestion du toolTip dans les vue de nuit;
    if (navUrl.includes("_nuit")) {
      setPlayerToolTip(null);
    }

    currentImg.addEventListener("load", onLoad);
    return () => {
      currentImg.removeEventListener("load", onLoad);
    };
    // }
  }, [
    frame,
    navUrl,
    block,
    floor,
    resolution,
    programme,
    loadHdForFrame.current,
  ]);

  useEffect(() => {
    // Mise a jour du player 2D
    if (render) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const [offsetX, offsetY, canvasWidth, canvasHeight] =
        getPlayerSizes(canvas);
      context.drawImage(
        render,
        offsetX,
        offsetY,
        canvasWidth * zoom,
        canvasHeight * zoom
      );

      const player = document.getElementById("player");

      const w = canvas.width * zoom;
      const h = canvas.height * zoom;

      setPlayer3DSizes(
        w,
        h,
        -(w - player.offsetWidth) / 2,
        -(h - player.offsetHeight) / 2
      );

      setOffsetWidth(offsetX);
      setOffsetHeight(offsetY);
      setSizeWidth(canvas.width * zoom);
      setSizeHeight(canvas.height * zoom);
      update3D();
    }
  }, [render, programmeLots, jsonDatas, imgStyle, zoom]);

  useEffect(() => {
    // Mise a jour de la 3D au changement de ratio ou si un lot est presurvolle
    if (block != null && floor != null) {
      update3D();
    }
  }, [imgStyle, alreadyOveredLot]);

  useEffect(() => {
    // gestion de l'autoPlay
    if (autoPlay === true) {
      setTimeout(
        () => setFrame((f) => (f + 1) % numFrames),
        autoPlaySpeed * 1000
      );
    }
  }, [frame, autoPlay]);

  useEffect(() => {
    // lancement de l'autoplay si le temps d'inaction depasse la valeur seuil
    if (currentSpeed !== 0 || preloaded === null || autoPlay === true) {
      return;
    }
    if (inactivTime >= autoPlayDuration) {
      setInactivTime(0);
      setAutoPlay(true);
    }
  }, [inactivTime]);

  // Render
  return (
    <div
      ref={playerRef}
      id="player"
      style={{
        width:
          programmeLots?.lots?.length > 1 && location !== "situation"
            ? "calc(100% - 200px)"
            : "calc(100% - 100px)",
      }}
    >
      <canvas ref={canvasRef} id="player-2D" className={imgStyle} />

      <div
        id="playerZone"
        style={{
          position: "absolute",
          width: `${sizeWidth}px`,
          height: `${sizeHeight}px`,
          transform: `translate(${offsetWidth}px, ${offsetHeight}px)`,
        }}
      >
        {player3D}
        {playerToolTip}
        {puces}
      </div>

      {/* Boussole des maquettes 3D && pdv */}
      {(type === "maquette" || type === "localiser") &&
        maquetteDatas.global.useCompass && (
          <div
            id="player-boussole"
            style={{
              rotate: `${
                maquetteDatas.global.compassAngle + (360.0 / numFrames) * -frame
              }deg`,
            }}
          >
            <SvgIcon icon={"boussole"} />
          </div>
        )}

        <PlayerBoussole type = {type} numFrames = {numFrames} frame = {frame}/>

    </div>
  );
};

export default Player;
