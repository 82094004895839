// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du bouton affichant le formulaire de contact
// Creation : 18/08/2023 FTR
// ////////////////////////////////////////////////////////////////////////////////

// //////////////// Imports ////////////////// 

// Imports system
import React, { useContext, useEffect, useState } from 'react';

// Imports contexts
import { contactContext, programmeContext } from '../../contexts';

// Custom hooks
import useMobile from '../../globalHooks/useMobile';

// Components
//OLD import Contact from './contact'
import SvgIcon from './../../components/svg/svg';

const ContactButton = () => {

    // Context
    const programmeDatas = useContext(programmeContext);
    // INFO: Nous l'appelons pour gérer l'update du contexte
    // INFO: Mais la gestion de l'affichage se fait dans contact.js
    const { contactFormDisplay, setContactFormDisplay } = useContext(contactContext)

    // States
    const [showButton, setShowButton] = useState(programmeDatas.pages.includes("contact"))
    //OLD const [showForm, setShowForm] = useState(false)

    // Custom hook
    const isMobile = useMobile();

    useEffect(() => {

        //DESC: Gestion de la fermeture de la modal
        if (document.getElementById('contactButton')) {
            window.addEventListener('click', (e) => {
                if (document.getElementById('contactButton').contains(e.target) || document.getElementById('contact').contains(e.target)) {
                    setContactFormDisplay(true)
                } else {
                    setContactFormDisplay(false)
                }
            })
        }
    }, [])

    // Render
    return (
        <>
            {showButton &&
                <div id='contactButton' className={contactFormDisplay ? '' : 'visible'}>
                    {isMobile ?
                        <SvgIcon icon='v5_poste' />
                        :
                        <p id='contactButton-text'>Contactez-nous</p>
                    }
                    {/* OLD : showForm &&  INFO : Utilisation de Context
                        <Contact/>   
                    */}
                </div>
            }
        </>
    )
}

export default ContactButton;
