// ///////////////////////////////////////////////////////
// Composant pour la gestion des altimetries
// ///////////////////////////////////////////////////////
// Creation : 04/07/2022    -> CGR
// ///////////////////////////////////////////////////////

// Imports system
import React, { useEffect, useState, useContext } from 'react';
import {
    useParams
} from 'react-router-dom';

// Imports src
import useKrpano from 'react-krpano-hooks'
import {pageContext, programmeNameContext, programmeContext, lotContext, domain} from './../../../contexts';
import ButtonLong from '../../../components/button/buttonLong';
import Annotation from '../../../components/annotation/annotation';


const Vues = ({loadLot}) => { // Partie découvrir

    // /////////////////////////////
    // Properties
    const {lotParam} = useParams();
    const programmeName = useContext(programmeNameContext);
    const contextDatas = useContext(programmeContext);
    const { lotDatas, setLotDatas } = useContext(lotContext);
    const {setCurrent, setHeaderLevel} = useContext(pageContext);
    const [paletteList, setPaletteList] = useState([]);
    const [currentVim, setCurrentVim] = useState(null) // kr actif
    const { containerRef, krpanoState } = useKrpano({ // Ref krPano
        scriptPath : `/public/programmes/${programmeName}/lots/${lotParam}/altimetrie/tour.js`,
        embeddingParams : {swf:`/public/programmes/${programmeName}/lots/${lotParam}/altimetrie/tour.swf`, xml: `/public/programmes/${programmeName}/lots/${lotParam}/altimetrie/tour.xml`, target: 'react-krpano', html: 'prefer'},
        width : "calc(100% - 100px)"
    });
    const [annotationText, setAnnotationText] = useState("");

    // /////////////////////////////
    // Effects
    useEffect(() => { // Verification si le contexte de lot est bien a jour

        if (programmeName !== null && lotDatas === null) {
            fetch(`${domain}/${programmeName}/lot/name=${lotParam}`, {mode: 'cors'})
            .then(response => response.json())
            .then(data => {
                setLotDatas(data[lotParam]);
            })
            .catch((err) => {
                console.log("Erreur de fetch : ", err);
            })    
        }

    }, [programmeName, lotDatas, lotParam])

    useEffect(() => {

        if (programmeName !== null) {
            setCurrent(`detail-lot/${lotParam}/vue`); // maj du menu
            setHeaderLevel(2);
        }

    }, [programmeName, lotParam])

    // Annotation (ex"Visuel non contractuel")
    // TODO: Voir pour éviter un useEffect juste pour ça
    useEffect(() => {
        fetch(`${domain}/${programmeName}/lots`, {mode: 'cors'})
        .then(response => response.json())
        .then(data => {
            setAnnotationText(data.infos.pageVue.annotationText);
        })
        .catch((err) => {
            console.log("Erreur de fetch : ", err);
        })    
    }, [])


    useEffect(() => { // switch kr actif

        if (lotDatas) {
            if (currentVim) {

                const kr = containerRef.current.children[0];
                const correctVim = lotDatas.altimetries[currentVim];

                if (kr != undefined) {  
                    // kr.call(`loadscene('${Object.keys(lotDatas.altimetries).length > 1 ? "scene_" : ""}alti${lotDatas.altimetries[currentVim].id}', null, MERGE, BLEND(0.5));`);
                    kr.call(`loadscene('scene_alti${lotDatas.altimetries[currentVim].id}', null, MERGE, BLEND(0.5));`);

                    const forceFov = setInterval(() => {
                        if (kr.get("view.fov") != 130) {
                            kr.set("view.fov", 130);
                            kr.set("view.limitview", "range");
                            kr.set("view.hlookat", correctVim.xStart);
                            kr.set("view.vlookat", correctVim.yStart);
                            kr.set("view.hlookatmin", correctVim.xMin);
                            kr.set("view.hlookatmax", correctVim.xMax);
                            kr.set("view.vlookatmin", correctVim.yMin);
                            kr.set("view.vlookatmax", correctVim.yMax);
                        } else {
                            clearInterval(forceFov)
                        }
                    }, 500)
            
                    kr.set("view.fov", 130);
                    kr.set("view.limitview", "range");
                    kr.set("view.hlookat", correctVim.xStart);
                    kr.set("view.vlookat", correctVim.yStart);
                    kr.set("view.hlookatmin", correctVim.xMin);
                    kr.set("view.hlookatmax", correctVim.xMax);
                    kr.set("view.vlookatmin", correctVim.yMin);
                    kr.set("view.vlookatmax", correctVim.yMax);


                }
            } else {
                setCurrentVim(Object.keys(lotDatas.altimetries)[0])
            }
        }
    }, [krpanoState.isEmbedded, lotDatas, currentVim])

    // /////////////////////////////
    // Render
    return (
        <div id = 'vues'>
            {(currentVim && lotDatas && Object.keys(lotDatas.altimetries).length > 1) &&
                <div id ='vuesControllers'>
                    {Object.keys(lotDatas.altimetries).map(a => 
                        <ButtonLong 
                            key={a} 
                            text={a} 
                            state = {currentVim === a ? a : ""} 
                            icon = {null} 
                            action = {() => {
                                setCurrentVim(a)
                            }}
                        />
                    )}
                </div>
            }
            <div ref={containerRef} style={{ position: 'absolute', width: '100%', height: '100%' }} />

            {// 200 = marge des détails (280) - dépassement du player (80)
                annotationText != "" &&
                <Annotation text={annotationText} leftoffset={50}></Annotation>
            }

        </div>
    )
}

export default Vues;