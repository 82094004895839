export const getMeta = (url, callback) => {
  // Fonction pour obtenir la taille d'une image
  const img = new Image();
  img.src = url;
  img.onload = function () {
    callback(this.width, this.height);
  };
};

export const detectRatio = (image) => {
  // Applique un style en comparant le ratio de la source avec celui du body

  const wx = document.body.clientWidth;
  const wy = document.body.clientHeight;
  const windowRatio = wx / wy;

  getMeta(image?.src, (width, height) => {
    if (windowRatio < width / height) {
      image.className = "fromHeight";
      image.style.transform = `translate(${
        -(width * (wy / height) - wx) / 2
      }px, ${0}px)`;
    } else {
      image.className = "fromWidth";
      image.style.transform = `translate(${0}px, ${
        -(height * (wx / width) - wy) / 2
      }px)`;
    }
  });
};
