import { createGlobalStyle } from "styled-components";
import { programmeContext } from "../contexts";
import { useContext, useEffect, useState } from "react";

const GlobalStyle = () => {

    const [color, setColor] = useState("#A1A1A1");
    const programmeDatas = useContext(programmeContext);

    const GlobalStyles = createGlobalStyle`
    .mainBackground {
        background-color: ${color};
    }
    .activ {
        background-color: ${color};
    }
    .mainColor {
        color : ${color};
    }
    .mainBorder {
        background-color : ${color}!important;
    }

    @media all and (min-height: 599px) {
        .no-hover:hover {
            background-color : ${color}!important;
        }
    }
    .mainStroke path, .mainStroke rect, .mainStroke circle, .mainStroke polygon, .mainStroke line, .mainStroke polyline {
        fill : ${color};
    }
    .fill path, .fill rect, .fill circle, .fill polygon, .fill line, .fill polyline {
        fill : ${color}!important;
    }
    .mediaActive {
        border-color: ${color}!important;
    }
    .filterActive {
        background-color : ${color}!important;
    }
    .btnToggle:hover {
        background-color : ${color}!important;
    }
    .indicator {
        background-color: ${color};
    }
    #poi div div{
        background-color: ${color};
    }
    .comparerBtn:hover, .displayedRollout {
        background-color: ${color}!important;
    }
    .switch-top-bottom {
        background-color: ${color}!important;
    }
    .grecaptcha-badge{
        display:none;
    }
    #contactButton{
        background-color: ${color}!important;
    }
    #contact-form-title{
        color: ${color}!important;
    }
    .contact-form-fields-inputs{
        background-color: rgba(${() => {
            //DESC: Convertir la couleur hexadécimale en RGB
            //INFO: Opacité appliquée directement sur la background-color (au lieu de faire background-color puis opacity), pour ne pas impacter le texte
            const hexColor = color.substring(1); // Supprimer le caractère "#" au début
            const bigint = parseInt(hexColor, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return `${r}, ${g}, ${b}`;
        }}, 0.5);
    }
    #contact-form-button{
        background-color: ${color}!important;
    }
    #contact-form-promotion-checkbox:checked{
        background-color: ${color}!important; 
    }
    .activRoom {
        color: ${color}!important; 
    }
`;

    useEffect(() => {
        if (programmeDatas) {
            setColor(programmeDatas.color);
        }
    }, [programmeDatas])


    return (
        <GlobalStyles />
    )
};

export default GlobalStyle;