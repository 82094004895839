// /////////////////////////////////////////////////////////////////
// Hook pour gérer l'affichage des POI de différents types sur la map.
// /////////////////////////////////////////////////////////////////
// Creation : 21/09/2022    -> FTR
// /////////////////////////////////////////////////////////////////


// Imports system
import React, {useState, useContext} from 'react';
import { Marker } from "react-map-gl";
import { programmeNameContext, languageContext} from '../../../contexts';

// Import components
import POI from '../carto/poi/poi';
import POIProjet from '../carto/poi/poiProjet';

// ////////////////////////////////////////////////////////////////////////
// Hook pour controler la rotation du carrousel avec contraintes
const usePOIs = (type) => {
    // Properties
    const lang = useContext(languageContext)

    const [pois, setPOIs] = useState([]);

    const buildProjectsPOIs = (poisData) => {
        poisData.map(poiData =>
            setPOIs(             
                currentPois => [...currentPois,
                    <Marker
                        latitude={poiData.x}
                        longitude={poiData.y}
                    >
                        <POIProjet image={poisData.image} color={poiData.color} text={poiData.name}/>
                    </Marker>
                ]
            )
        )
    }

    const buildSharedDatasPOIs = (poisData, project, perimeter = {x:0.05, y:0.1}) => {
        for(const [type, poiData] of Object.entries(poisData)){
            fetch(`/public/maps/${type}.json`, { mode: 'cors'})
            .then(sharedDatasPOIResponse => sharedDatasPOIResponse.json())
            .then(datas => {
                setPOIs(        
                    currentPois => [...currentPois,
                        datas.features.filter(
                            data => (data.geometry && data.geometry.type !== "Polygon")
                            && data.geometry.coordinates[1] > project.x - perimeter.x
                            && data.geometry.coordinates[1] < project.x + perimeter.x
                            && data.geometry.coordinates[0] < project.y + perimeter.y
                            && data.geometry.coordinates[0] > project.y - perimeter.y
                        )
                        .map(data =>
                            <Marker
                                latitude={data.geometry.coordinates[1]}
                                longitude={data.geometry.coordinates[0]}
                            >
                                <POI icon={poiData.icon} color={poiData.color} text={poiData.forcePlaceholder ? poiData.placeholder : (data.properties.name ? data.properties.name : poiData.placeholder)}/>
                            </Marker>
                        )
                    ]
                )
            })
            .catch((err) => {
                console.log("Erreur de fetch : ", err);
            })
        }
    }

    const buildCustomPOIs = (poisData) => {
        poisData.map(poiData =>
            setPOIs(        
                currentPois => [...currentPois,
                    <Marker
                        latitude={poiData.coordinates.latitude}
                        longitude={poiData.coordinates.longitude}
                        color={poiData.color}
                    >
                        <POI icon={poiData.icon} color={poiData.color} text={poiData.name[lang]}/>
                    </Marker>
                ]
            )
        )
    }

    switch(type){
        case "project":
            return [pois, buildProjectsPOIs];
            break;
        case "sharedDatas":
            return [pois, buildSharedDatasPOIs];
            break;
        case "custom":
            return [pois, buildCustomPOIs];
            break;
        default:
            return [pois, setPOIs];
            break;
    }
    
}


export default usePOIs;
