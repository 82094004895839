import React, { useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "../../../components/button/button";
import Palette from "../../../components/palette/palette";
import {
  languageContext,
  noHeaderContext,
  programmeContext,
  programmeNameContext,
} from "./../../../contexts";
import SubNavigation from "./SubNavigation";

const PanoNavigationContent = () => {
  

  // Properties
  const { pathname, search } = useLocation();
  const { currentInt } = useParams();
  const navigate = useNavigate();
  const noHeader = useContext(noHeaderContext);
  const programmeName = useContext(programmeNameContext);
  const lang = useContext(languageContext);
  const programmeDatas = useContext(programmeContext);
  const interieursDatas = programmeDatas.pagesDetails.interieurs;
  const [searchParams, setSearchParams] = useSearchParams();
  const roomList = Object.entries(interieursDatas.pano[currentInt].pieces).map(
    (datas) => {
      return { name: datas[0], title: datas[1].titleLangs[programmeDatas.languages.findIndex(x => x === lang)], icon: datas[1].icon };
    }
  );

  const [currentNavigation, setCurrentNavigation] = useState("");
  
  // Function
  const getList = () => {

    if (currentNavigation === "piece") {
      return Object.entries(interieursDatas.pano[currentInt].pieces).map(
        (datas) => {
          return { name: datas[0], title: datas[1].titleLangs[programmeDatas.languages.findIndex(x => x === lang)], icon: datas[1].icon };
        }
      );
    } else if (currentNavigation === "decoration") {
      return interieursDatas.pano[currentInt].decorationsLanguages[lang].map((datas) => {
        return { name: datas, title: datas, icon: null };
      });
    } else if (currentNavigation === "prestation") {
      return interieursDatas.pano[currentInt].prestations.map((datas) => {
        return { name: datas, title: datas, icon: null };
      });
    } else {
      return [];
    }
  };
  const list = getList();

  // Effects
  useEffect(() => {
    // Test piece

    if (
      searchParams.get("piece") &&
      !Object.values(roomList)
        .map((x) => x.title)
        .includes(searchParams.get("piece"))
    ) {
      searchParams.set("piece", Object.values(roomList)[0].title);
      setSearchParams(searchParams);
    }
    // Test Decoration
    if (
      searchParams.get("decoration") &&
      !interieursDatas.pano[currentInt].decorations.length
    ) {
      searchParams.delete("decoration");
      setSearchParams(searchParams);
    }
    // Test Presation
    if (
      searchParams.get("prestation") &&
      !interieursDatas.pano[currentInt].prestations.length
    ) {
      searchParams.delete("prestation");
      setSearchParams(searchParams);
    }
  }, [currentInt]);

  useEffect(() => {
    if (!searchParams.get("piece")) {
      searchParams.set("piece", Object.values(roomList)[0].title);
      setSearchParams(searchParams);
    }

    if (
      interieursDatas.pano[currentInt].prestations.length &&
      interieursDatas.pano[currentInt].decorations.length &&
      (!searchParams.get("prestation") || !searchParams.get("decoration"))
    ) {
      searchParams.set(
        "decoration",
        interieursDatas.pano[currentInt].decorationsLanguages[lang][0]
      );
      setSearchParams(searchParams);
    } else if (
      interieursDatas.pano[currentInt].prestations.length &&
      !searchParams.get("prestation")
    ) {
      searchParams.set(
        "prestation",
        interieursDatas.pano[currentInt].prestationsLanguages[lang][0]
      );
      setSearchParams(searchParams);
    } else if (
      interieursDatas.pano[currentInt].decorations.length &&
      !searchParams.get("decoration")
    ) {
      searchParams.set(
        "decoration",
        interieursDatas.pano[currentInt].decorationsLanguages[lang][0]
      );
      setSearchParams(searchParams);
    }
  }, [currentInt]);

  // Render
  return (
    <>
      {/* Selection du lot */}
      {!searchParams.get("isolate") &&
        programmeName &&
        Object.keys(interieursDatas.pano).length > 1 && (
          <div id="pano-palette">
            <Palette
              list={Object.values(interieursDatas.pano).map(x => x.languages[programmeDatas.languages.findIndex(x => x === lang)])}
              current={interieursDatas.pano[currentInt].languages[programmeDatas.languages.findIndex(x => x === lang)]}
              setCurrent={(e) => {
                const goodDir = Object.keys(interieursDatas.pano).filter(x => interieursDatas.pano[x].languages[programmeDatas.languages.findIndex(x => x === lang)] === e)
                setCurrentNavigation("");
                navigate(
                  `/${programmeDatas.urlName}/interieurs/pano/${goodDir}${search}`
                );
              }}
            ></Palette>
          </div>
        )}

      {/* Selection de la piece */}
      <div id="pano-controls">
        <Button
          icon="pieces"
          text={programmeDatas.languagesDatas[lang].interieurs.header.pieces}
          state={currentNavigation === "piece"}
          action={() => {
            setCurrentNavigation((n) => (n === "piece" ? "" : "piece"));
          }}
          displayText={programmeDatas.displayText}
        />

        {interieursDatas.pano[currentInt]?.decorations?.length > 0 && (
          <Button
            icon="decorations-2"
            text={
              programmeDatas.languagesDatas[lang].interieurs.header.decorations
            }
            state={currentNavigation === "decoration"}
            action={() => {
              setCurrentNavigation((n) =>
                n === "decoration" ? "" : "decoration"
              );
            }}
            displayText={programmeDatas.displayText}
          />
        )}

        {interieursDatas.pano[currentInt]?.prestations?.length > 0 && (
          <Button
            icon="prestations"
            text={
              programmeDatas.languagesDatas[lang].interieurs.header.prestations
            }
            state={currentNavigation === "prestation"}
            action={() => {
              setCurrentNavigation((n) =>
                n === "prestation" ? "" : "prestation"
              );
            }}
            displayText={programmeDatas.displayText}
          />
        )}
      </div>

      <div
        id="InterieursNavigation"
        className={`${noHeader ? "no-header" : ""}`}
      >
        {list?.length > 0 && (
          <SubNavigation
            list={list}
            current={searchParams.get(currentNavigation)}
            onUpdate={(e) => {
              searchParams.set(currentNavigation, e.title);
              if (currentNavigation === "decoration") {
                searchParams.delete("prestation");
              } else if (currentNavigation === "prestation") {
                searchParams.delete("decoration");
              }
              setSearchParams(searchParams);
              setCurrentNavigation("");
            }}
          />
        )}
      </div>
    </>
  );
};

export default PanoNavigationContent;
