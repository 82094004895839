// ////////////////////////////////////////////////////////////////////////////////
// Fichier pour l'affichage de la fenetre de comparaison entre 2 lots
// ////////////////////////////////////////////////////////////////////////////////
// Creation : 08/09/2022 CGR
// ////////////////////////////////////////////////////////////////////////////////


// ////////////////////////////////////////////////////////////////////////////////
// Imports

// Imports system
import React, { useEffect, useState, useContext } from 'react';
import { comparerContext } from '../../../contexts';
import DragZone from './dragZone/dragZone';
import DatasZone from './datasZone/datasZone';
import useMobile from '../../../globalHooks/useMobile';


// ////////////////////////////////////////////////////////////////////////////////
// Components

const FavComp = ({ }) => {

    const isMobile = useMobile();
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        setIsCollapsed(isMobile)
    }, [isMobile]);

    return (
        <comparerContext.Consumer>
            {comparerLots =>
                <div id='comparer-displayer'>
                    {!isCollapsed && !isMobile &&

                        <div id='comparer-view'>
                            <DragZone side="A" display={comparerLots.currentLotA} setter={comparerLots.setCurrentLotA} />
                            <DragZone side="B" display={comparerLots.currentLotB} setter={comparerLots.setCurrentLotB} />
                        </div>
                    }
                    {
                        isCollapsed && isMobile &&
                        <>
                            <div id='comparer-view'>
                                <DragZone isMobile={isMobile} side="A" display={comparerLots.currentLotA} setter={comparerLots.setCurrentLotA} />
                                <DragZone isMobile={isMobile} side="B" display={comparerLots.currentLotB} setter={comparerLots.setCurrentLotB} />
                            </div>
                            <DatasZone isMobile={isMobile} display={isCollapsed ? "full" : ""} />
                        </>
                    }
                    {
                        !isMobile &&
                        <>
                        <div id="comparer-view-collapser" onClick={() => { setIsCollapsed(c => !c) }}><div></div></div>
                            <DatasZone display={isCollapsed ? "full" : ""} />
                        </>

                    }
                    

                </div>
            }</comparerContext.Consumer>
    )
}

export default FavComp;