import { useParams } from "react-router-dom"
import { useContext } from "react"
import { programmeContext, pageContext } from "../../contexts"
import { useEffect } from "react"


const ExternalContent = ({}) => {

    const {btnId} = useParams()
    const programmeDatas = useContext(programmeContext)
    const { setHeaderLevel, setCurrent } = useContext(pageContext)


    useEffect(() => {
        setHeaderLevel(1)
        setCurrent(btnId)
    }, [])


    return <div id = "external" style = {{width : '100%', height : '100%'}}>
        <iframe 
            src = {programmeDatas.header.toggleMenu[1].find(btn => btn.id === parseInt(btnId)).route} 
            style = {{width : '100%', height : '100%'}}
        />
    </div>
}   

export default ExternalContent