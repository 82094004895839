// /////////////////////////////////////////////////////////////
// Composant pour l'affichage de la page 404
// /////////////////////////////////////////////////////////////
// Creation : 06/09/2022    -> CGR
// /////////////////////////////////////////////////////////////



// ///////////////////////////////////
// Imports

// Imports system
import React, { useContext }  from 'react';
import { useNavigate } from 'react-router-dom';

// Import context
import { pageContext, programmeNameContext } from './../../contexts';



///////////////////////////////////
// Component

const Page404 = () => {

    // Contexts
    const { setHeaderLevel, setCurrent } = useContext(pageContext);
    const programmeName = useContext(programmeNameContext);
    
    // Nav
    const navigate = useNavigate();

    // Functions
    const returnToHome = () => { // Fonction pour le retour a la page d'acceuil
        setHeaderLevel(0);
        setCurrent("");
        navigate(`/${programmeName}`);
    }

    // Render
    return (
        <div id = "page404">
            <h1 className = 'mainColor'>ERREUR 404</h1>
            <p>Désolé, l'outil 3D recherché n'existe pas</p>
            <div id = "backToHome" className = "mainBackground" onClick={returnToHome}>
                Revenir en arrière
            </div>
        </div>
    )
}


// ///////////////////////////////////
// Export
export default Page404;