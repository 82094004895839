// ////////////////////////////////////////////////////////////////////////
// Fichier pour la gestion du toolTip d'information du lot survole dans
// la maquette 3D.
// ////////////////////////////////////////////////////////////////////////
// Creation : 22/04/2022 CGR
// Update : 08/09/2022  -> CGR   : ajout aux favoris
// ////////////////////////////////////////////////////////////////////////

// ////////////////////////////////////////////////////////////////////////
// Imports
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  favorisContext,
  languageContext,
  programmeContext,
  programmeLotsContext,
  programmeNameContext,
  returnToContext,
} from "./../../../contexts";
import SvgIcon from "./../../svg/svg";

// ////////////////////////////////////////////////////////////////////////
// Composant react principal pour la vignette de survol de zone
const PlayerToolTip = ({ programme, name, x, y }) => {
  // Infos ////////////////////////////////
  // name : nom du lot -> str
  // x, y : position de la vignette -> int
  // //////////////////////////////////////

  // Properties
  const navigate = useNavigate();
  let { pathname, search } = useLocation();
  const { setReturnTo } = useContext(returnToContext);
  const { favs, setFavs } = useContext(favorisContext);
  const programmeDatas = useContext(programmeContext);
  const lotsDatas = useContext(programmeLotsContext);
  const lang = useContext(languageContext);

  const useSmallName = name.length > 5 ? true : false;
  const [lot, setLot] = useState({
    type: "",
    statut: "",
    surface: 0,
  });
  const [displayDetailLot, setDisplayDetailLot] = useState(false);
  const [dispoLot, setDispoLot] = useState(false);

  // Fonctions
  const goToLot = (programmeName) => {
    if (
      programmeDatas.displayIndispo === "Grisés sans accès au détail" &&
      lot.statut === "Vendu"
    )
      return;
    else {
      if (pathname.indexOf("/lot=") !== -1) {
        setReturnTo(
          `${pathname
            .slice(0, pathname.indexOf("/lot="))
            .replace(`/${programmeDatas.urlName}/`, "")}/lot=${name}`
        );
      } else {
        setReturnTo(
          `${pathname.replace(`/${programmeDatas.urlName}/`, "")}/lot=${name}`
        );
      }
      navigate(`/${programmeDatas.urlName}/detail-lot/${name}/plan${search}`);
    }
  };

  const addToFavs = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFavs(name);
  };

  const getLotDescription = (lotDatas, lang) => {
    // Utilisation de Number.isFinite pour une vérification plus robuste qu'une variable est un type numérique
    const isTypeNumer = Number.isFinite(lotDatas.type);

    if (lang === "fr" || !isTypeNumer) {
      return lotDatas.type;
    } else {
      return lotDatas.numPieces - 1;
    }
  };

  // Effects
  useEffect(() => {
    // recuperation des informations du lot via l'API
    if (lotsDatas) {
      setLot(lotsDatas.lots.find((x) => x.name === name));
      setDisplayDetailLot(
        lotsDatas.lots.find((x) => x.name === name).useDetailLot
      );
      setDispoLot(
        lotsDatas.lots.find((x) => x.name === name).statut === "Disponible"
      );
    }
  }, [name, lotsDatas]);

  // Render
  return (
    <programmeNameContext.Consumer>
      {(programmeName) =>
        programmeName !== null && (
          <favorisContext.Consumer>
            {(favsDatas) => (
              <div
                id="lotHover"
                style={{
                  top: y - 120,
                  left: x,
                  cursor:
                    (displayDetailLot && lot.display === "Grisés") ||
                    lot.display === "Masqués" ||
                    (lot.display == "Grisés sans accès au détail" &&
                      lot.statut === "Disponible")
                      ? "pointer"
                      : "default",
                }}
              >
                {/* Zone de contenue pour les informations du lot */}
                <div
                  id="lotHover-content"
                  onClick={() => goToLot(programmeName)}
                >
                  <div className="lotHover-title">
                    <h2 className={`mainColor ${useSmallName && "small"}`}>
                      {name}
                    </h2>
                    {programmeDatas.displayStatus && (
                      <div
                        className={`lotHover-title-status ${
                          lot.statut === "Disponible"
                            ? "mainBackground"
                            : "unable"
                        }`}
                      >
                        {
                          programmeDatas.languagesDatas[lang].lots.statut[
                            lot.statut
                          ]
                        }
                      </div>
                    )}
                  </div>
                  <div className="lotHover-info">
                    <p>
                      {
                        programmeDatas.languagesDatas[lang].maquette.search
                          .results.type
                      }
                    </p>
                    <p className="lotHover-info-data">
                      {getLotDescription(lot, lang)}
                    </p>
                  </div>
                  <div className="lotHover-info">
                    <p>
                      {
                        programmeDatas.languagesDatas[lang].rechercher.lot
                          .surface
                      }
                    </p>
                    <p className="lotHover-info-data">{lot.surface}</p>
                  </div>

                  <div id="lotHover-actions">
                    <div id="lotHover-fav" onClick={addToFavs}>
                      {favsDatas.favs.includes(lot.name) ? (
                        <SvgIcon icon="favorisPlein" />
                      ) : (
                        <SvgIcon icon="favoris" />
                      )}
                    </div>
                    {displayDetailLot && dispoLot && (
                      <div id="lotHover-link">
                        <SvgIcon icon="fleche-r" />
                      </div>
                    )}
                  </div>
                </div>

                {/* Ligne de jonction entre l'assise et la bulle de contenu */}
                <div id="lotHover-line"></div>

                {/* Bulle d'assise de la vignette, doit etre positionnée au centre de la zone survolée */}
                <div id="lotHover-base"></div>
              </div>
            )}
          </favorisContext.Consumer>
        )
      }
    </programmeNameContext.Consumer>
  );
};

// ////////////////////////////////////////////////////////////////////////
// Export
export default PlayerToolTip;
